import React, { useEffect, useState, useRef } from "react";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { fetchGlnData } from "../model/GLNModel";
import { CombinedConstKeys } from "../../assets/VarConstants";
import CCopy from "../../../basecomp/components/CCopy";
//import GenericUtils from "../../../base/GenericUtils";
import { Link } from "react-router-dom";

const isBlank = (str) => {
  return !str || str.trim().length === 0;
};

const GLNDetailVM = (inputData, headerCheckboxState) => {
  const accessToken = process.env.REACT_APP_MAP_TOKEN;
  const mapRef = useRef(null);
  const { toastObj, handleToastOpen, handleToastClose } = useToast();
  const [glnData, setGlnData] = useState([]);
  const [countryCoordinates, setCountryCoordinates] = useState([]);
  const [zoomLevel, setZoomLevel] = useState();
  const [isDetail, setIsDetail] = useState(-1);
  const [listCardData, setListCardData] = useState(null);
  const [detailCardData, setDetailCardData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState([1000, -100000]);
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    const fetchGLN = async () => {
      setGlnData([]);
      const cityCodes = inputData.get(CombinedConstKeys.CITY_KEY);
      const facilityCodes = inputData.get(CombinedConstKeys.FACILITY_NAME_KEY);
      const glnId = inputData.get(CombinedConstKeys.GLN_ID_KEY);
      const facilityName = inputData.get(CombinedConstKeys.OTHER_TYPE_KEY);
      const allFilter = headerCheckboxState;
      // console.log("isBlnak   ", isBlank(cityCodes), isBlank(facilityCodes), isBlank(otherId),
      //         isBlank(otherType), isBlank(glnId), cityCodes, facilityCodes, otherId, otherType, glnId, inputData);
      if (!isBlank(cityCodes) || !isBlank(facilityCodes) || !isBlank(glnId)) {
        try {
          const result = await fetchGlnData(
            cityCodes,
            facilityCodes,
            glnId,
            facilityName,
            allFilter
          );
          setGlnData(result.glnData);

          setZoomLevel(result.zoomLevel);
          setCountryCoordinates(result.countryMapValue);
        } catch (error) {
          handleToastOpen("error", error.message);
        }
      } else {
        //handleToastOpen("error", "Select Required Fields to Proceed");
      }
    };
    fetchGLN();
  }, [inputData]);

  useEffect(() => {
    if (glnData && glnData.length > 0) {
      let results = [];

      // Iterate through each row in glnData
      for (let i = 0; i < glnData.length; i++) {
        const currentRow = glnData[i];

        let result;

        // Check if the position property inside mapValue is null
        if (currentRow.mapValue.position === null) {
          const position = [0, 0];
          const message = currentRow.mapValue.message;
          result = {
            position: position,
            message: message,
          };
        } else {
          const position = currentRow.mapValue.position;
          const message = currentRow.mapValue.message;
          result = {
            position: position,
            message: message,
          };
        }

        results.push(result);
      }

      setMarkers(results);

      if (glnData.length === 1) {
        setIsDetail(1);
        setDetailCardDataAt(0);
        setListCardData([]);
      } else if (glnData.length > 1) {
        setIsDetail(0);
        setListCardDataAll();
        setDetailCardData([]);
      }
    } else {
      setIsDetail(-1);
      setListCardData(null);
      setDetailCardData(null);
      setMarkers([]);
      setMapCenter([0, 0]);
      setMapKey(0);
    }
  }, [glnData]);

  //Todo
  useEffect(() => {
    if (countryCoordinates.length > 0) {
      // Filter out markers with position [0, 0]

      const avgLat = countryCoordinates[0];
      const avgLng = countryCoordinates[1];

      setMapCenter([avgLat, avgLng]);

      // Update the key to trigger re-render
      // Update the key to trigger re-render
      setMapKey((prevKey) => prevKey + 1);
    }
  }, [countryCoordinates]);

  const onListClickAt = (index) => {
    setIsDetail(1);
    setDetailCardDataAt(index);

    const coordinates = markers.map((item) => ({
      latitude: item.position[0],
      longitude: item.position[1],
    }));

    const zoomLevel = 8;

    // Use the clicked item's coordinates
    const clickedCoordinate = coordinates[index];

    if (
      clickedCoordinate &&
      clickedCoordinate.latitude !== 0 &&
      clickedCoordinate.longitude !== 0
    ) {
      if (mapRef.current) {
        mapRef.current.setView(
          [clickedCoordinate.latitude, clickedCoordinate.longitude],
          zoomLevel
        );
      }
    }
  };

  const onDetailCloseClicked = () => {
    setIsDetail(0);
    const initialZoomLevel = zoomLevel; // Set your initial zoom level here

    if (countryCoordinates !== null && mapRef.current) {
      // Set the default center and zoom level only if countryCoordinates is present
      mapRef.current.setView(mapCenter, initialZoomLevel);
    }
  };

  const setDetailCardDataAt = (index) => {
    let detailCard = [];
    const glnId = glnData[index].glnId;
    detailCard.push({
      title: "GLN:",
      detail: glnId,
    });
    const unlocode = glnData[index].unlocode;
    if (unlocode !== null) {
      detailCard.push({
        title: "UN/Locode:",
        detail: unlocode,
      });
    }
    const otherId = glnData[index].otherId;
    const otherType = glnData[index].otherType;
    if (
      otherId !== undefined &&
      otherId !== null &&
      otherId !== "null" &&
      otherType !== undefined &&
      otherType !== null &&
      otherType !== "null"
    ) {
      detailCard.push({
        title: "Alternate Code:",
        detail: otherId + " [" + otherType + "]",
      });
    }
    const locationName = glnData[index].locationName;
    if (locationName !== null) {
      detailCard.push({
        title: "Location Name:",
        detail: locationName,
      });
    }
    const geoCoordinates = glnData[index].geoCoordinates;
    if (geoCoordinates && geoCoordinates !== null) {
      detailCard.push({
        title: "GeoCoordinates:",
        custom: <CCopy cText={geoCoordinates} />,
      });
      let positionX = 0;
      let positionY = 0;

      if (
        glnData[index].mapValue &&
        glnData[index].mapValue.position &&
        glnData[index].mapValue.position.length >= 2
      ) {
        positionX = glnData[index].mapValue.position[0].toFixed(6);
        positionY = glnData[index].mapValue.position[1].toFixed(6);
      }

      const mapValue = positionX + " " + positionY;

      detailCard.push({
        custom: <CCopy cText={mapValue} />,
      });
    }
    const roleType = glnData[index].roleType;
    if (roleType !== null && roleType !== "") {
      detailCard.push({
        title: "Role Type:",
        detail: roleType,
      });
    }
    if ("BIC" === otherType || "SMDG" === otherType) {
      let link = unlocode + otherId;
      if ("BIC" === otherType) {
        link = "https://www.bic-code.org/facility-codes/" + link;
      } else {
        link = "https://www.bic-code.org/facility-codes/smdg/" + link;
      }
      detailCard.push({
        custom: (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            View More Details
          </Link>
        ),
      });
    }

    setDetailCardData(detailCard);
  };
  const setListCardDataAll = () => {
    let listCardArr = [];
    glnData.forEach((item) => {
      let listCard = [];
      const glnId = item.glnId;
      listCard.push({
        head: "Global Location Number:",
        detail: glnId,
      });

      // Add new Code Type entry
      listCard.push({
        head: "Code Type:",
        detail: item.otherType || "UN/LOCODE",
      });

      const unlocode = item.unlocode;
      const locationName = item.locationName;
      const otherId = item.otherId;
      if (locationName !== null) {
        listCard.push({
          head: otherId
            ? "Alternate Code - Name:"
            : "UN/Locode - Location Name:",
          detail: otherId
            ? otherId + " - " + locationName
            : unlocode + " - " + locationName,
        });
      }
      listCardArr.push(listCard);
    });

    setListCardData(listCardArr);
  };

  const validMarkers = markers.filter(
    (marker) => !(marker.position[0] === 0 && marker.position[1] === 0)
  );

  // Calculating the center based on valid markers
  const calculateCenter = () => {
    if (validMarkers.length === 0) {
      // If no valid markers exist, return a default center
      return [0, 0]; // Change this to your desired default center coordinates
    }

    // Calculate the average of all valid marker positions to find the center
    const sum = validMarkers.reduce(
      (acc, marker) => [
        acc[0] + marker.position[0],
        acc[1] + marker.position[1],
      ],
      [0, 0]
    );
    return [sum[0] / validMarkers.length, sum[1] / validMarkers.length];
  };

  const centerCoordinates = calculateCenter();

  return {
    isDetail,
    listCardData,
    detailCardData,
    onListClickAt,
    onDetailCloseClicked,
    mapKey,
    mapRef,
    mapCenter,
    accessToken,
    markers,
    zoomLevel,
    centerCoordinates,
  };
};

export default GLNDetailVM;
