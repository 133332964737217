import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";


const currentUrl = window.location.origin;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallHeaderWithToken,
  axiosPutCallWithToken
} = AxiosWrapper;

export const fetchSizeOptions = async () => {
  const response = await axiosGetCallWithToken(
    `/label_print/getAllMeasurement`
  );
  return response;
};

export const fetchPoOrMaterial = async (poNo, matNo) => {
  const response = await axiosGetCallWithToken(
    `/label_print/getPoNoOrMaterial?po_no=${poNo}&mat_no=${matNo}`
  );
  return response;
};

export const addSticker = async (stickerData) => {
  const response = await axiosPostCallWithToken(
    `/label_print/addSticker`,
    stickerData
  );
  return response;
};

export const updateSticker = async (id, material_no, bin_location, bulk_location, print_qty) => {
  const response = await axiosPutCallWithToken(
    `/label_print/updateSticker?id=${id}&material_no=${material_no}&bin_location=${bin_location}&bulk_location=${bulk_location}&print_qty=${print_qty}`
  );
  return response;
};

export const fetchStickers = async () => {
  const response = await axiosGetCallWithToken(
    `/label_print/getAllSticker`
  );
  return response;
};

export const deleteSticker = async (id) => {
  const idParam = id === undefined ? `` : `?id=${id}`;
  const response = await axiosDeleteCallWithToken(
    `/label_print/deleteSticker${idParam}`
  );
  return response;
};

export const getStickerPdf = async (id, stickerData, type) => {
  const idParam = id === undefined ? `` : `&id=${id}`;
  const stickerDataParam =
    stickerData === undefined
      ? ``
      : `&stickerData=${JSON.stringify(stickerData)}`;
  const response = await axiosGetCallHeaderWithToken(
    `/label_print/getStickerPdf?type=${type}&url=${currentUrl}${idParam}${stickerDataParam}`,
    "application/pdf"
  );
  return response;
};

export const uploadStickerData = async (selectedSizeId, selectedFiles) => {
  const formData = new FormData();
  formData.append("selectedSizeId", selectedSizeId);
  for (let i = 0; i < selectedFiles.length; i++) {
    formData.append("file", selectedFiles[i]);
  }
  const response = await axiosPostCallWithToken(
    `/label_print/uploadStickerData`,
    formData
  );
  return response;
};

export const getSampleUploadFile = async (type) => {
  const response = await axiosGetCallHeaderWithToken(
    `/label_print/getSampleCsv?type=${type}`,
    "text/csv"
  );
  return response;
};
