import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Box, CircularProgress } from "@mui/material";
import SummaryCards from './SummaryCards';
import PopularPagesChart from './PopularPagesChart';
import UserActivityTable from './UserActivityTable';

const REACT_BE_URL = process.env.REACT_APP_BE_URL2;

const Dashboard = () => {
  const [stats, setStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [popularPages, setPopularPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [statsRes, pagesRes] = await Promise.all([
          axios.get(`${REACT_BE_URL}/tracker/dashboard/stats`),
          axios.get(`${REACT_BE_URL}/tracker/dashboard/popular-pages`),
        ]);

        setStats(statsRes.data.data);
        setFilteredStats(statsRes.data.data);
        setPopularPages(pagesRes.data.data);
      } catch (error) {
        console.error("Failed to fetch dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    filterData(date, endDate, searchQuery);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    filterData(startDate, date, searchQuery);
  };

  useEffect(() => {
    filterData(startDate, endDate, searchQuery);
  }, [searchQuery]);

  const filterData = (start, end, query) => {
    let filtered = [...stats];
  
    if (start || end) {
      const startTimestamp = start ? Math.floor(new Date(start).getTime() / 1000).toString() : null;
      const endTimestamp = end 
        ? Math.floor(new Date(end).getTime() / 1000).toString() 
        : Math.floor(Date.now() / 1000).toString();
  
      filtered = filtered.filter(item => {
        const loginTime = parseInt(item.login_time);
        const startTime = startTimestamp ? parseInt(startTimestamp) : null;
        const endTime = parseInt(endTimestamp);
        
        if (start && end) {
          return loginTime >= startTime && loginTime <= endTime;
        }
        if (start) {
          return loginTime >= startTime;
        }
        if (end) {
          return loginTime <= endTime;
        }
        return true;
      });
    }
  
    if (query) {
      const lowercaseQuery = query.toLowerCase();
      filtered = filtered.filter(item => {
        const terminalIdString = String(item.terminal_id || '');
        
        return (
          item.user_name?.toLowerCase().includes(lowercaseQuery) ||
          item.user_email?.toLowerCase().includes(lowercaseQuery) ||
          terminalIdString.includes(lowercaseQuery) ||
          (item.user_ip || '').toLowerCase().includes(lowercaseQuery)
        );
      });
    }
  
    setFilteredStats(filtered);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 12, mb: 12 }}>
      <SummaryCards stats={stats} popularPages={popularPages} />
      <PopularPagesChart popularPages={popularPages} title={"Most Visited Pages"}/>
      <UserActivityTable
        filteredStats={filteredStats}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
      />
    </Container>
  );
};

export default Dashboard;