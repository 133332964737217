import React from "react";
import Grid from "@mui/material/Grid";
import MailIcon from "@mui/icons-material/Mail";
import ReplenishmentVM from "../viewmodel/ReplenishmentVM";
import CTable from "../../../../basecomp/components/CTable";
import CButton from "../../../../basecomp/components/CButton";
import CText from "../../../../basecomp/components/CText";
import CBackDrop from "../../../../basecomp/components/CBackDrop";

const Replenishment = (props) => {
  const {
    jobDirective,
    equipmentID,
    equipmentType,
    spDetails,
    jobCardNumber,
    handleToastOpen,
    jobType,
  } = props;
  const { handleIconButtonClick, isLoading, infoHeadData, infoRowData } =
    ReplenishmentVM({
      spDetails,
      jobDirective,
      equipmentID,
      jobCardNumber,
      equipmentType,
      handleToastOpen,
      jobType,
    });

  // Filter spDetails to exclude records with an empty material_no
  const filteredSpDetails = spDetails.filter((detail) => detail.material_no);

  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <CText cVariant="header_card" cText="Replenishment" />
        </Grid>

        <Grid item xs={12} sm={12} md={12} margin={1.5}>
          <div style={{ overflowX: "auto" }}>
            {filteredSpDetails.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <CTable
                  cHeaderData={infoHeadData}
                  cRowData={infoRowData}
                  cActualData={filteredSpDetails}
                  cIsPagination={true}
                  cSmallSize={true}
                />
              </Grid>
            )}
          </div>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={11.9}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1em",
            }}>
            <div style={{ display: "flex" }}>
              <CButton
                cId="email_replenishment"
                cIsMain={true}
                cSrc={<MailIcon />}
                cTooltipText="Replenishment Email"
                cOnClick={handleIconButtonClick}
                cDisabled={filteredSpDetails.length === 0}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Replenishment;
