import React from "react";
import CText from "../../../basecomp/components/CText";
import CButton from "../../../basecomp/components/CButton";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import CChooseFile from "../../../basecomp/components/CChooseFile";
import CTable from "../../../basecomp/components/CTable";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DcatalogueTerminalVM from "../viewmodel/DcatalogueTerminalVM";
import {
  header,
  subHeader,
  cTextVariant,
  sampleDownload,
  upload,
  fileType,
  tooltip,
  UploadType,
  download
} from "../model/Strings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CBackDrop from "../../../basecomp/components/CBackDrop";


const DCatalogueTerminalView = () => {
  const {
    handleFileChange,
    handleDownload,
    handleUpload,
    tableData,
    infoHeadData,
    infoRowData,
    isLoading
  } = DcatalogueTerminalVM();

  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <CMainGrid
        item
        xs={10}
        sm={8}
        md={10}
        sx={{ margin: "6em 10em 10em 10em" }}
      >
        <CMainCard
          sx={{ padding: "2em", marginRight: "5em", marginLeft: "5em" }}
        >
          <CText cVariant={cTextVariant} cText={header} />

          <CMainGrid container paddingTop={2}>
            <CMainGrid item xs={12} sm={6} md={3}>
            <CChooseFile
            cFileType={fileType}
            cTooltipText={tooltip}
            cHelperText={UploadType}
            cHandleFileChange={handleFileChange}
            cFullWidth={true}
            cIsRequired={true}
            cIsSmall={true}
          />
            </CMainGrid>
            <CMainGrid
              item
              xs={12}
              sm={6}
              md={3}
              paddingLeft={2}
              paddingTop={0.5}
            >
              <CButton
                cId={download}
                cIsMain={true}
                cSrc={<CloudDownloadIcon />}
                cTooltipText={sampleDownload}
                cOnClick={handleDownload}
              />
            </CMainGrid>
          </CMainGrid>

          <CMainGrid
            item
            xs={12}
            sm={6}
            md={1.5}
            paddingLeft={1}
            paddingTop={1}
          >
            <CButton
                cId={upload}
                cIsMain={true}
                cSrc={<CloudUploadIcon />}
                cTooltipText={upload}
                cOnClick={handleUpload}
              />
          </CMainGrid>
          <CMainGrid item xs={12} sm={6} md={1.5} paddingTop={3}>
            <CText cVariant={cTextVariant} cText={subHeader} />
          </CMainGrid>

          <CMainGrid item xs={3} sm={6} md={12} paddingTop={1}>
            <CTable
              cHeaderData={infoHeadData}
              cRowData={infoRowData}
              cActualData={tableData}
              cIsPagination={true}
              cSmallSize={true}
              showSearch={true}
            />
          </CMainGrid>
        </CMainCard>
      </CMainGrid>
    </>
  );
};

export default DCatalogueTerminalView;
