import React, { useState, useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { themeMain } from "../../basecomp/main/ThemeMain";

const CCopy = ({ cText }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {}, [isCopied]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(cText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 10000);
      })
      .catch((error) => {
        // Logger.printStackTrace(Constants.errorFetch, error);
      });
  };

  return (
    <Typography
      color="textSecondary"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Align content at the end
        border: "0.1em solid #0000001F",
        borderRadius: "0.3em",
        padding: "0.4em",
      }}
    >
      <span className="output">{cText}</span>
      <Tooltip title={isCopied ? "Copied" : "Copy"}>
        <IconButton
          style={{
            cursor: "pointer",
            color: isCopied ? themeMain.palette.secondary.main : "inherit",
          }}
          onClick={copyToClipboard}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};
export default CCopy;
