import axios from "axios";
import UserUtils from "../auth/UserUtils";

const apiKey = "x-api-key";
const { handleLoginError, getTokenFromEmail } = UserUtils;

const getBEUrl = () => {
  const data = localStorage.getItem("permissionData");
  const isUAT = data !== undefined && data !== null && data.includes("feature_uat");
  let REACT_APP_BE_URL = isUAT ? process.env.REACT_APP_BE_URL2 : process.env.REACT_APP_BE_URL;
  return REACT_APP_BE_URL;
}

const responseHandling = (response) => {
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
}

const axiosGetCall = async (url, dataObj) => {
  const response = await axios.get(`${getBEUrl()}${url}`, dataObj);
  return responseHandling(response);
};

const axiosPostCall = async (url, formData) => {
  const response = await axios.post(`${getBEUrl()}${url}`, formData);
  return responseHandling(response);
};

const axiosPutCall = async (url, dataObj) => {
  const response = await axios.put(`${getBEUrl()}${url}`, dataObj);
  return responseHandling(response);
};

const axiosDeleteCall = async (url, dataObj) => {
  const response = await axios.delete(`${getBEUrl()}${url}`, dataObj);
  return responseHandling(response);
};

const axiosPostCallWithToken = async (url, formData) => {
  let response;
  try {
    const token = getTokenFromEmail();
    response = await axios.post(`${getBEUrl()}${url}`, formData, {
      headers: {
        [apiKey]: token,
      },
    });
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

const axiosGetCallWithToken = async (url, formData) => {
  let response;
  try {
    const token = getTokenFromEmail();
    response = await axios.get(`${getBEUrl()}${url}`, {
      params: formData,
      headers: {
        [apiKey]: token,
      },
    });
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

export const axiosGetWithTokenHeader = async (url) => {
  let response 
  try {
    const token = getTokenFromEmail();
    if (token === null) {
      return;
    }
    response = await axios.get(`${getBEUrl()}${url}`, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/excel",
        [apiKey]: token,
      },
    });
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

const axiosPutCallWithToken = async (url, formData) => {
  let response;
  try {
    const token = getTokenFromEmail();
    response = await axios.put(`${getBEUrl()}${url}`, formData, {
      headers: {
        [apiKey]: token,
      },
    });
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

const axiosDeleteCallWithToken = async (url) => {
  let response;
  try {
    const token = getTokenFromEmail();
    response = await axios.delete(`${getBEUrl()}${url}`, {
      headers: {
        [apiKey]: token,
      },
    });
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

const axiosGetCallHeaderWithToken = async (url, headerType) => {
  let response;
  try {
    const token = getTokenFromEmail();
    response = await axios.get(`${getBEUrl()}${url}`, {
      headers: {
        [apiKey]: token,
        "Content-Type": headerType,
      },
      responseType: 'blob', // Set the response type to blob here
    });
  } catch (error) {
    handleLoginError(error);
    return null; // Ensure you return null if there's an error
  }
  return responseHandling(response);
};

const axiosGetCallHeader = async (url, headerType) => {
  let response;
  try {
    response = await axios.get(`${getBEUrl()}${url}`, {
      headers: {
        "Content-Type": headerType,
      },
    });
    if (response.status === 200) {
      response.config.responseType = 'blob';
    }
    response = await axios(response.config);
  } catch (error) {
    handleLoginError(error);
  }
  return responseHandling(response);
};

const AxiosWrapper = {
  axiosGetCall,
  axiosPostCall,
  axiosPutCall,
  axiosDeleteCall,
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosPutCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallHeaderWithToken,
  axiosGetCallHeader,
  getBEUrl
};

export default AxiosWrapper;