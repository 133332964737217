import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  Card,
  Typography,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Select,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../basecomp/components/provider/ToastProvider";
import CBackDrop from "../../basecomp/components/CBackDrop";

const MAX_PHOTO_SIZE_MB = 2; // Max size in megabytes

const VisitorForm = () => {
  const { handleToastOpen } = useToast();
  const [isVerified, setIsVerified] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    contact: "",
    email: "",
    visitFrom: "",
    visitTill: "",
    purpose: "",
    whomToVisitName: "", // New state for 'Whom to Visit' name
    whomToVisitContact: "", // New state for 'Whom to Visit' contact
    whomToVisitEmail: "", // New state for 'Whom to Visit' email
    photo: null,
    document: null,
    documentType: "", // New state for document type
  });

  const [photoPreview, setPhotoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [openPhotoPreview, setOpenPhotoPreview] = useState(false);
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [isVerifyEnabled, setIsVerifyEnabled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [selectedWhomToVisitCountryCode, setSelectedWhomToVisitCountryCode] =
    useState("+91");
  const [countryCodes, setCountryCodes] = useState([]);

  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/getCountryCodes`
      );
      console.log("response.data", response.data);
      setCountryCodes(response.data);
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  useEffect(() => {
    fetchCountryCodes();
  }, []);

  useEffect(() => {
    // Enable the Verify button only if either Full Name, Contact, or Email is filled
    setIsVerifyEnabled(
      (formData.whomToVisitName || "").trim() !== "" ||
        (formData.whomToVisitContact || "").trim() !== "" ||
        (formData.whomToVisitEmail || "").trim() !== ""
    );
  }, [
    formData.whomToVisitName,
    formData.whomToVisitContact,
    formData.whomToVisitEmail,
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (name === "photo" && file) {
      setFormData((prevData) => ({ ...prevData, [name]: file }));
      setPhotoPreview(URL.createObjectURL(file));
    } else if (name === "document" && file) {
      setFormData((prevData) => ({ ...prevData, [name]: file }));
      setDocumentPreview(URL.createObjectURL(file));
    }
  };

  const validateForm = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    // Validate 'Visit From' date
    if (formData.visitFrom < currentDate) {
      handleToastOpen("error", "'From date' must be today or later.");
      return false;
    }

    // Validate 'Visit Till' date
    if (formData.visitTill < formData.visitFrom) {
      handleToastOpen("error", "'Till date' must be after 'From date'.");
      return false;
    }

    // Validate photo upload
    if (!formData.photo) {
      handleToastOpen("error", "Please upload a photo.");
      return false;
    }

    // Validate document upload
    if (!formData.document) {
      handleToastOpen("error", "Please upload a document.");
      return false;
    }

    // Validate email format using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      handleToastOpen("error", "Please enter a valid email address.");
      return false;
    }

    // Validate phone number (must be exactly 10 digits)
    // const phoneRegex = /^\d{10}$/;
    // if (!phoneRegex.test(formData.contact)) {
    //   handleToastOpen("error", "Please enter a valid 10-digit phone number.");
    //   return false;
    // }

    // Validate purpose of visit (not more than 100 words)
    const wordCount = formData.purpose.trim().split(/\s+/).length;
    if (wordCount > 100) {
      handleToastOpen("error", "Purpose of visit should not exceed 100 words.");
      return false;
    }

    return true; // Return true if all validations pass
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsClicked(true);
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    if (!isFormComplete()) {
      setLoading(false);
      handleToastOpen(
        "error",
        "Please fill all the required fields and upload the necessary files."
      );
      return;
    }

    const form = new FormData();
    const fullPhoneNumber = `${selectedCountryCode}${formData.contact}`;
    const whomToVisitFullPhoneNumber = `${selectedCountryCode}${formData.whomToVisitContact}`;
    console.log("fullPhoneNumber", fullPhoneNumber);
    console.log("whomToVisitFullPhoneNumber", whomToVisitFullPhoneNumber);
    form.append("fullName", `${formData.name} ${formData.surname}`);
    form.append("contactNumber", fullPhoneNumber);
    form.append("email", formData.email);
    form.append("visitFrom", formData.visitFrom);
    form.append("visitTill", formData.visitTill);
    form.append("purpose", formData.purpose);
    form.append("whomToVisitName", formData.whomToVisitName); // Append 'Whom to Visit' name
    form.append("whomToVisitContact", formData.whomToVisitContact); // Append 'Whom to Visit' contact
    form.append("whomToVisitEmail", formData.whomToVisitEmail); // Append 'Whom to Visit' email
    form.append("photo", formData.photo);
    form.append("document", formData.document);
    form.append("documentType", formData.documentType); // Append document type
    form.append(
      "fe_url",
      window.location.href.split("/visitor-mgmt")[0] + "/visitor-mgmt"
    );
    try {
      await axios.post(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/addVisitor`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setFormData({
        name: "",
        surname: "",
        contact: "",
        email: "",
        visitFrom: "",
        visitTill: "",
        purpose: "",
        whomToVisitName: "",
        whomToVisitContact: "",
        whomToVisitEmail: "",
        photo: null,
        document: null,
        documentType: "",
      });
      setPhotoPreview(null);
      setDocumentPreview(null);
      setLoading(false);
      handleToastOpen("success", "Form submitted successfully!");
    } catch (error) {
      console.error("Error adding visitor:", error);
      setLoading(false);
      handleToastOpen("error", "Error submitting the form. Please try again.");
    }
  };

  const handleOpenPhotoPreview = () => setOpenPhotoPreview(true);
  const handleClosePhotoPreview = () => setOpenPhotoPreview(false);
  const handleOpenDocumentPreview = () => setOpenDocumentPreview(true);
  const handleCloseDocumentPreview = () => setOpenDocumentPreview(false);

  const handleVerify = async () => {
    try {
      const { whomToVisitName, whomToVisitContact, whomToVisitEmail } =
        formData;

      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/verifyApprover`,
        {
          full_name: whomToVisitName,
          contact_details: `${selectedWhomToVisitCountryCode}${whomToVisitContact}`,
          email_id: whomToVisitEmail,
        }
      );

      if (response.status === 200) {
        if (response.data.success) {
          setFormData((prevData) => ({
            ...prevData,
            whomToVisitName: response.data.fullName,
            whomToVisitEmail: response.data.email,
            whomToVisitContact: response.data.contact_details,
          }));
          setIsVerified(true); // Update the state when verification is successful
          handleToastOpen("success", "Apporver verified");
        } else {
          setIsVerified(false); // Set to false if verification fails
          handleToastOpen(
            "error",
            response.data.message ||
              "Verification failed. No matching records found."
          );
        }
      } else {
        setIsVerified(false); // Set to false in case of error
        handleToastOpen("error", "An error occurred during verification.");
      }
    } catch (error) {
      setIsVerified(false); // Set to false in case of error
      handleToastOpen("error", "An error occurred during verification.");
      console.error("Verification error:", error);
    }
  };

  const isFormComplete = () => {
    console.log("formData", formData);
    return (
      (formData.name || "").trim() !== "" &&
      (formData.surname || "").trim() !== "" &&
      (formData.contact || "").trim() !== "" &&
      (formData.email || "").trim() !== "" &&
      (formData.visitFrom || "").trim() !== "" &&
      (formData.visitTill || "").trim() !== "" &&
      (formData.purpose || "").trim() !== "" &&
      isVerified &&
      formData.photo &&
      formData.document
    );
  };

  return (
    <>
      <CBackDrop cOpen={loading} />
      <Grid container justifyContent="center" sx={{ margin: "5em auto" }}>
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <Card
            sx={{
              padding: "2em",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: "1em",
                textAlign: "center",
              }}
            >
              Visitor Registration Form
            </Typography>
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1em",
                }}
              >
                Personal Information :
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="First Name"
                    name="name"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z\s]/g,
                        ""
                      ); // Allow letters and spaces
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="Surname"
                    name="surname"
                    fullWidth
                    value={formData.surname}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z\s]/g,
                        ""
                      ); // Allow letters and spaces
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="Contact Number"
                    name="contact"
                    fullWidth
                    value={formData.contact}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Only allows numbers
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            value={selectedCountryCode}
                            onChange={(e) =>
                              setSelectedCountryCode(e.target.value)
                            }
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                paddingRight: "24px !important",
                              },
                            }}
                            renderValue={(selected) => selected} // Show only the country_code after selection
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200, // Limits the dropdown height to show 5 items at a time
                                },
                              },
                            }}
                          >
                            {countryCodes.length > 0 ? (
                              countryCodes.map((country) => (
                                <MenuItem
                                  key={country.country_code}
                                  value={country.country_code}
                                >
                                  {country.country_code} -{" "}
                                  {country.country_name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>Loading...</MenuItem>
                            )}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="Email"
                    name="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    error={
                      formData.email &&
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        formData.email
                      )
                    } // Email validation only after the user starts typing
                    helperText={
                      formData.email &&
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        formData.email
                      )
                        ? "Invalid email format"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="Visit From"
                    name="visitFrom"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formData.visitFrom}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    label="Visit Till"
                    name="visitTill"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={formData.visitTill}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    label="Purpose of Visit"
                    name="purpose"
                    fullWidth
                    value={formData.purpose}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    Whom To Visit
                    <Tooltip
                      title="You can select either Full Name, Contact Details, or Email ID and click Verify to approve the visitor."
                      arrow
                    >
                      <HelpOutlineIcon
                        sx={{
                          fontSize: "1rem",
                          marginLeft: "0.5em",
                          color: "gray",
                        }}
                      />
                    </Tooltip>
                    &nbsp;:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Full Name"
                    name="whomToVisitName"
                    fullWidth
                    value={formData.whomToVisitName}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^a-zA-Z\s]/g,
                        ""
                      ); // Allow letters and spaces
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Contact Details"
                    name="whomToVisitContact"
                    fullWidth
                    value={formData.whomToVisitContact}
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Only allows numbers
                    }}
                    InputProps={{
                      startAdornment: !isVerified ? (
                        <InputAdornment position="start">
                          <Select
                            value={selectedWhomToVisitCountryCode}
                            onChange={(e) =>
                              setSelectedWhomToVisitCountryCode(e.target.value)
                            }
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                paddingRight: "24px !important",
                              },
                            }}
                            renderValue={(selected) => selected} // Show only the country_code after selection
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200, // Limits the dropdown height to show 5 items at a time
                                },
                              },
                            }}
                          >
                            {countryCodes.length > 0 ? (
                              countryCodes.map((country) => (
                                <MenuItem
                                  key={country.country_code}
                                  value={country.country_code}
                                >
                                  {country.country_code} -{" "}
                                  {country.country_name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>Loading...</MenuItem>
                            )}
                          </Select>
                        </InputAdornment>
                      ) : null, // Hide dropdown when verified
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email ID"
                    name="whomToVisitEmail"
                    fullWidth
                    value={formData.whomToVisitEmail}
                    onChange={handleInputChange}
                    error={
                      formData.whomToVisitEmail &&
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        formData.whomToVisitEmail
                      )
                    } // Email validation only after the user starts typing
                    helperText={
                      formData.whomToVisitEmail &&
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        formData.whomToVisitEmail
                      )
                        ? "Invalid email format"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleVerify}
                    sx={{
                      height: "100%", // Ensure the button is the same height as the input fields
                    }}
                    disabled={!isVerifyEnabled}
                  >
                    Verify
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    Upload Documents :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    required
                    label="Select Document Type"
                    name="documentType"
                    fullWidth
                    value={formData.documentType}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Aadhar Card">Aadhar Card</MenuItem>
                    <MenuItem value="PAN Card">PAN Card</MenuItem>
                    <MenuItem value="Driving License">Driving License</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ marginTop: "1em" }}
                  >
                    Upload Photo*
                    <input
                      type="file"
                      name="photo"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                  {photoPreview && (
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: "0.5em" }}
                      onClick={handleOpenPhotoPreview}
                    >
                      Preview Photo
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ marginTop: "1em" }}
                    disabled={!formData.documentType} // Only enable if document type is selected
                  >
                    Upload Document*
                    <input
                      type="file"
                      name="document"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                  {documentPreview && (
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: "0.5em" }}
                      onClick={handleOpenDocumentPreview}
                    >
                      Preview Document
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    sx={{ marginTop: "1em" }}
                    disabled={!isFormComplete()}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>

      {/* Photo Preview Dialog */}
      <Dialog
        open={openPhotoPreview}
        onClose={handleClosePhotoPreview}
        maxWidth="md"
      >
        <DialogTitle>Photo Preview</DialogTitle>
        <DialogContent>
          <img
            src={photoPreview}
            alt="Photo Preview"
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhotoPreview} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Document Preview Dialog */}
      <Dialog
        open={openDocumentPreview}
        onClose={handleCloseDocumentPreview}
        maxWidth="md"
      >
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent>
          <img
            src={documentPreview}
            alt="Photo Preview"
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDocumentPreview} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VisitorForm;
