import React, { useEffect } from "react";
import MatRequestVM from "../viewmodel/MatRequestVM";
import CText from "../../../basecomp/components/CText";
import CInput from "../../../basecomp/components/CInput2";
import CButton from "../../../basecomp/components/CButton";
import CChooseFile from "../../../basecomp/components/CChooseFile";
import CDropDown from "../../../basecomp/components/CDropDown";
import MCTTable from "./MCTTable";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import { useNavigate, useLocation } from "react-router-dom";
import EmailRequestView from "./EmailRequestView";
import {
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_IN_PROGRESS,
} from "../../../material_creation/assets/Strings";
import CBackDrop from "../../../basecomp/components/CBackDrop";

const MatRequestView = () => {
  const {
    handleFileChoose,
    handleUpload,
    isTableVisible,
    downloadExportFile,
    handleStatusChange,
    filteredList,
    isLoading,
    handleRequestSampleUpload,
  } = MatRequestVM();

  const navigate = useNavigate();
  const location = useLocation();

  // Get unique values for type_of_request and plant_id
  // Define allowed statuses
  const allowedStatuses = ["In Progress", "On Hold", "Pending"];

  // Filter the list based on allowed statuses
  const filteredByStatus = filteredList.filter((item) =>
    allowedStatuses.includes(item.status)
  );

  // Generate unique values for each property
  const uniqueOperations = [
    ...new Set(filteredByStatus.map((item) => item.type_of_request)),
  ];

  const uniquePlantCodes = [
    ...new Set(filteredByStatus.map((item) => item.plant_id)),
  ];

  const uniqueStatus = [
    ...new Set(filteredByStatus.map((item) => item.status)),
  ];

  const cHeaderData = [
    "Material No",
    "Date",
    "Description",
    <CDropDown
      cId="dd_operations"
      cHelperText="Operations"
      cData={uniqueOperations}
      cOnChange={(selectedStatus) =>
        handleStatusChange("type_of_request", selectedStatus)
      }
      cFullWidth="true"
    />,
    <CDropDown
      cId="dd_plant_code"
      cHelperText="Plant Code"
      cData={uniquePlantCodes}
      cOnChange={(selectedStatus) =>
        handleStatusChange("plant_id", selectedStatus)
      }
      cFullWidth="true"
    />,
    <CDropDown
      cId="dd_status"
      cHelperText="Status"
      cData={uniqueStatus}
      cOnChange={(selectedStatus) =>
        handleStatusChange("status", selectedStatus)
      }
      cFullWidth="true"
    />,
    "Request Status",
    "Reason",
  ];

  const cRowData = [
    { type: "T", value: "material_number" },
    { type: "T", value: "request_date" },
    { type: "T", value: "description" },
    { type: "T", value: "type_of_request" },
    { type: "T", value: "plant_id" },
    { type: "T", value: "status" },
    {
      type: "B",
      value: "Start",
      isDisable: (actualRow) =>
        actualRow.status === STATUS_APPROVED ||
        actualRow.status === STATUS_REJECTED ||
        actualRow.status === STATUS_IN_PROGRESS,
      tooltipText: "Click to Start the Progress Page",
      actionV: (data) => {
        const id = data.id;
        navigate(`/material-creation-demo/process/${String(id)}`);
      },
    },
    { type: "T", value: "reason" },
  ];

  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <CMainGrid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ margin: "6em 0.5em 0.5em 0.5em" }}
      >
        <CMainCard sx={{ padding: "0.5em" }}>
          <CMainGrid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <CMainGrid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <CButton
                cId="mct_sample_btn"
                cText="Sample File"
                cTooltipText="Download sample file"
                cOnClick={handleRequestSampleUpload}
                sx={{
                  whiteSpace: "nowrap",
                  height: "40px", // Match height with file input
                }}
              />
              <CChooseFile
                cFileType="XCL"
                cTooltipText="Upload excel"
                cHelperText="*File Types Excel/CSV"
                cHandleFileChange={handleFileChoose}
              />
              <CButton
                cId="mct_submit_btn"
                cText="Submit"
                cTooltipText="Submit the action"
                cOnClick={handleUpload}
                sx={{
                  height: "40px", // Match height with file input
                }}
              />
            </CMainGrid>

            <CMainGrid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <EmailRequestView />
              <CButton
                cId="mct_download_btn"
                cText="Download"
                cTooltipText="Download the Sample file"
                cOnClick={downloadExportFile}
              />
            </CMainGrid>
          </CMainGrid>
        </CMainCard>
      </CMainGrid>

      {isTableVisible && (
        <CMainCard>
          <CText cVariant="header_card" cText="Material Requests" />
          <MCTTable
            cHeaderData={cHeaderData}
            cRowData={cRowData}
            cActualData={filteredList}
            cIsPagination={true}
            cSmallSize={false}
          />
        </CMainCard>
      )}
    </>
  );
};

export default MatRequestView;
