// import { AxiosWrapper } from "fe_comp_base";
import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";


const {
  axiosPostCallWithToken
} = AxiosWrapper;


export const addAnyFileToTable = async (selectedApi, selectedFiles) => {
  const formData = new FormData();
  for (let i = 0; i < selectedFiles.length; i++) {
    formData.append("file", selectedFiles[i]);
  }
  const response = await axiosPostCallWithToken(
    `/${selectedApi}`,
    formData
  );
  return response;
};

