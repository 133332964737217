//import { AxiosWrapper, Logger } from "fe_comp_base";
import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
import axios from "axios";

const {
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosPutCallWithToken,
  axiosGetCallHeaderWithToken,
} = AxiosWrapper;

export const uploadMaterialData = async (selectedFiles) => {
  const formData = new FormData();
  for (let i = 0; i < selectedFiles.length; i++) {
    formData.append("file", selectedFiles[i]);
  }
  const response = await axiosPostCallWithToken(
    `/mct/uploadMaterialData`,
    formData
  );
  return response;
};

export const uploadBulkEmailRequests = async (selectedBulkFile) => {
  const formData = new FormData();
  for (let i = 0; i < selectedBulkFile.length; i++) {
    formData.append("file", selectedBulkFile[i]);
  }
  console.log("selectedBulkFile", selectedBulkFile);
  const response = await axiosPostCallWithToken(
    `/mct/uploadBulkEmailRequests`,
    formData
  );
  return response;
};

export const addEmailRequest = async (emailInput) => {
  const response = await axiosPostCallWithToken(
    `/mct/addEmailRequest`,
    emailInput
  );
  return response;
};

export const getDownloadExportFile = async () => {
  const response = await axiosGetCallHeaderWithToken(
    `/mct/downloadExportFile?tableName="mct_table"`,
    "application/excel"
  );
  return response;
};

export const getMCTTable = async () => {
  const response = await axiosGetCallWithToken(`/mct/getMCTTable`);
  return response;
};

export const downloadExcelByDateRange = async (
  startDate,
  endDate,
  buttonKey
) => {
  const response = await axiosGetCallHeaderWithToken(
    `/mct/downloadExcelByDateRange?startDate=${startDate}&endDate=${endDate}&method=${buttonKey}`,
    "application/excel"
  );
  return response;
};

export const updateStatusMCTTableById = async (id) => {
  const response = await axiosPutCallWithToken(
    `/mct/updateStatusMCTTableById`,
    { id: id }
  );
  return response;
};

export const updateStatusAndData = async (
  id,
  processInput,
  newStatus,
  userName
) => {
  const response = await axiosPutCallWithToken(`/mct/updateStatusAndData`, {
    id: id,
    input: processInput,
    newStatus: newStatus,
    userName: userName,
  });
  return response;
};

export const validateData = async (id, processInput, type) => {
  const response = await axiosPostCallWithToken(`/mct/validateData`, {
    inputJson: processInput,
  });
  return response;
};

export const getSampleEmailBulkUploadFile = async () => {
  const response = await axiosGetCallHeaderWithToken(
    `/mct/getSampleCsv`,
    "text/csv"
  );
  return response;
};
