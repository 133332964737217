// components/Dashboard/SummaryCards.js
import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Tooltip, IconButton } from "@mui/material";
import { 
  Timeline, 
  AccessTime, 
  Person, 
  Computer,
  TrendingUp,
  TrendingDown,
  Info
} from "@mui/icons-material";
import { formatTime } from './utils';

const SummaryCard = ({ title, value, icon: Icon, subtitle, trend, info }) => {
  // Calculate background color based on trend
  const getTrendColor = () => {
    if (!trend) return '#f5f5f5';
    return trend > 0 ? '#e8f5e9' : trend < 0 ? '#ffebee' : '#f5f5f5';
  };

  const getTrendIcon = () => {
    if (!trend) return null;
    return trend > 0 ? <TrendingUp color="success" /> : <TrendingDown color="error" />;
  };

  return (
    <Card 
      sx={{ 
        height: '100%',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }
      }}
    >
      <CardContent sx={{ position: 'relative', height: '100%' }}>
        <Box 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            right: 0, 
            width: '30%', 
            height: '100%', 
            backgroundColor: getTrendColor(),
            borderTopLeftRadius: '100%',
            borderBottomLeftRadius: '100%',
            zIndex: 0 
          }} 
        />
        
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography color="textSecondary" variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {title}
              {info && (
                <Tooltip title={info}>
                  <IconButton size="small">
                    <Info fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            <Icon sx={{ color: 'primary.main', opacity: 0.8 }} />
          </Box>

          <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
            {value}
          </Typography>

          {subtitle && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
              {getTrendIcon()}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const SummaryCards = ({ stats, popularPages }) => {
  const totalUsers = stats.length;
  const activePercentage = totalUsers ? Math.round((totalUsers) * 100) : 0;

  const totalVisits = popularPages.reduce((acc, curr) => acc + Number(curr.visit_count), 0);
  const averageVisitsPerUser = totalUsers ? Math.round(totalVisits / totalUsers) : 0;

  const avgSessionDuration = Math.floor(
    stats.reduce((acc, curr) => {
      const totalTime = curr.activities?.reduce((sum, act) => sum + act.time_spent, 0) || 0;
      return acc + totalTime;
    }, 0) / stats.length
  );

  return (
    <Grid container spacing={3} mb={4}>
      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          title="Total Users"
          value={totalUsers}
          icon={Person}
          subtitle={`total number of users logged in so far`}
          trend={activePercentage - 50}  
          info="Total number of users who have accessed the system"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          title="Total Sessions"
          value={totalUsers}
          icon={Computer}
          subtitle={`total sessions created so far`}
          info="Number of sessions currently logged into the system"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          title="Pages Visited"
          value={totalVisits}
          icon={Timeline}
          subtitle={`${averageVisitsPerUser} avg per user`}
          trend={15}  // Example trend
          info="Total number of page visits across all users"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          title="Avg. Session Duration"
          value={formatTime(avgSessionDuration)}
          icon={AccessTime}
          subtitle="per logging session"
          info="Average time users spend per session"
        />
      </Grid>
    </Grid>
  );
};

export default SummaryCards;