import React from "react";
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import CCheckButton from "./CCheckButton";

const CCheckGroup = ({
  cId,
  cData,
  cIsSingle,
  cLabelEmpty,
  cOnChange,
  cIsSmall,
  dcat, // Prop to determine the layout
}) => {
  const onChange = (key, isChecked) => {
    if (cOnChange !== undefined) {
      cOnChange(key, isChecked);
    }
  };

  if (!cData || cData.length === 0) {
    return null;
  }

  const { data, filters, checks } = cData;

  // Generate check button components
  const CheckBtnComponents = [...data.entries()].map(([key, item], index) =>
    filters.has(key) ? (
      <CCheckButton
        cId={cId}
        key={index}
        cLabel={item === null ? cLabelEmpty : item}
        cChecked={checks.has(key)}
        cIsSingle={cIsSingle}
        cHandleCheck={() => onChange(key, true)}
        cHandleUnCheck={() => onChange(key, false)}
        cIsSmall={cIsSmall}
        dcat={dcat} // Pass the dcat prop
      />
    ) : null
  );

  // Apply styles based on the dcat prop
  const containerStyle = dcat
    ? {
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "13.5em",
        padding: "0 1em", // Remove top and bottom padding
        gap: "0", // Remove gap between items when dcat is true
      }
    : {
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "13.5em",
      };

  // Determine the component to render based on cIsSingle
  const Content = cIsSingle ? (
    <RadioGroup value={cIsSingle ? [...checks][0] : undefined}>
      {CheckBtnComponents}
    </RadioGroup>
  ) : (
    <FormGroup>{CheckBtnComponents}</FormGroup>
  );

  return dcat ? (
    <Box sx={containerStyle}>{Content}</Box>
  ) : cIsSingle ? (
    <RadioGroup sx={{ padding: "1em", height: "13.5em", overflowY: "auto" }}>
      {CheckBtnComponents}
    </RadioGroup>
  ) : (
    <FormGroup sx={{ padding: "1em", height: "13.5em", overflowY: "auto" }}>
      {CheckBtnComponents}
    </FormGroup>
  );
};

export default CCheckGroup;
