import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const { axiosGetCallWithToken } = AxiosWrapper;

export const fetchJobDetails = async (jobCardNumber) => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getJobIdCatByJobNos/${jobCardNumber}`
  );
  return response.data;
};

export const replenishmentEmail = async (
  jobId,
  equipmentId,
  jobDirective,
  type
) => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getPdf?jobId=${jobId}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&type=${type}`
  );
  return response;
};
