import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Styled components
const StyledFooter = styled("footer")({
  backgroundColor: "#fff",
  padding: "0.5em",
  position: "fixed",
  bottom: 0,
  right: 0,
  width: "100%",
  zIndex: 9999,
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
});

const StyledFooterImage = styled("img")({
  width: "6.25em",
  height: "auto",
  marginRight: "0.18em",
});

// Footer component
const Footer = ({ cLogo }) => {
  // Check for userName in local storage
  const userName = localStorage.getItem('userName');
  
  // If userName is not present, don't render the footer
  if (!userName) {
    return null;
  }

  return (
    <StyledFooter>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        paddingBottom="0.25em"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            margin: "0.31em",
            paddingRight: "0.18em",
          }}
        >
          Developed By
        </Typography>
        <StyledFooterImage src={cLogo} alt="Footer Logo" />
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
