
export const header ="ADD CATALOGUE";

export const subHeader ="CATALOGUE DETAILS";

export const cTextVariant ="heading_section";

export const sampleDownload ="Download sample file";

export const upload ="Upload";

export const fileType = "XCL";

export const tooltip = "Upload Excel file";

export const UploadType = "*File Types CSV";

export const download = "Download";







