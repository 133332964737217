import { QRCodeSVG } from "qrcode.react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { replenishmentEmail } from "../model/ReplenishmentModel";
import { useState } from "react";

const ReplenishmentVM = (props) => {
  const {
    spDetails,
    jobCardNumber,
    equipmentID,
    jobDirective,
    equipmentType,
    jobType,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  let response;

  //Job Details : Validations
  const { handleToastOpen } = useToast();

  const isDisabled = !jobDirective || !equipmentType || !equipmentID;
  const handleIconButtonClick = async () => {
    // Check for zero quantities and stop execution if found
    for (let i = 0; i < spDetails.length; i++) {
      const item = spDetails[i];
      if (Number(item.quantity) <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Stop execution
      }
    }

    if (spDetails.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive, Equipment type, and Equipment Id."
      );
      return; // Stop execution
    }

    setIsLoading(true); // Start the loader
    const startTime = Date.now(); // Track when the loader started

    try {
      const type = "replenishment_mail";
      response = await replenishmentEmail(
        jobCardNumber,
        equipmentID.equipment_number,
        jobDirective,
        type
      );

    } catch (error) {
      handleToastOpen("error", "An error occurred while sending email");
      console.error("Error sending email:", error);
    } finally {
      // Ensure loader is hidden after at least 20 seconds
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(20000 - elapsedTime, 0); // Calculate remaining time

      setTimeout(() => {
        setIsLoading(false);
        if (response.status === 200) {
          handleToastOpen("success", "Email sent successfully");
        } else {
          console.error("Error sending email:", response.status);
          handleToastOpen("error", "Failed to send email. Please try again.");
        }
      }, remainingTime);
    }
  };


  const mrpType = `SAP Material Requirement Planning Type:

  VB   Manual Reorder Point Planning (SAP will trigger PR/Planned orders based on manually set re-order point by the user

  ND   No (Demand) Planning; user needs to create PR manually

  PD  PR/PO trigger post quantity requested in workorder (lot for lot)`;

  const lotSize = `SAP lot size setting:

  FX      Fixed lot size

  HB      Replenishment to maximum stock level

  EX      Lot for lot order quantity

  VB/FX   Re-Order Point with Fixed Re-Order Quantity; trigger by MRP

  VB/HB   Re- Order Point to the maximum level; trigger by MRP

  PD/EX   Lot for Lot when QTY is added to a SAP EAMS Work order; trigger by MRP

  ND      Nothing happens; Create manually a PR`;

  const infoHeadData = [
    { text: "Material", tooltipText: "SAP material number" },
    { text: "Description", tooltipText: "SAP material description" },
    { text: "QTY", tooltipText: "Quantity requested in shopping basket" },
    {
      text: "OHQ",
      tooltipText: "On Hand Quantity during last monthly data refresh",
    },
    { text: "MRP Type", tooltipText: mrpType },
    { text: "Lot Size", tooltipText: lotSize },
    {
      text: "Re-order Point",
      tooltipText:
        "SAP MRP triggers if On Hand QTY is below ROP (exception: if ROP is 0, it triggers at 0)",
    },
    {
      text: "Max. Stock Level",
      tooltipText: "SAP MRP replenishes to the maximum stock level",
    },
    {
      text: "Fix. Lot Size",
      tooltipText: "SAP MRP replenishes with a fixed lot size Quantity",
    },
    { text: "UOM", tooltipText: "Unit of Measure" },
    {
      text: "QR code",
      tooltipText: "Quick Response Code containing the SAP material number",
    },
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: `material_no`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `material_desc`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },

    {
      value: `quantity`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `ohqty`,
      statusType: "bg",
      status: (actualRow) => {
        if (actualRow.ohqty === "0") {
          return 2;
        } else if (actualRow.ohqty >= actualRow.quantity) {
          return 1;
        } else {
          return 0;
        }
      },
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `type`,
      statusType: "bg",
      status: (actualRow) => (actualRow.type === "VB" ? 1 : 0),
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `ls`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `reorder_pt`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `max_level`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `fix_lot_size`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `uom`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.material_no ? (
          <QRCodeSVG value={actualRow.material_no} size={40} />
        ) : (
          ""
        ),
      tooltipText: (actualRow) => (actualRow.material_no ? null : " "),
      type: "IC",
      actualType: (actualRow) => actualRow.tableMode,
    },
  ]);

  return {
    spDetails,
    mrpType,
    lotSize,
    handleIconButtonClick,
    isLoading,
    infoHeadData,
    infoRowData,
  };
};

export default ReplenishmentVM;
