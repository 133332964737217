import React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CCheckGroup from "./CCheckGroup";
import CInput from "./CInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";

const resetTooltipText = "Reset Filter";
let tColor = "white";
let cVariant = "h6";

const CFilterItem = ({
  cId,
  cKey,
  cKeyReset,
  cHeader,
  cData,
  cIsSingle,
  cShowSearch,
  cOnSearchCheck,
  cOnFilterCheck,
  cOnSearchClick,
  cOnResetClick,
  cShowDelete,
  cTooltipText,
  cLabelKey,
  cLabelEmpty,
  dcat,
  cShowHeaderCheckbox = false, // New prop for header checkbox visibility
  cHeaderCheckboxChecked = false, // New prop for checkbox state
  cOnHeaderCheckboxChange, // New prop for checkbox change handler
}) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: "0.8em",
    },
  }));

  const onSearchClick = () => {
    if (cOnSearchClick !== undefined) {
      cOnSearchClick(cKey);
    }
  };

  const handleResetClick = () => {
    if (cOnResetClick !== undefined) {
      cOnResetClick(cKey);
    }
  };

  return (
    <Card
      aria-label={cTooltipText}
      variant="outlined"
      sx={{ height: "22em", borderRadius: "0.6em" }}
    >
      {cHeader !== undefined && (
        <Box bgcolor="secondary.main" padding={"0.5em"}>
          <LightTooltip title={cTooltipText}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "0.5em",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {cShowHeaderCheckbox && (
                  <Checkbox
                    checked={cHeaderCheckboxChecked}
                    onChange={(e) =>
                      cOnHeaderCheckboxChange?.(e.target.checked)
                    }
                    sx={{
                      color: tColor,
                      "&.Mui-checked": {
                        color: tColor,
                      },
                      marginLeft: "-0.75em", // Align with radio buttons
                    }}
                  />
                )}
                <Typography
                  variant={cVariant}
                  color={tColor}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  {cHeader}
                </Typography>
              </Box>
              {cShowDelete && (
                <Tooltip title={resetTooltipText} arrow>
                  <IconButton
                    aria-label={resetTooltipText}
                    onClick={handleResetClick}
                  >
                    <DeleteForeverIcon id="resetIcon" sx={{ color: tColor }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </LightTooltip>
        </Box>
      )}
      {cShowSearch && (
        <CInput
          cId={cId}
          cHintText="Search"
          cType={"search"}
          cVariant={"filled"}
          cIsSmall={true}
          cOnChange={cOnSearchCheck}
          cKeyReset={cKeyReset}
          cHandleSubmit={onSearchClick}
          cInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          cFullWidth={true}
          cHelperText={""}
        />
      )}
      <CCheckGroup
        cId={cId}
        cData={cData.get(cKey)}
        cIsSingle={cIsSingle}
        cLabelKey={cLabelKey}
        cOnChange={cOnFilterCheck}
        cLabelEmpty={cLabelEmpty}
        cIsSmall={true}
        dcat={dcat}
      />
    </Card>
  );
};

export default CFilterItem;
