import React from "react";
import CInput from "../../../basecomp/components/CInput2";
import CButton from "../../../basecomp/components/CButton";
import CDropDown from "../../../basecomp/components/CDropDown";
import CTable from "../../../basecomp/components/CTable";
import CDialog from "../../../basecomp/components/CDialog";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MatProcessVM from "../viewmodel/MatProcessVM";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import SearchIcon from "@mui/icons-material/Search";
import ProcessingModal from "./ProcessingModal";

import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
const { axiosGetCallWithToken } = AxiosWrapper;

const MatProcessView = () => {
  const {
    processInput,
    handleInputChange,
    handleInfoClick,
    handleBackButtonClick,
    buttonJson,
    handleButtonClick,
    dialogOpen,
    mpnDialogOpen,
    handleDialogClose,
    handleMpnDialogClose,
    tableActualData,
    tableRowData,
    tableHeadData,
    isProcessingModalOpen,
    handleModalClose,
    processingModalMessage,
  } = MatProcessVM();

  const navigate = useNavigate();

  const dialogContent = (
    <CTable
      cHeaderData={tableHeadData}
      cRowData={tableRowData}
      cActualData={tableActualData}
      cIsPagination={false}
      cSmallSize={false}
    />
  );

  const MpnDuplicateDialogContent = (
    <CTable
      cHeaderData={tableHeadData}
      cRowData={tableRowData}
      cActualData={tableActualData}
      cIsPagination={false}
      cSmallSize={false}
    />
  );

  const openGoogleSearch = async () => {
    const mpnValue = processInput.mpn_number.value;
    const mpnName = processInput.mpn_name.value;

    if (mpnName) {
      try {
        // Make an API call to check if the link for the given mpnName exists in the database
        const response = await axiosGetCallWithToken(
          `/mct/getLinkByMpnName?mpn_name=${mpnName}`
        );

        if (response.data && response.data.m_link) {
          // If the link exists, append the mpnValue to the link and open it
          const linkWithMpnValue = `${response.data.m_link}${mpnValue}`;
          window.open(linkWithMpnValue, "_blank");
          return;
        }
      } catch (error) {
        console.error("Error fetching link:", error);
      }
    }

    // If no link is found, proceed with the normal Google search
    if (mpnValue && mpnName) {
      window.open(
        `https://www.google.com/search?q=${mpnName} ${mpnValue}`,
        "_blank"
      );
    } else if (mpnValue) {
      window.open(`https://www.google.com/search?q=${mpnValue}`, "_blank");
    } else if (mpnName) {
      window.open(`https://www.google.com/search?q=${mpnName}`, "_blank");
    }
  };

  const getEmailFromUsername = async (username) => {
    const response = await axiosGetCallWithToken(
      `/mct/getEmailFromUser?requester_name=${username}`
    );
    return response.data; // Ensure we return the data object from response
  };

  const openOutlookEmail = async (userName) => {
    if (userName) {
      try {
        const data = await getEmailFromUsername(userName);

        // This URL opens the Outlook web app with the email prefilled
        if (data && data.email) {
          // Use "email" instead of "requester_email"
          window.open(
            `https://outlook.office.com/mail/deeplink/compose?to=${data.email}`,
            "_blank"
          );
        } else {
          console.error("Email not found for the user.");
        }
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    }
  };

  const isReasonValid =
    !processInput["type_of_request"] ||
    processInput["type_of_request"].value !== "DELETE" ||
    (processInput["type_of_request"].value === "DELETE" &&
      processInput["reason"] &&
      ["Mark for deletion", "Reactivation"].includes(
        processInput["reason"].value
      ));

  return (
    <CMainGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{ margin: "6em 0.5em 0.5em 0.5em" }}
    >
      <ProcessingModal
        isOpen={isProcessingModalOpen}
        onClose={handleModalClose}
        processingModalMessage={processingModalMessage}
      />
      <CMainCard sx={{ padding: "0.5em" }}>
        <CButton
          cId="mct_backButton"
          cSrc={<ArrowBackIcon />}
          cTooltipText="Go back to previous page"
          cOnClick={() => handleBackButtonClick()}
        />
        <CMainGrid container spacing={1} marginTop={2}>
          {Object.keys(processInput).map((key) => (
            <CMainGrid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              key={key}
              marginTop={1}
              marginBottom={1}
            >
              {key === "mpn_number" ? (
                <CInput
                  cFullWidth={true}
                  cOnChange={(value) => handleInputChange(key)(value)}
                  cDisabled={processInput[key].isDisabled}
                  cIsRequired={processInput[key].isRequired}
                  cValue={processInput[key].value}
                  cOtherType={processInput[key].otherType}
                  cOtherMsg={processInput[key].otherMsg}
                  cHintText={processInput[key].enter}
                  cInputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={openGoogleSearch}>
                          <SearchIcon />
                        </IconButton>
                        {processInput[key].tableViewData && (
                          <IconButton
                            onClick={() =>
                              handleInfoClick(processInput[key].tableViewData)
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : key === "user_name" ? (
                <CInput
                  cFullWidth={true}
                  cOnChange={(value) => handleInputChange(key)(value)}
                  cDisabled={processInput[key].isDisabled}
                  cIsRequired={processInput[key].isRequired}
                  cValue={processInput[key].value}
                  cHintText={processInput[key].enter}
                  cInputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            openOutlookEmail(processInput[key].value)
                          }
                        >
                          <EmailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : key === "reason" &&
                processInput["type_of_request"].value === "DELETE" ? (
                <CDropDown
                  cId="dd_reason"
                  cHelperText="Reason for Request"
                  cData={["Mark for deletion", "Reactivation"]}
                  cOnChange={(selectedReason) =>
                    handleInputChange(key)(selectedReason)
                  }
                  cFullWidth={true}
                  cDefaultValue={processInput["reason"].initialValue}
                  cValue={processInput[key].value}
                />
              ) : (
                <CInput
                  cFullWidth={true}
                  cOnChange={(value) => handleInputChange(key)(value)}
                  cDisabled={processInput[key].isDisabled}
                  cIsRequired={processInput[key].isRequired}
                  cValue={processInput[key].value}
                  cOtherType={processInput[key].otherType}
                  cOtherMsg={processInput[key].otherMsg}
                  cHintText={processInput[key].enter}
                  cRows={processInput[key].isRows === true ? 10 : 1}
                  cInputProps={{
                    ...(processInput[key].tableViewData !== undefined && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleInfoClick(processInput[key].tableViewData)
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }),
                  }}
                />
              )}
            </CMainGrid>
          ))}
        </CMainGrid>

        <CMainGrid container spacing={2} marginTop={2} marginBottom={2}>
          {Object.keys(buttonJson).map((key) => (
            <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3} key={key}>
              <CButton
                cText={buttonJson[key].text}
                cTooltipText={buttonJson[key].tooltipText}
                cOnClick={() => handleButtonClick(key, buttonJson[key].text)}
                cFullWidth={true}
                cDisabled={buttonJson[key].isDisabled || !isReasonValid}
              />
            </CMainGrid>
          ))}
        </CMainGrid>

        <CDialog
          cOpen={dialogOpen}
          cHandleClose={handleDialogClose}
          cTitle="Details"
          cDialogContent={dialogContent}
        />

        <CDialog
          cOpen={mpnDialogOpen}
          cHandleClose={handleMpnDialogClose}
          cTitle="Duplicate MPN Details"
          cDialogContent={MpnDuplicateDialogContent}
        />
      </CMainCard>
    </CMainGrid>
  );
};

export default MatProcessView;
