import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MailIcon from "@mui/icons-material/Mail";
import {
  TableContainer,
} from "@mui/material";
import MaterialSearch from "./MaterialSearch";
import JobDetails from "./JobDetails";
import Replenishment from "./Replenishment";
import SBHeader from "./SBHeader";
import ShoppingBasketVM from "../viewmodel/ShoppingBasketVM";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CBackDrop from "../../../../basecomp/components/CBackDrop";
import CTable from "../../../../basecomp/components/CTable";
import CButton from "../../../../basecomp/components/CButton";
import CText from "../../../../basecomp/components/CText";
import { useLocation } from "react-router-dom";

function ShoppingBasket() {
  const {
    DeleteAll,
    handleGreyRecords,
    setExternalRef,
    setJobDirective,
    setEquipmentType,
    setEquipmentID,
    dropdownData,
    startDate,
    finishDate,
    isMaterialSearchUI,
    setIsMaterialSearchUI,
    isShoppingBasketUI,
    setIsShoppingBasketUI,
    isJobDetailsUI,
    setIsobDetailsUI,
    isReplenishmentUI,
    setIsReplenishmentUI,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    externalRef,
    jobDirective,
    equipmentType,
    equipmentID,
    spDetails,
    setClickedImageIndex,
    setSpDetails,
    setLightboxOpen,
    setSliderImages,
    jobCardNumber,
    spData,
    setSpData,
    handleIconButtonClick,
    setStartDate,
    setFinishDate,
    handleToastOpen,
    lclMaterialNo,
    setOpenCarousel,
    openCarousel,
    setlclMaterialNo,
    isLoading,
    infoHeadData,
    infoRowData,
    carouselImages,
  } = ShoppingBasketVM();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchEqType =
    decodeURIComponent(searchParams.get("equipment_type")) || null;
    let eq_type
    if(searchEqType){
      eq_type=searchEqType
    } else{
      eq_type= equipmentType
    }
  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 3em 5em 3em" }}>
        <Card sx={{ padding: "2em" }}>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer>
              <SBHeader
                spData={spData}
                setSpDetails={setSpDetails}
                spDetails={spDetails}
                setSpData={setSpData}
                setIsMaterialSearchUI={setIsMaterialSearchUI}
                setIsShoppingBasketUI={setIsShoppingBasketUI}
                setIsobDetailsUI={setIsobDetailsUI}
                setIsReplenishmentUI={setIsReplenishmentUI}
                section={section}
                jobType={jobType}
                resources={resources}
                startDate={startDate}
                finishDate={finishDate}
                workDesc={workDesc}
                dropdownData={dropdownData}
                externalRef={externalRef}
                setExternalRef={setExternalRef}
                jobDirective={jobDirective}
                setJobDirective={setJobDirective}
                equipmentType={eq_type}
                setEquipmentType={setEquipmentType}
                equipmentID={equipmentID}
                setEquipmentID={setEquipmentID}
                handleToastOpen={handleToastOpen}
              />

              {isMaterialSearchUI && (
                <>
                  <CText cVariant="header_card" cText="Shopping Basket" />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11.9}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "0.9em",
                    }}>
                    <CButton
                      cId="clear_basket"
                      cIsMain={true}
                      cSrc={<DeleteForeverIcon />}
                      cTooltipText="Delete All"
                      cOnClick={DeleteAll}
                      cDisabled={spData.length === 0}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} margin={1.5}>
                    <div style={{ overflowX: "auto" }}>
                      {spData.length > 0 && (
                        <Grid item xs={12} sm={12} md={12}>
                          <CTable
                            cHeaderData={infoHeadData}
                            cRowData={infoRowData}
                            cActualData={spData}
                            cIsPagination={true}
                            cSmallSize={true}
                          />
                        </Grid>
                      )}
                    </div>
                  </Grid>

                  {openCarousel && (
                    <CCarousel
                      cOpen={openCarousel}
                      cCloseClicked={() => setOpenCarousel(false)}
                      cImages={carouselImages} 
                    />
                  )}

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11.9}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "0.9em",
                      }}>
                      {spData && spData.length === 0 ? (
                        <CButton
                          cId="grey_record"
                          cIsMain={true}
                          cSrc={<LibraryAddIcon />}
                          cTooltipText="Send mail for Material Number Request"
                          cOnClick={handleIconButtonClick}
                          cDisabled={true}
                        />
                      ) : (
                        <CButton
                          cId="grey_record"
                          cIsMain={true}
                          cSrc={<LibraryAddIcon />}
                          cDisabled={
                            spData &&
                            !spData.some(
                              (item) =>
                                item.material_no === null ||
                                item.material_no === ""
                            )
                          }
                          cTooltipText="Send mail for Material Number Request"
                          cOnClick={handleGreyRecords}
                        />
                      )}

                      <CButton
                        cId="email"
                        cIsMain={true}
                        cSrc={<MailIcon />}
                        cTooltipText="Email"
                        cOnClick={handleIconButtonClick}
                        cDisabled={spData.length === 0}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {isShoppingBasketUI && (
                <MaterialSearch
                  setSpDetails={setSpDetails}
                  setSpData={setSpData}
                  setLightboxOpen={setLightboxOpen}
                  setClickedImageIndex={setClickedImageIndex}
                  setSliderImages={setSliderImages}
                  jobCardNumber={jobCardNumber}
                  handleToastOpen={handleToastOpen}
                  lclMaterialNo={lclMaterialNo}
                  setlclMaterialNo={setlclMaterialNo}
                  spData={spData}
                />
              )}
              {isJobDetailsUI && (
                <div>
                  <JobDetails
                    spDetails={spDetails}
                    spData={spData}
                    externalRef={externalRef}
                    jobDirective={jobDirective}
                    equipmentType={equipmentType}
                    equipmentID={equipmentID}
                    section={section}
                    setSection={setSection}
                    jobType={jobType}
                    setJobType={setJobType}
                    resources={resources}
                    setResources={setResources}
                    workDesc={workDesc}
                    setWorkDesc={setWorkDesc}
                    jobCardNumber={jobCardNumber}
                    startDate={startDate}
                    finishDate={finishDate}
                    setStartDate={setStartDate}
                    setFinishDate={setFinishDate}
                    handleToastOpen={handleToastOpen}
                  />
                </div>
              )}
              {isReplenishmentUI && (
                <Replenishment
                  spDetails={spDetails}
                  spData={spData}
                  jobDirective={jobDirective}
                  equipmentType={equipmentType}
                  equipmentID={equipmentID}
                  jobCardNumber={jobCardNumber}
                  handleToastOpen={handleToastOpen}
                  jobType={jobType}
                  setJobType={setJobType}
                />
              )}
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default ShoppingBasket;
