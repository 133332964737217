import React from "react";
import CText from "./CText";
import CButton from "./CButton";

import CChooseFile from "./CChooseFile";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Grid,
} from "@mui/material";

const CDialog = ({
  cOpen,
  cHandleClose,
  cTitle,
  cOnSubmitClick,
  cDialogContent,
  cDialogWidth = "sm",
  cBulkUpload,
  cOnBulkEmailUpload,
  cHandleBulkFileChoose,
  cHandleEmailBulkFileDownload,
}) => {
  const dialogWidthMapping = {
    xs: "800px",
    sm: "1000px",
    md: "1200px",
    lg: "1500px",
    xl: "1800px",
  };

  const dialogWidth = dialogWidthMapping[cDialogWidth] || dialogWidthMapping.md;

  return (
    <Dialog
      open={cOpen}
      onClose={cHandleClose}
      PaperProps={{
        style: {
          width: dialogWidth,
          maxWidth: "100%",
        },
      }}
    >
      <DialogTitle>
        <CText cText={cTitle} cVariant="header_card" />
      </DialogTitle>
      {cBulkUpload && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "24px",
            padding: "0 24px 16px",
          }}
        >
          <CChooseFile
            cFileType="XCL"
            cTooltipText="Upload excel"
            cHelperText="*File Types Excel/CSV"
            cHandleFileChange={cHandleBulkFileChoose}
          />
          <CButton cOnClick={cOnBulkEmailUpload} cText="Bulk Upload" />
          <Tooltip title="Download sample file template" arrow>
            <div>
              <CButton
                cOnClick={cHandleEmailBulkFileDownload}
                cText="Sample File"
              />
            </div>
          </Tooltip>
        </div>
      )}

      <DialogContent>{cDialogContent}</DialogContent>
      <DialogActions>
        <CButton cOnClick={cHandleClose} cText="Close" />
        {cOnSubmitClick && <CButton cOnClick={cOnSubmitClick} cText="Submit" />}
      </DialogActions>
    </Dialog>
  );
};

export default CDialog;
