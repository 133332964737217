//yet to merge it with components

import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

const CCheckButton = ({
  cId,
  cLabel,
  cChecked,
  cHandleCheck,
  cHandleUnCheck,
  cDisabled,
  cIsSingle,
  cTooltipText,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked !== cChecked) {
      setChecked(cChecked);
    }
  }, [cChecked]);

  const onHandleChange = (event) => {
    setChecked(event.target.checked);
    if (cHandleCheck !== undefined && event.target.checked === true) {
      cHandleCheck();
    } else if (cHandleUnCheck !== undefined && event.target.checked === false) {
      cHandleUnCheck();
    }
  };

  if (cIsSingle === true) {
    return (
      <FormControlLabel
        control={
          <Radio
            checked={checked}
            onChange={onHandleChange}
            disabled={cDisabled}
            color="secondary"
            id={cId}
          />
        }
        label={cLabel}
        aria-label={cTooltipText}
      />
    );
  } else {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onHandleChange}
            disabled={cDisabled}
            color="secondary"
            id={cId}
          />
        }
        label={cLabel}
        aria-label={cTooltipText}
      />
    );
  }
};

export default CCheckButton;
