const VRC_KEY = 1;
const CRC_KEY = 2;
const MRC_KEY = 3;
const RRC_KEY = 4;
const DRC_KEY = 5;

const COMMERCIAL_KEY = "Commercial";
const NON_COMMERCIAL_KEY = "Non-Commercial";

const PPP_KEY = "PPP";
const PORT_KEY = "Port";

const finalInfoHeadData = [
  "Sr. No",
  "Main Services",
  "Service Type",
  "Rate",
  "Rate(in Dollars)",
  "Rate(in Rs)",
  "Unit",
  "Quantity",
  "Discount",
  "Amount",
];

const finalInfoRowData = [
  {
    type: "S",
    align: "center",
  },
  { value: `mainType`, type: "T", align: "left" },
  { value: `serviceType`, type: "T", align: "left" },
  { value: `rate`, type: "T", align: "right" },
  { value: `rateC`, type: "T", align: "right" },
  { value: `rateF`, type: "T", align: "right" },
  { value: `unit`, type: "T", align: "center" },
  { value: `quantity`, type: "T", align: "center" },
  { value: `discount`, type: "T", align: "center" },
  { value: `amount`, type: "T", align: "right" },
];

const tariffInfoHeadData = [
  "Sr. No",
  "Main Services",
  "Service Type",
  "Rate",
  "Rate(in Dollars)",
  "Rate(in Rs)",
  "Unit",
  "Quantity",
  "Discount",
  "Amount",
  "Details",
];

const finalPageLabels = {
  DATE: "Date",
  SERVICE: "Service Category",
  CHARGES: "Charges",
  QUANTITY: "Quantity",
  VESSEL_TYPE: "Vessel Type",
};

export const BerthKeys = {
  PPP_KEY,
  PORT_KEY,
};

export const CommercialKeys = {
  COMMERCIAL_KEY,
  NON_COMMERCIAL_KEY,
};

export const ChargeKeys = {
  VRC_KEY,
  CRC_KEY,
  MRC_KEY,
  RRC_KEY,
  DRC_KEY,
};

export {
  finalInfoHeadData,
  finalInfoRowData,
  tariffInfoHeadData,
  finalPageLabels,
};
