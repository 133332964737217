const EQUIPMENT_TYPE = "equipment_type";
const EQUIPMENT_ID = "equipment_number";
const CATALOGUE_ID = "catalogue_id";
const CATALOGUE_NAME = "catalogue_name";
const TERMINAL = "terminal_id";

export const CatKeys = {
  EQUIPMENT_TYPE,
  EQUIPMENT_ID,
  CATALOGUE_ID,
  CATALOGUE_NAME,
  TERMINAL
};
