import React from "react";
// import {
//   CButton,
//   CMainCard,
//   CMainGrid,
//   CDateTimePicker
// } from "fe_comp_base";
import CButton from "../../../basecomp/components/CButton";
import CDateTimePicker from "../../../basecomp/components/CDateTimePicker";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import UploadTemplatesVM from "../viewmodel/UploadTemplatesVM";


const UploadTemplatesView = () => {
  const {
    handleStartDateChange,
    handleEndDateChange,
    handleUpload
  } = UploadTemplatesVM();

  const emailFieldsForm = [
    {
      enter: "Creation",
      key: "creation"
    },

    {
      enter: "Extend",
      key: "extend"
    },

    {
      enter: "IND",
      key: "ind"
    },

    {
      enter: "Taxonomy",
      key: "taxonomy"
    },

    {
      enter: "Eq Function",
      key: "equipment_function"
    },

    {
      enter: "Long Description",
      key: "long_description"
    },

    // {
    //   enter: "Manufacturer",
    //   key: "manufacturer"
    // },

    // {
    //   enter: "MFR Part Profile",
    //   key: "mfr_part_profile"
    // },

    {
      enter: "MPN",
      key: "mpn"
    },
  ];

  return (
    <>
      <CMainGrid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ margin: "5em 0.5em 0.5em 0.5em" }}>
        <CMainCard sx={{ padding: "0.5em", justifyContent: "center" }}>
          <CMainGrid
            container
            paddingLeft={2}
            paddingRight={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CMainGrid item xs={8} sm={6} md={4} lg={4} xl={4}>
              <CDateTimePicker
                cHelperText="Start Date"
                cOnChange={handleStartDateChange}
                cFullWidth={true}
              />
            </CMainGrid>
            <CMainGrid item xs={8} sm={6} md={4} lg={4} xl={4}
            >
              <CDateTimePicker
                cHelperText="End Date"
                cOnChange={handleEndDateChange}
                cFullWidth={true}
              />
            </CMainGrid>
          </CMainGrid>

          <CMainGrid container spacing={1} sx={{ marginTop: "1em" }}>
            {emailFieldsForm.map((item) => (
              <CMainGrid item xs={12} sm={6} md={6} lg={4} xl={4} key={item.key}>
                <CButton
                  cId="mct_btn_download_excel"
                  cText={item.enter}
                  cFullWidth={"true"}
                  cOnClick={() => handleUpload(item.key)}
                />
              </CMainGrid>
            ))}
          </CMainGrid>

        </CMainCard>
      </CMainGrid>
    </>
  );
};

export default UploadTemplatesView;
