import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import GLNDetailView from "./GLNDetailView";
import GLNFilterView from "./GLNFilterView";

const GLNView = () => {
  const [inputData, setInputData] = useState(new Map());
  const [headerCheckboxState, setHeaderCheckboxState] = useState(false);
  const [facilityHeaderCheckboxState, setFacilityHeaderCheckboxState] =
    useState(false);

  const handleSubmit = (newData) => {
    setInputData((prevData) => {
      const updatedData = new Map(prevData);
      newData.forEach((value, key) => {
        updatedData.set(key, value);
      });
      return updatedData;
    });
  };

  return (
    <Grid sx={{ marginTop: "6em", marginBottom: "3em", padding: "1em" }}>
      <GLNFilterView
        handleSubmit={handleSubmit}
        headerCheckboxState={headerCheckboxState}
        setHeaderCheckboxState={setHeaderCheckboxState}
        facilityHeaderCheckboxState={facilityHeaderCheckboxState}
        setFacilityHeaderCheckboxState={setFacilityHeaderCheckboxState}
      />
      <GLNDetailView
        inputData={inputData}
        headerCheckboxState={headerCheckboxState}
      />
    </Grid>
  );
};

export default GLNView;
