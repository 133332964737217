import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Circle,
  ZoomControl,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import { MyLocationRounded } from "@mui/icons-material";
import CCard from "../../../basecomp/components/CCard";
import GLNDetailVM from "../viewmodel/GLNDetailVM";
import "leaflet/dist/leaflet.css";

// Enhanced marker icons with retina support
const createIcon = (color = "blue") =>
  L.icon({
    iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  });

const icons = {
  default: createIcon("blue"),
  selected: createIcon("red"),
  warning: createIcon("orange"),
  success: createIcon("green"),
};

// Updated MapControls component
const MapControls = ({ onLayerChange, onLocate, mapStyle }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: 10,
        left: 10,
        zIndex: 1000,
        p: 1.5,
        borderRadius: 2,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        borderLeft: "3px solid #CCCCCC",
        paddingLeft: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton
          onClick={() => onLayerChange("street")}
          size="small"
          color={mapStyle === "street" ? "primary" : "default"}
          title="Street Map"
          sx={{
            borderRadius: 1,
            fontWeight: mapStyle === "street" ? "bold" : "normal",
          }}
        >
          Map
        </IconButton>
        <IconButton
          onClick={() => onLayerChange("satellite")}
          size="small"
          color={mapStyle === "satellite" ? "primary" : "default"}
          title="Satellite Map"
          sx={{
            borderRadius: 1,
            fontWeight: mapStyle === "satellite" ? "bold" : "normal",
          }}
        >
          Satellite
        </IconButton>
        <IconButton
          onClick={onLocate}
          size="small"
          title="Center Map"
          sx={{ borderRadius: 1 }}
        >
          <MyLocationRounded />
        </IconButton>
      </Box>
    </Paper>
  );
};

// Enhanced MapUpdater
const MapUpdater = ({ markers, selectedIndex, initialLoad }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !markers?.length) return;

    // Strictly validate markers, filtering out [0,0] coordinates
    const validMarkers = markers.filter(
      (marker) =>
        marker.position &&
        Array.isArray(marker.position) &&
        marker.position.length === 2 &&
        marker.position[0] !== 0 &&
        marker.position[1] !== 0
    );

    const timeoutId = setTimeout(() => {
      // Find the selected marker at the specific index, even if it's an invalid marker
      const selectedMarker =
        selectedIndex !== null && selectedIndex >= 0
          ? markers[selectedIndex]
          : null;

      // Check if the selected marker has a valid position
      const hasValidPosition =
        selectedMarker &&
        selectedMarker.position &&
        selectedMarker.position.length === 2 &&
        selectedMarker.position[0] !== 0 &&
        selectedMarker.position[1] !== 0;

      // Determine if we should zoom to max level
      const isSingleMarkerSelected =
        validMarkers.length === 1 || (hasValidPosition && !initialLoad);

      if (isSingleMarkerSelected && hasValidPosition) {
        // Use maximum zoom level of 18 for single marker selection
        map.flyTo(selectedMarker.position, 18, {
          duration: 1.5,
          easeLinearity: 0.25,
        });
      } else if (validMarkers.length > 0) {
        // When multiple markers exist or on initial load, show all valid markers
        const bounds = L.latLngBounds(validMarkers.map((m) => m.position));
        map.flyToBounds(bounds.pad(0.2), {
          duration: 1.5,
          maxZoom: 16,
          padding: [50, 50],
        });
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [map, markers, selectedIndex, initialLoad]);

  return null;
};

const GLNDetailView = ({ inputData, headerCheckboxState }) => {
  const {
    isDetail,
    listCardData,
    detailCardData,
    onListClickAt,
    onDetailCloseClicked,
    mapKey,
    mapRef,
    markers,
    centerCoordinates,
  } = GLNDetailVM(inputData, headerCheckboxState);

  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [mapStyle, setMapStyle] = useState("street");

  // Map tile layers
  const mapLayers = {
    street: {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "&copy; OpenStreetMap contributors",
    },
    satellite: {
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
    },
  };

  // Styles
  const styles = {
    gridContainer: {
      marginTop: "2em",
    },
    gridItem: {
      marginBottom: "0.5em",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        transform: "translateX(8px)",
      },
    },
    selectedGridItem: {
      backgroundColor: "rgba(33, 150, 243, 0.1)",
      transform: "translateX(8px)",
    },
    mapCard: {
      width: "100%",
      height: "40em",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      position: "relative",
    },
  };

  useEffect(() => {
    // Find the first marker with valid coordinates
    const firstValidMarkerIndex = markers.findIndex(
      (marker) =>
        marker.position &&
        Array.isArray(marker.position) &&
        marker.position.length === 2 &&
        marker.position[0] !== 0 &&
        marker.position[1] !== 0
    );

    // If no valid markers exist, reset to initial state
    if (firstValidMarkerIndex === -1) {
      setSelectedMarkerIndex(null);
      setInitialLoad(true);
    }
  }, [inputData, markers]);

  // Handle list click with toggle functionality
  const handleListClick = (index) => {
    // Validate the selected marker
    const selectedMarker = markers[index];

    // Always allow showing detail card
    if (selectedMarkerIndex === index) {
      setSelectedMarkerIndex(null);
      setInitialLoad(true);
    } else {
      // Set the selected index explicitly to the clicked index
      setSelectedMarkerIndex(index);

      // Determine if it's an initial load based on coordinate validity
      const isValidCoordinate =
        selectedMarker.position &&
        Array.isArray(selectedMarker.position) &&
        selectedMarker.position.length === 2 &&
        selectedMarker.position[0] !== 0 &&
        selectedMarker.position[1] !== 0;

      // Set initial load based on coordinate validity
      setInitialLoad(!isValidCoordinate);
    }

    // Call the view model's list click handler
    onListClickAt(index);
  };

  // Detail close handler
  const handleDetailClose = () => {
    setSelectedMarkerIndex(null);
    setInitialLoad(true);
    onDetailCloseClicked();
  };

  // Dynamic marker icon selection
  const getMarkerIcon = (index, marker) => {
    // Check if the current index matches the selected marker index
    const isSelectedMarker = index === selectedMarkerIndex;

    // Check if the marker has a valid non-zero position
    const isValidMarker =
      marker.position &&
      Array.isArray(marker.position) &&
      marker.position.length === 2 &&
      marker.position[0] !== 0 &&
      marker.position[1] !== 0;

    // If the marker is the selected one but doesn't have a valid position, return default icon
    if (isSelectedMarker && !isValidMarker) return icons.default;

    // Apply other icon logic for valid markers
    if (isValidMarker && isSelectedMarker) return icons.selected;
    if (isValidMarker && marker.status === "warning") return icons.warning;
    if (isValidMarker && marker.status === "success") return icons.success;

    return icons.default;
  };

  return (
    <Grid container spacing={2} sx={styles.gridContainer}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        {isDetail === 1 && detailCardData !== null && (
          <CCard
            cData={detailCardData}
            cCloseClicked={
              listCardData?.length > 0 ? handleDetailClose : undefined
            }
          />
        )}
        {isDetail === 0 &&
          listCardData?.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                ...styles.gridItem,
                ...(selectedMarkerIndex === index
                  ? styles.selectedGridItem
                  : {}),
              }}
              onClick={() => handleListClick(index)}
            >
              <CCard cData={item} />
            </Grid>
          ))}
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={9}>
        {detailCardData !== null && (
          <Card sx={styles.mapCard}>
            <MapControls
              onLayerChange={setMapStyle}
              mapStyle={mapStyle}
              onLocate={() =>
                mapRef.current?.flyToBounds(
                  L.latLngBounds(
                    markers
                      .filter(
                        (m) =>
                          m.position &&
                          m.position[0] !== 0 &&
                          m.position[1] !== 0
                      )
                      .map((m) => m.position)
                  ).pad(0.2)
                )
              }
            />

            <MapContainer
              key={mapKey}
              ref={mapRef}
              center={centerCoordinates}
              zoom={5}
              minZoom={2}
              zoomControl={false}
              style={{ height: "100%" }}
            >
              <ZoomControl position="bottomright" />
              <MapUpdater
                markers={markers}
                selectedIndex={selectedMarkerIndex}
                initialLoad={initialLoad}
              />

              <TileLayer {...mapLayers[mapStyle]} />

              <MarkerClusterGroup
                chunkedLoading
                maxClusterRadius={50}
                spiderfyOnMaxZoom={true}
                showCoverageOnHover={false}
              >
                {markers
                  .filter(
                    (marker) =>
                      marker.position &&
                      Array.isArray(marker.position) &&
                      marker.position.length === 2 &&
                      marker.position[0] !== 0 &&
                      marker.position[1] !== 0
                  )
                  .map((marker, index) => (
                    <React.Fragment key={index}>
                      <Marker
                        position={marker.position}
                        icon={getMarkerIcon(index, marker)}
                        eventHandlers={{
                          click: () => handleListClick(index),
                        }}
                      >
                        <Popup>
                          <div className="p-2">
                            <h3 className="font-bold mb-2">
                              {marker.title || marker.message || "Location"}
                            </h3>
                            {marker.address && (
                              <p className="mt-2 text-sm text-gray-600">
                                {marker.address}
                              </p>
                            )}
                          </div>
                        </Popup>
                      </Marker>
                    </React.Fragment>
                  ))}
              </MarkerClusterGroup>
            </MapContainer>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default GLNDetailView;
