import React from 'react';
import Card from '@mui/material/Card';

const CMainCard = ({ children }) => {
    return (
        <Card sx={{ padding: "2em" }}>
            {children}
        </Card>
    );
};

export default CMainCard;
