import React, { useState, useEffect } from "react";
import {
  uploadMaterialData,
  addEmailRequest,
  getDownloadExportFile,
  getMCTTable,
  uploadBulkEmailRequests,
  getSampleEmailBulkUploadFile,
} from "../model/MatRequestModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { formattedDate } from "../model/MaterialCreationMethods";
import {
  CREATE_TYPE,
  EXTEND_TYPE,
  COPY_TYPE,
  MODIFY_TYPE,
  DELETE_TYPE,
  MPN_TYPE,
} from "../../assets/Strings";
import UserUtils from "../../../basecomp/auth/UserUtils";

const MatRequestVM = () => {
  const { getUserName } = UserUtils;
  const userName = getUserName();
  console.log("userName", userName);
  const { handleToastOpen } = useToast();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedBulkFile, setSelectedBulkFile] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({
    type_of_request: "",
    status: "",
  });
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [mctData, setMctData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isEmailSubmitting, setIsEmailSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailBulkLoading, setEmailBulkLoading] = useState(false);
  const [emailFieldsForm, setEmailFieldsForm] = useState({
    material_number: {
      enter: "Material Number",
      isRequired: true,
    },
    description: {
      enter: "Description",
    },
    reason: {
      enter: "Reason",
      isRequired: true,
    },
    type_of_request: {
      enter: "Type of Request",
      isRequired: true,
      dropdownValues: [
        CREATE_TYPE,
        EXTEND_TYPE,
        COPY_TYPE,
        MODIFY_TYPE,
        DELETE_TYPE,
        MPN_TYPE,
      ],
    },
    plant_code: {
      enter: "Plant Id",
      isRequired: true,
    },
    mdm_name: {
      enter: "MDM Name",
      isRequired: true,
      value: userName,
    },
    status: {
      enter: "Status",
      dropdownValues: ["Approved", "Rejected"],
    },
    rejected_request_comments: {
      enter: "Rejected Request Comments",
    },
    date: {
      enter: "Date",
      isRequired: true,
      value: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getMCTTable();
        setIsTableVisible(true);
        setMctData(response.data);
        setFilteredList(response.data);
        // const filteredCActualData = response.data.filter(
        //   (item) =>
        //     item.status === STATUS_IN_PROGRESS ||
        //     item.status === STATUS_PENDING ||
        //     item.status === STATUS_ON_HOLD
        // );
        // console.log("filteredCActualData", filteredCActualData);
        // setFilteredList(filteredCActualData);
      } catch (error) {
        handleToastOpen("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChoose = (files) => {
    setSelectedFiles(files);
  };

  const handleBulkFile = (files) => {
    setSelectedBulkFile(files);
  };

  const handleUpload = async () => {
    try {
      const response = await uploadMaterialData(selectedFiles);
      if (
        response &&
        response.data &&
        response.data.insertedNames &&
        response.data.insertedNames.length > 0
      ) {
        handleToastOpen(
          "success",
          `${response.data.insertedNames.length} records inserted successfully`
        );
      } else {
        handleToastOpen(
          "error",
          "Data Upload Unsuccessful (All Duplicates Found)"
        );
      }

      setIsTableVisible(true);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const handleBulkEmailUpload = async () => {
    setIsUploading(true);
    try {
      const response = await uploadBulkEmailRequests(selectedBulkFile);
      if (response && response.data && response.data.insertedNames) {
        handleToastOpen(
          "success",
          `${response.data.insertedNames} records inserted successfully`
        );
      } else {
        handleToastOpen(
          "error",
          "Data Upload Unsuccessful (All Duplicates Found)"
        );
      }

      setIsTableVisible(true);
    } catch (error) {
      handleToastOpen("error", error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleInputChange = (itemId) => (value) => {
    setEmailFieldsForm((prevSelected) => {
      const itemObject = prevSelected[itemId];
      const updatedItemObject = {
        ...itemObject,
        value: value,
      };
      return {
        ...prevSelected,
        [itemId]: updatedItemObject,
      };
    });
  };

  // Function to reset the email fields form
  const resetEmailFieldsForm = () => {
    setEmailFieldsForm({
      material_number: {
        enter: "Material Number",
        isRequired: true,
      },
      description: {
        enter: "Description",
      },
      reason: {
        enter: "Reason",
        isRequired: true,
      },
      type_of_request: {
        enter: "Type of Request",
        isRequired: true,
        dropdownValues: [
          CREATE_TYPE,
          EXTEND_TYPE,
          COPY_TYPE,
          MODIFY_TYPE,
          DELETE_TYPE,
          MPN_TYPE,
        ],
      },
      plant_code: {
        enter: "Plant Id",
        isRequired: true,
      },
      mdm_name: {
        enter: "MDM Name",
        isRequired: true,
        value: userName,
      },
      status: {
        enter: "Status",
        dropdownValues: ["Approved", "Rejected"],
      },
      rejected_request_comments: {
        enter: "Rejected Request Comments",
      },
      date: {
        enter: "Date",
        isRequired: true,
        value: "",
      },
    });
  };

  const handleEmailSubmit = async () => {
    let isError = false;
    const newInput = {};
    for (const key in emailFieldsForm) {
      const item = emailFieldsForm[key];
      if (
        item.isRequired &&
        (item.value === undefined ||
          item.value === "" ||
          item.value.trim() === "")
      ) {
        handleToastOpen(
          "error",
          `Field "${item.enter}" is required but the value is blank.`
        );
        isError = true;
        break;
      }
      newInput[key] = item.value;
    }
    if (!isError) {
      setIsEmailSubmitting(true);
      try {
        await addEmailRequest(newInput);
        setIsTableVisible(true);
        handleToastOpen("success", "Data Successfully Inserted");
        resetEmailFieldsForm();
      } catch (error) {
        handleToastOpen("error", "No Data Found");
      } finally {
        setIsEmailSubmitting(false);
      }
    }
  };

  const downloadExportFile = async () => {
    try {
      const response = await getDownloadExportFile();
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `MaterialRequestData_${formattedDate}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  const setFilteredData = (updatedSelectedStatus, searchValue) => {
    let filteredData = [...mctData];
    Object.keys(updatedSelectedStatus).forEach((key) => {
      const value = updatedSelectedStatus[key];
      if (value === null || value === "") {
        filteredData = filteredData.filter((item) => true);
      } else {
        filteredData = filteredData.filter((item) => item[key] === value);
      }
    });
    if (searchValue !== "") {
      const searchArr = [
        "material_number",
        "description",
        "plant_code",
        "uuid",
      ];
      const filteredResult = filteredData.filter((rowVal) => {
        return searchArr.some((key) => {
          return rowVal[key] === searchValue;
        });
      });
      if (filteredResult.length > 0) {
        setFilteredList(filteredResult);
      } else {
        setFilteredList(filteredData);
      }
    } else {
      setFilteredList(filteredData);
    }
  };

  const searchItems = (searchValue) => {
    setSearchValue(searchValue);
  };

  const onSearchClick = () => {
    setFilteredData(selectedStatus, searchValue);
  };

  const handleStatusChange = (rowName, selectedValue) => {
    const updatedSelectedStatus = { ...selectedStatus };
    updatedSelectedStatus[rowName] = selectedValue;
    setSelectedStatus(updatedSelectedStatus);
    setFilteredData(updatedSelectedStatus, searchValue);
  };

  const handleEmailBulkFileDownload = async () => {
    setEmailBulkLoading(true);
    try {
      const response = await getSampleEmailBulkUploadFile();
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `SampleEmailBulkUpload.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    } finally {
      setEmailBulkLoading(false);
    }
  };

  return {
    handleFileChoose,
    handleBulkFile,
    handleUpload,
    handleBulkEmailUpload,
    emailFieldsForm,
    handleInputChange,
    handleEmailSubmit,
    isEmailSubmitting,
    downloadExportFile,
    searchItems,
    onSearchClick,
    handleStatusChange,
    isTableVisible,
    filteredList,
    isUploading,
    isLoading,
    handleEmailBulkFileDownload,
    emailBulkLoading,
  };
};

export default MatRequestVM;
