import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CText from "./CText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CCard = ({
  cData,
  cHeader,
  cMedia,
  cMediaHeight,
  cTooltipText,
  cCloseClicked,
}) => {
  return (
    <Card
      aria-label={cTooltipText}
      variant="outlined"
      sx={{ position: "relative", padding: "1em" }}
    >
      {cCloseClicked !== undefined && (
        <IconButton
          onClick={cCloseClicked}
          sx={{
            position: "absolute",
            top: "0.2em",
            right: "0.2em",
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {cHeader !== undefined && (
        <CText cVariant="header_card" cText={cHeader} />
      )}
      {cData !== undefined &&
        cData.map((item, index) => (
          <CardContent key={index} sx={{ padding: "0.1em" }}>
            {item.head && <CText cVariant="head_card" cText={item.head} />}
            {item.title && <CText cVariant="title_card" cText={item.title} />}
            {item.detail && <CText cVariant="subtitle1" cText={item.detail} />}
            {item.sub && <CText cVariant="body2" cText={item.sub} />}
            {item.custom}
          </CardContent>
        ))}
      {cMedia !== undefined && (
        <CardMedia component="img" height={cMediaHeight} image={cMedia} />
      )}
    </Card>
  );
};

export default CCard;
