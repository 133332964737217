import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, FormGroup, IconButton, TextField } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const CCustomCard = ({
  title,
  data,
  handleReset,
  handleChange,
  itemKey,
  descriptionKey,
  hideUnchecked = false,
  checkedItemIndex = null,
  searchTerm,
  setSearchTerm,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Update filteredData whenever data or searchTerm changes
    const applyFilters = () => {
      const initialFilteredData = data.filter(
        (item) =>
          item[descriptionKey]?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          !item.hidden
      );

      const finalFilteredData = initialFilteredData.filter((item, index) => {
        const isChecked = item.checked || false;
        if (hideUnchecked) {
          return checkedItemIndex === index || isChecked;
        }
        return true;
      });

      setFilteredData(finalFilteredData);
    };

    applyFilters();
  }, [data, searchTerm, hideUnchecked, checkedItemIndex, descriptionKey]);

  const handleCheckboxChange = (filteredIndex) => {
    const item = filteredData[filteredIndex];
    if (!item) {
      console.error("Item not found in filteredData");
      return;
    }

    const originalIndex = data.findIndex((dataItem) => dataItem[itemKey] === item[itemKey]);
    if (originalIndex === -1) {
      console.error("Original index not found in data");
      return;
    }

    handleChange(originalIndex); // Pass the original index
  };

  const onReset = () => {
    setSearchTerm(""); // Clear search field
    handleReset(); // Call parent reset handler
  };

  return (
    <Grid item xs={12} sm={12} md={2.4}>
      <Card
        style={{
          height: "21em",
          padding: "1em",
          position: "relative",
          marginBottom: "2em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "2.5em",
            backgroundColor: "#3FB043",
            position: "relative",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 1em",
          }}
        >
          <Typography variant="body1" style={{ color: "white", fontWeight: "bold" }}>
            {title}
          </Typography>
          <IconButton onClick={onReset} color="inherit" title="Clear">
            <DeleteForeverIcon style={{ color: "white" }} />
          </IconButton>
        </div>

        <TextField
          style={{ marginTop: "0.5em" }}
          variant="standard"
          size="small"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            style: {
              height: "2em",
              padding: "0.5em",
            },
          }}
        />

        <div
          style={{
            marginTop: "1em",
            height: "calc(100% - 3.5em)",
            overflow: "auto",
          }}
        >
          <FormGroup>
            {filteredData.map((item, index) => {
              const isChecked = item.checked || false;

              return (
                <div
                  key={item[itemKey]} // Ensure unique key for each item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5em",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    style={{ marginLeft: "1em" }}
                    onChange={() => handleCheckboxChange(index)} // Pass filtered index
                  />
                  <Typography
                    variant="body2"
                    style={{ fontSize: "12px", marginLeft: "0.5em" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item[descriptionKey]}
                  </Typography>
                </div>
              );
            })}
          </FormGroup>
        </div>
      </Card>
    </Grid>
  );
};

export default CCustomCard;
