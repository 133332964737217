const QUANTITY_KEY = "quantity";
const PURCHASE_KEY = 1;
const LEVEL1_KEY = 2;
const LEVEL2_KEY = 3;
const LEVEL3_KEY = 4;
const LEVEL4_KEY = 5;

export const SpDataKeys = {
  QUANTITY_KEY,
  PURCHASE_KEY,
  LEVEL1_KEY,
  LEVEL2_KEY,
  LEVEL3_KEY,
  LEVEL4_KEY,
};

const JOB_ID = "id";
const REFERENCE_NO = "reference_no";
const JOB_TYPE = "job_type";
const JOB_DIRECTIVE = "job_directive";
const EQUIPMENT_TYPE = "equipment_type";
const EQUIPMENT_ID = "equipment_id";
const USER_NAME = "created_by_name";
const CREATED_AT = "created_at";

export const JobIdKeys = {
  JOB_ID,
  REFERENCE_NO,
  JOB_TYPE,
  JOB_DIRECTIVE,
  EQUIPMENT_TYPE,
  EQUIPMENT_ID,
  USER_NAME,
  CREATED_AT,
};
