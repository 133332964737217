import React from 'react';
import Grid from '@mui/material/Grid';

const CMainGrid = (props) => {
  return (
    <Grid item {...props}>
      {props.children}
    </Grid>
  );
};

export default CMainGrid;
