import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";

// Todo: Add disabled as a variant, if needed
const CText = ({ cVariant, cText, cIsJson }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let actualText = cText;
  if (cIsJson === true && typeof actualText !== "string") {
    actualText = JSON.stringify(actualText);
  }

  let tColor = "inherit",
    tFontWeight = "inherit",
    marginBottom = "0";

  // Define font sizes for different variants and screen sizes
  const fontSize = {
    h6: isSmallScreen ? "0.3rem" : "1.25rem",
    subtitle1: isSmallScreen ? "0.875rem" : "1.125rem",
    body1: isSmallScreen ? "0.95rem" : "1rem",
  };

  if (cVariant === "header_card") {
    tColor = "white";
    cVariant = "h6";
    return (
      <Box
        bgcolor="secondary.main"
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%", // Use maxWidth instead of width
          margin: "1em 0",
          overflow: "hidden", // Prevent horizontal scrolling
        }}>
        <Typography
          variant={cVariant}
          color={tColor}
          fontWeight="bold"
          sx={{ fontSize: fontSize[cVariant] }}>
          {actualText}
        </Typography>
      </Box>
    );
  } else if (cVariant === "no_data") {
    tColor = "#888888";
    cVariant = "h6";
    if (cText === undefined) {
      actualText = "No Data";
    }
    return (
      <Box
        bgcolor="#F2F2F2"
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: "100%", // Use maxWidth instead of width
          margin: "1em 0",
          overflow: "hidden", // Prevent horizontal scrolling
        }}>
        <Typography
          variant={cVariant}
          color={tColor}
          fontWeight="bold"
          sx={{ fontSize: fontSize[cVariant] }}>
          {actualText}
        </Typography>
      </Box>
    );
  } else if (cVariant === "head_card") {
    tColor = "secondary";
    tFontWeight = "bold";
    cVariant = "subtitle1";
  } else if (cVariant === "title_card") {
    tFontWeight = "bold";
    cVariant = "subtitle1";
  } else if (cVariant === "heading_section") {
    cVariant = "h6";
  } else if (cVariant === "normal_text") {
    cVariant = "body1";
    marginBottom = "0.2em";
  } else if (cVariant === "nav_bar") {
    tColor = "white";
    cVariant = "h6";
    return (
      <Box
        bgcolor="#D3D3D3"
        sx={{
          display: "flex",
          flexDirection: "row", // Change to row to align buttons horizontally
          alignItems: "flex-start", // Align items to the start (left)
          maxWidth: "100%", // Use maxWidth instead of width
          minHeight: "48px", // Set a minimum height
          margin: "1em 0",
          overflow: "hidden", // Prevent horizontal scrolling
          paddingTop: "8px", // Adjust this value to move the text down
          paddingLeft: "1em",
          gap: "10px",
        }}>
        {actualText}
      </Box>
    );
  }

  return (
    <Typography
      variant={cVariant}
      color={tColor}
      fontWeight={tFontWeight}
      sx={{
        marginBottom,
        fontSize: fontSize[cVariant] || fontSize.body1, // Fallback to body1 if variant is not defined
      }}>
      {actualText}
    </Typography>
  );
};

export default CText;
