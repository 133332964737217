import React from "react";
import { useNavigate } from "react-router-dom";
// import { CTable, CButton, CInput, CMainGrid, CAllIcons } from "fe_comp_base";
import CInput from "../../../basecomp/components/CInput";
import CButton from "../../../basecomp/components/CButton";
import CTable from "../../../basecomp/components/CTable";
import CAllIcons from "../../../basecomp/components/wrapper/CAllIcons";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";

import ListUserVM from "../viewmodel/ListUserVM";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { UserKeys } from "../../assets/VarConstants";


const ListUserView = () => {

  const navigate = useNavigate();
  const {
    headerData,
    rowData,
    filteredList,
    searchItems,
    onSearchClick,
  } = ListUserVM();

  return (
    <CMainGrid sx={{ marginTop: '6em', marginBottom: '3em', marginLeft: '1.5em', marginRight: '1.5em' }}>

      <CMainGrid
        container
        justifyContent="flex-end"
        marginBottom={2}
        marginRight={1}
        spacing={2}
      >

        <CMainGrid item>
          <CInput
            cHintText="Search field"
            cType="search"
            cOnChange={searchItems}
            cHandleSubmit={onSearchClick}
            cInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onSearchClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </CMainGrid>
        <CMainGrid item
          marginLeft="3.5em">
          <CButton
            cSrc={<CAllIcons name="personAdd" />}
            cTooltipText="Add User"
            cDisabled={false}
            cOnClick={() => {
              sessionStorage.removeItem(UserKeys.NAV_USER);
              navigate("/users/update-user");
            }}
          />
        </CMainGrid>
        {/* <CMainGrid item marginLeft="2em">
          <CButton
            cSrc={<CAllIcons name="contact" />}
            cTooltipText={EXPORT_USER_LIST}
            cDisabled={false}
            cOnClick={downloadUserLists}
          />
        </CMainGrid> */}
      </CMainGrid>

      <CTable
        cHeaderData={headerData}
        cRowData={rowData}
        cActualData={filteredList}
        cIsPagination={true}
        cSmallSize={false}
      />

    </CMainGrid>
  );
};

export default ListUserView;
