import { useState, useEffect } from "react";
import { fetchTypeList, fetchPermissionList, updatePermissionList } from "../model/PermissionModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

const PermissionVM = () => {

  const [selectedPermType, setSelectedPermType] = useState("");
  const [selectedChooseType, setSelectedChooseType] = useState("");
  const [dataListByType, setdataListByType] = useState([]);
  const [dataDisplay, setDataDisplay] = useState({}
    //   {
    //   "tool_warehouse": "Warehouse Management",
    //   "menu_warehouse_label": "Label Printing",
    //   "menu_warehouse_image": "Image Upload",
    //   "menu_dcat_hist": "Historical Jobs",
    //   "tool_dcat": "D-Catalogue"
    // }
  );

  const [dataGroup, setDataGroup] = useState({}
    //   {
    //   "tool_warehouse": ["menu_warehouse_label", "menu_warehouse_image"],
    //   "tool_dcat": ["menu_dcat_hist"]
    // }
  );
  const checksArr = [
    // "tool_warehouse", "menu_warehouse_image"
  ];
  const [checksList, setChecksList] = useState(new Set(checksArr));
  const { handleToastOpen } = useToast();

  const fetchPermission = async () => {
    try {
      const response = await fetchPermissionList();
      setDataDisplay(response.data.dataDisplay);
      setDataGroup(response.data.dataGroup);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const onCheckChange = (key, isChecked) => {
    setChecksList(prevChecksList => {
      const newChecksList = new Set(prevChecksList);
      if (isChecked) {
        newChecksList.add(key);
      } else {
        newChecksList.delete(key);
      }
      return newChecksList;
    });
  }

  const handlePermTypeChange = async (value) => {
    setSelectedPermType(value);
    try {
      await fetchPermission();
      const response = await fetchTypeList(value);
      setdataListByType(response.data);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  }

  const handleValueChange = (value) => {
    setSelectedChooseType(value.id);
    setChecksList(new Set(value.permission_id.split(",")));
  }

  const handleSubmitClick = async () => {
    try {
      const response = await updatePermissionList(selectedPermType, selectedChooseType, Array.from(checksList).join(', '));
      handleToastOpen("success", response.data.message);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  }


  return {
    dataListByType,
    dataDisplay,
    dataGroup,
    checksList,
    selectedPermType,
    onCheckChange,
    handlePermTypeChange,
    handleValueChange,
    handleSubmitClick
  };
}

export default PermissionVM;
