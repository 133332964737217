import React, { useState, useEffect } from "react";
import { Grid, Card } from "@mui/material";
import axios from "axios";
import ADHeader from "./ADHeader";
import IconTabs from "./IconTabs";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../basecomp/components/provider/ToastProvider";

const AdminDashboard = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [approvers, setApprovers] = useState([]);
  const [gatekeepers, setGatekeepers] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();
  const { handleToastOpen } = useToast();

  useEffect(() => {
    // Check if the user is an admin
    const userRole = localStorage.getItem("userRole");
    if (userRole !== "Admin") {
      navigate("/visitor-mgmt/login"); // Redirect if not an admin
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [approverRes, gatekeeperRes, visitorRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
            params: { role: "Approver" },
          }),
          axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
            params: { role: "GateKeeper" },
          }),
          axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
            params: { role: "Visitor" },
          }),
        ]);

        setApprovers(approverRes.data);
        setGatekeepers(gatekeeperRes.data);
        setVisitors(visitorRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFileUpload = async () => {
    setLoading(true);
    try {
      // Perform actual file upload
      await addAnyFileToTable(`bulkUpload/${selectedRole}`, selectedFiles);

      // Set success state and show success toast
      handleToastOpen("success", "Bulk upload successful!");

      // Reload the page after successful upload
      window.location.reload();
    } catch (error) {
      // Show error toast if the upload fails
      handleToastOpen("error", "Failed to upload file.");
      console.error("Error during bulk upload:", error);
    } finally {
      // Set loading state to false after the operation is completed
      setLoading(false);
    }
  };

  const addAnyFileToTable = async (selectedApi, selectedFiles) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("file", selectedFiles[i]);
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/${selectedApi}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        if (
          response.data.status === undefined ||
          response.data.status === 200
        ) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleViewDetails = (user) => {
    navigate(`/approve-visitor/${user.id}`, { state: { user } });
  };

  const handleDelete = async (id, role) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/deleteUser`,
        {
          data: { id, role },
        }
      );

      if (role === "Visitor") {
        setVisitors(visitors.filter((visitor) => visitor.id !== id));
        handleToastOpen("success", "Visitor deleted successfully!");
      } else if (role === "Approver") {
        setApprovers(approvers.filter((approver) => approver.id !== id));
        handleToastOpen("success", "Approver deleted successfully!");
      } else if (role === "Gatekeeper") {
        setGatekeepers(
          gatekeepers.filter((gatekeeper) => gatekeeper.id !== id)
        );
        handleToastOpen("success", "Gatekeeper deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      handleToastOpen("error", "Failed to delete user");
    }
  };

  const handleAddUser = (newUser, role) => {
    if (role === "Approver") {
      setApprovers((prevApprovers) => [...prevApprovers, newUser]);
    } else if (role === "Gatekeeper") {
      setGatekeepers((prevGatekeepers) => [...prevGatekeepers, newUser]);
    } else if (role === "Visitor") {
      setVisitors((prevVisitors) => [...prevVisitors, newUser]);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ padding: { xs: "1em", sm: "2em", md: "5em 3em 0.5em 3em" } }}
    >
      <Grid item xs={12}>
        <Card sx={{ padding: { xs: "1em", sm: "2em", md: "2em" } }}>
          <ADHeader
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            setSelectedFiles={setSelectedFiles} // To handle file selection
            handleBulkUpload={handleFileUpload}
            isLoading={loading}
          />
          <IconTabs
            approvers={approvers}
            gatekeepers={gatekeepers}
            visitors={visitors}
            handleViewDetails={handleViewDetails}
            handleDelete={handleDelete}
            onAddUser={handleAddUser}
            setApprovers={setApprovers}
            setGatekeepers={setGatekeepers}
            setVisitors={setVisitors}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
