import React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import CText from "../../../basecomp/components/CText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CalculationDetailsModal = ({ isOpen, handleClose, details }) => {
  const StyledPaper = styled(Paper)(({ theme }) => ({
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));
  const detailsTextStyle = {
    margin: "20px 0",
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.5",
    borderTop: "1px solid #ccc", // Grey top border
    borderBottom: "1px solid #ccc", // Grey bottom border
    paddingTop: "10px", // Add some padding to the top
    paddingBottom: "10px", // Add some padding to the bottom
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledPaper sx={{ p: 4 }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <br />
        <CText cText="Calculation Details" cVariant="header_card" />
        <p sx={detailsTextStyle}>{details}</p>
      </StyledPaper>
    </Modal>
  );
};

export default CalculationDetailsModal;
