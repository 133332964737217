import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
import axios from "axios";

// Base Url Setup
const { axiosGetCall, axiosGetCallHeader } = AxiosWrapper;
const REACT_BE_URL = process.env.REACT_APP_BE_URL;

// Continent API Call
const fetchContinentData = async () => {
  try {
    const response = await axiosGetCall(`/gln/getUniqueContinentCodes`);
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

// Country API Call
const fetchCountryData = async () => {
  try {
    const response = await axiosGetCall(`/gln/getUniqueCountryCodes`);
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

// Region API Call
const fetchRegionData = async (searchInput, selectedCountryCodes) => {
  try {
    const response = await axiosGetCall(
      `/gln/searchRegionCodes?` +
        `countryCodes=${selectedCountryCodes}&searchInput=${searchInput}`
    );
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

// City API Call
const fetchCityData = async (
  searchInput,
  selectedRegionCodes,
  selectedCountryCodes
) => {
  try {
    const response = await axiosGetCall(
      `/gln/searchCityCodes?` +
        `regionCodes=${selectedRegionCodes}&countryCodes=${selectedCountryCodes}&searchInput=${searchInput}`
    );
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

// City Facility Call
const fetchFacilityData = async (
  searchInput,
  selectedCityCodes,
  selectedCountryCodes,
  codeType,
  selectedRegionCodes
) => {
  try {
    const response = await axiosGetCall(
      `/gln/searchFacilityCodes?` +
        `cityCodes=${selectedCityCodes}&regionCodes=${selectedRegionCodes}&countryCodes=${selectedCountryCodes}&searchInput=${searchInput}&codeType=${codeType}`
    );
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

const fetchGlnData = async (
  cityCodes,
  facilityCodes,
  glnId,
  facilityName,
  allFilter
) => {
  try {
    const response = await axiosGetCall(
      `/gln/getGlnData?` +
        `cityCodes=${cityCodes}&facilityCodes=${facilityCodes}&glnId=${glnId}&facilityName=${facilityName}&allFilter=${allFilter}`
    );
    if (response.status === 200) {
      if (response.data.status === undefined || response.data.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

export const getDownloadExportFile = async (selectedCodeData) => {
  const queryParams = new URLSearchParams(selectedCodeData).toString();
  console.log("queryParams", queryParams);
  let url = `${REACT_BE_URL}/gln/downloadExportFile?${queryParams}`;

  let response;
  try {
    response = await axiosGetCallWithTokenHeader(url);
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened. If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);
    throw sendError;
  }

  if (response === undefined) {
    throw new Error("No response");
  }

  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const axiosGetCallWithTokenHeader = async (url) => {
  // const token = getTokenFromEmail();
  try {
    // if (token === null) {
    // return;
    // }
    const response = await axios.get(url, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/excel",
        // [apiKey]: token,
      },
    });
    return response;
  } catch (error) {
    // handleLoginError(error);
  }
};

export {
  fetchContinentData,
  fetchCountryData,
  fetchRegionData,
  fetchCityData,
  fetchFacilityData,
  fetchGlnData,
};
