import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useToast } from "../../basecomp/components/provider/ToastProvider";
import Grid from "@mui/material/Grid";
import axios from "axios";

const LoginView = ({ cLogo, cBgImg }) => {
  const { handleToastOpen } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [useEmail, setUseEmail] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  // New state for country codes
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    fetchCountryCodes();
  }, []);

  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/getCountryCodes`
      );
      setCountryCodes(response.data);
    } catch (error) {
      console.error("Error fetching country codes:", error);
      handleToastOpen("error", "Failed to load country codes");
    }
  };

  const handleSendOtp = async () => {
    if (!mobile) {
      handleToastOpen("error", "Please enter your mobile number");
      return;
    }

    const fullMobileNumber = `${selectedCountryCode}${mobile}`;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/send-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: fullMobileNumber }),
        }
      );

      if (response.ok) {
        setOtpSent(true);
        handleToastOpen("success", "OTP sent successfully!");
      } else {
        handleToastOpen("error", "Failed to send OTP!");
      }
    } catch (error) {
      handleToastOpen("error", "An error occurred. Please try again later");
    }
  };

  const handleLogin = async () => {
    let loginData = {};

    if (useEmail) {
      if (!email || !password) {
        handleToastOpen("error", "Please enter both email and password.");
        return;
      }
      loginData = { username: email, password };
    } else {
      if (!mobile || !otp) {
        handleToastOpen("error", "Please enter both mobile number and OTP.");
        return;
      }
      const fullMobileNumber = `${selectedCountryCode}${mobile}`;
      loginData = { username: fullMobileNumber, password: otp };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        localStorage.setItem("userRole", result.role);
        localStorage.setItem("token", result.token);
        localStorage.setItem("contactInfo", result.username);

        const redirectUrl = localStorage.getItem("redirectAfterLogin");
        localStorage.removeItem("redirectAfterLogin");

        if (result.role === "Admin") {
          navigate("/visitor-mgmt/admin-dashboard");
        } else if (result.role === "Approver") {
          navigate(redirectUrl || "/visitor-mgmt/approver-dashboard");
        } else {
          navigate(redirectUrl);
        }

        handleToastOpen("success", "Login successful!");
      } else {
        handleToastOpen("error", result.error || "Login Failed");
      }
    } catch (error) {
      handleToastOpen("error", "An error occurred. Please try again later.");
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${cBgImg})`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "translateY(-50px)",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "-50px" }}
      >
        <Card
          style={{
            padding: "2em",
            width: "90%",
            maxWidth: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "auto",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={() => setUseEmail(!useEmail)}
            sx={{ marginBottom: "1em" }}
          >
            {useEmail
              ? "Click to use Mobile and OTP instead"
              : "click to use Email and Password instead"}
          </Button>

          {useEmail ? (
            <>
              <TextField
                id="login_email"
                type="email"
                label="User ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                sx={{ marginBottom: "1.5em" }}
              />
              <TextField
                id="login_password"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                sx={{ marginBottom: "1.5em" }}
              />
            </>
          ) : (
            <>
              <TextField
                id="login_mobile"
                type="text"
                label="Mobile Number"
                value={mobile}
                onChange={(e) =>
                  setMobile(e.target.value.replace(/[^0-9]/g, ""))
                }
                required
                fullWidth
                disabled={otpSent}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        value={selectedCountryCode}
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "4px 8px",
                            paddingRight: "24px !important",
                          },
                        }}
                        renderValue={(selected) => selected}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        {countryCodes.length > 0 ? (
                          countryCodes.map((country) => (
                            <MenuItem
                              key={country.country_code}
                              value={country.country_code}
                            >
                              {country.country_code} - {country.country_name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>Loading...</MenuItem>
                        )}
                      </Select>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginBottom: "1.5em" }}
              />
              {otpSent && (
                <TextField
                  id="login_otp"
                  type="text"
                  label="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  fullWidth
                  sx={{ marginBottom: "1.5em" }}
                />
              )}
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              borderRadius: "1.0em",
              marginTop: "2.2em",
            }}
            onClick={
              useEmail ? handleLogin : otpSent ? handleLogin : handleSendOtp
            }
          >
            {useEmail ? "Login" : otpSent ? "Verify OTP" : "Send OTP"}
          </Button>
        </Card>
      </Grid>
    </div>
  );
};

export default LoginView;
