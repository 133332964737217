import React, { useState, useEffect } from "react";
import CInput from "./CInput";

const CDateTimePicker = ({
  cId,
  cHelperText,
  cTooltipText,
  cDisabled,
  cOnChange,
  cKeyReset,
  cIsSession,
  cHideTime,
  cSmall,
}) => {
  // Function to get initial value from session storage
  const getInitialDateTime = () => {
    if (cIsSession) {
      return sessionStorage.getItem(cId) || ""; // Default to empty string if no value is found
    }
    return "";
  };

  const [selectedDateTime, setSelectedDateTime] = useState(getInitialDateTime());

  useEffect(() => {
    // Update state if session storage has a value and cIsSession is true
    if (cIsSession) {
      setSelectedDateTime(getInitialDateTime());
    }
  }, [cIsSession, cId]);

  const handleDateTimeChange = (e) => {
    const updatedValue = e || "";
    setSelectedDateTime(updatedValue);
    cOnChange(updatedValue);
    sessionStorage.setItem(cId, updatedValue);
  };

  useEffect(() => {
    // Reset to initial value if cKeyReset changes
    if (cKeyReset) {
      setSelectedDateTime(getInitialDateTime());
    }
  }, [cKeyReset]);

  return (
    <CInput
      id={cId}
      key={cKeyReset}
      cTooltipText={cTooltipText}
      cDisabled={cDisabled}
      cHelperText={cHelperText}
      cType={cHideTime ? "date" : "datetime-local"}
      cOnChange={handleDateTimeChange}
      cValue={selectedDateTime}
      cIsSmall={cSmall}
      cFullWidth={true}
    />
  );
};

export default CDateTimePicker;
