// import { AxiosWrapper, Logger } from "fe_comp_base";
import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";


const {
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosPutCallWithToken
} = AxiosWrapper;

export const getUserTable = async () => {
  const response = await axiosGetCallWithToken(`/users/getAllUsers`);
  return response;
};

export const getAllUserType = async () => {
  const response = await axiosGetCallWithToken(`/users/getAllUserTypes`);
  return response;
};

export const getAllTerminals = async () => {
  const response = await axiosGetCallWithToken(`/users/getAllTerminals`);
  return response;
};

export const addUser = async (userData) => {
  let response;
  if (userData.isUpdate === true) {
    response = await axiosPutCallWithToken(`/users/updateUser`, userData);
  } else {
    response = await axiosPostCallWithToken(`/users/addUser`, userData);
  }
  return response;
};
