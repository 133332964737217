import React, { useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CInput from "./CInput";

const CDropDown2 = ({
  cId,
  cHelperText,
  cData,
  cErrorMsg,
  cOnChange,
  cIsSession,
  cIsSmall, // Add this prop
  getOptionLabel,
  isOptionEqualToValue,
}) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const resetRef = useRef(false);

  useEffect(() => {
    // Reset the selected value when resetRef is true
    if (resetRef.current) {
      setSelectedValue("");
      resetRef.current = false;
    }
  }, [resetRef]);

  // Check if session storage should be used to populate the value
  if (selectedValue === "" && cIsSession) {
    const sessionItem = sessionStorage.getItem(cId);
    if (sessionItem) {
      setSelectedValue(sessionItem);
    }
  }

  const handleChange = (newValue) => {
    const updatedValue = newValue === null ? "" : newValue;
    setSelectedValue(updatedValue);
    cOnChange(updatedValue);
    // Update session storage if cIsSession is true
    if (cIsSession && updatedValue !== null) {
      if (typeof updatedValue === "string") {
        sessionStorage.setItem(cId, updatedValue);
      } else {
        var updatedValueString = JSON.stringify(updatedValue);
        sessionStorage.setItem(cId, updatedValueString);
      }
    }
  };

  // Function to reset the selected value
  const resetDropdown = () => {
    resetRef.current = true;
  };

  return (
    <Autocomplete
      id={cId}
      options={cData || []}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) =>
        getOptionLabel ? getOptionLabel(option) : option
      } // Use the custom getOptionLabel if provided, otherwise fallback to the default one
      isOptionEqualToValue={isOptionEqualToValue}
      value={selectedValue}
      renderInput={(params) => (
        <CInput
          cHintText={cHelperText}
          cDropDown={params}
          cErrorMsg={cErrorMsg}
          cIsSmall={cIsSmall} // Pass down the cIsSmall prop
        />
      )}
      fullWidth
    />
  );
};

export default CDropDown2;
