//yet to merge it with components

import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import CInput from "./CInput";
import CCheckButton from "./CCheckButton";

const CDropDown = ({
  cId,
  cHelperText,
  cData,
  cErrorMsg,
  cOnChange,
  cKeyReset,
  cFullWidth,
  cIsOptionName,
  cIsMultiple,
  cDefaultValue,
  cIsRequired,
}) => {
  const [selectedValue, setSelectedValue] = useState(cDefaultValue || []);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSelectedValue(cDefaultValue || []);
    setInputValue("");
  }, [cKeyReset]);

  // useEffect(() => {
  //   setSelectedValue(cDefaultValue);
  // }, [cDefaultValue]);

  const renderInputLabel = () => {
    if (cIsRequired) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span>{cHelperText}</span>
          <span style={{ marginLeft: "4px" }}>*</span>
        </div>
      );
    } else {
      return (
        <div style={{ fontWeight: "bold" }}>
          <span>{cHelperText}</span>
        </div>
      );
    }
  };

  return (
    <Autocomplete
      id={cId}
      multiple={cIsMultiple}
      disableCloseOnSelect={cIsMultiple}
      options={cData || []}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        if (cOnChange) {
          cOnChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      getOptionLabel={(option) =>
        (option && (cIsOptionName ? option.name : option)) || ""
      }
      value={selectedValue || []}
      renderOption={
        cIsMultiple
          ? (props, option, { selected }) => {
              const isDefaultChecked = cDefaultValue.some((item) =>
                cIsOptionName ? item.name === option.name : item === option
              );

              // const isChecked =
              //   isDefaultChecked || selectedValue.indexOf(option) > -1;
              const isChecked = selected ? selected : isDefaultChecked;

              return (
                <li {...props}>
                  <CCheckButton
                    cLabel={cIsOptionName === true ? option.name : option}
                    cChecked={isChecked}
                  />
                </li>
              );
            }
          : undefined
      }
      renderInput={(params) => (
        <CInput
          key={cKeyReset}
          cHintText={renderInputLabel()}
          cDropDown={params}
          cErrorMsg={cErrorMsg}
          cValue={selectedValue}
        />
      )}
      fullWidth={cFullWidth}
      PaperComponent={({ children, ...rest }) => (
        <Paper {...rest}>{children}</Paper>
      )}
    />
  );
};

export default CDropDown;
