export const cTypeText = "text";

export const cVariantHeading = "heading_section";

export const cVariantOutlined = "outlined";

export const cTypeFile = "file";

export const cTypeSearch = "search";

export const cSuccessAlert = "success, Data added successfully";

export const cDuplicatedData = "Duplicate Data";

export const cAPIRequestFailedError = "API Request Failed";

export const cErrorFetchingData = "Error fetching  Data";


export const cError = "error  An error occurred";


export const cVariantContained = "contained";

export const cColorPrimary = "primary";


export const cSubmitAction = "Submit the action";

export const somethingWrong = "Something went wrong!";

export const cLogRecordsTooltipText = "Export File records";

export const STATUS_IN_PROGRESS = "In Progress";
export const STATUS_PENDING = "Pending";
export const STATUS_APPROVED = "Approved";
export const STATUS_REJECTED = "Rejected";
export const STATUS_ON_HOLD = "On Hold";

export const CREATE_TYPE = "CREATE";
export const EXTEND_TYPE = "EXTEND";
export const COPY_TYPE = "COPY";
export const MODIFY_TYPE = "MODIFY";
export const DELETE_TYPE = "DELETE";
export const MPN_TYPE = "MPN";