import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HistoricalJobVM from "../viewmodel/HistoricalJobVM";
import CText from "../../../../basecomp/components/CText";
import CButton from "../../../../basecomp/components/CButton";
import CButtonGroup from "../../../../basecomp/components/CButtonGroup";
import CDropdown from "../../../../basecomp/components/CDropDown";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";
import CInput from "../../../../basecomp/components/CInput";
import CTable from "../../../../basecomp/components/CTable";
import CMainGrid from "../../../../basecomp/components/wrapper/CMainGrid";
import CBackDrop from "../../../../basecomp/components/CBackDrop";

const HistoricalJobs = () => {
  const {
    dropdownData,
    tableData,
    showTable,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    handleSearchClick,
    handleResetClick,
    handleFromChange,
    handleToChange,
    handleJobIdChange,
    handleJobTypeChange,
    handleCreatedByChange,
    handleEquipmentTypeChange,
    handleEquipmentIdChange,
    handleJobDirectiveChange,
    resetAll,
    infoHeadData,
    infoRowData,
    isLoading,
  } = HistoricalJobVM();

  return (
    <>
    <CBackDrop cOpen={isLoading} />
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Card sx={{ padding: "2em" }}>
            <Grid container paddingTop={3}  paddingLeft={5}>
              <Grid item xs={12} sm={12} md={5.5}>
                <CText cVariant="normal_text" cText="Period Selection" />
                <Grid container spacing={3} paddingTop={0.9}>
                  <Grid item xs={12} sm={12} md={5}>
                    <CDateTimePicker
                      cKeyReset={resetAll}
                      cId="hist_from_date_id"
                      cOnChange={handleFromChange}
                      cHelperText="From"
                      cHideTime={true}
                      cSmall={true}
                      cFullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5}>
                    <CDateTimePicker
                      cKeyReset={resetAll}
                      cId="hist_to_date_id"
                      cOnChange={handleToChange}
                      cHelperText="To"
                      cHideTime={true}
                      cSmall={true}
                      cFullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} paddingTop={4} paddingLeft={3} paddingBottom={3}>
                  <CButtonGroup>
                    <CButton
                      cId="job_search"
                      cSrc={<SearchIcon />}
                      cIsMain={true}
                      cTooltipText="Search"
                      cOnClick={handleSearchClick}
                    />

                    <CButton
                      cId="job_reset"
                      cSrc={<AutorenewIcon />}
                      cIsMain={true}
                      cTooltipText="Reset"
                      cOnClick={handleResetClick}
                    />
                  </CButtonGroup>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="job_card_no"
                      cHelperText="Job ID"
                      cData={dropdownData ? dropdownData.id : {}}
                      cOnChange={handleJobIdChange}
                      cIsSmall={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="job_type"
                      cHelperText="Job Type"
                      cData={dropdownData ? dropdownData.job_type : {}}
                      cOnChange={handleJobTypeChange}
                      cIsSmall={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CDropdown
                      key={resetAll}
                      id="created_by"
                      cHelperText="Created By"
                      cData={dropdownData ? dropdownData.created_by : {}}
                      cOnChange={handleCreatedByChange}
                      cIsSmall={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="equipment_type"
                      cHelperText="Equipment Type"
                      cData={[...new Set(filteredEquipmentTypes)].sort() || []}
                      cOnChange={handleEquipmentTypeChange}
                      cIsSmall={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CDropdown
                      key={resetAll}
                      id="equipment_id"
                      cHelperText="Equipment ID"
                      cData={[...new Set(filteredEquipmentIDs)].sort() || []}
                      cOnChange={handleEquipmentIdChange}
                      cIsSmall={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CInput
                      key={resetAll}
                      id="job_desc"
                      cHintText="Enter Job Directive"
                      cOnChange={handleJobDirectiveChange}
                      cIsSmall={true}
                      cFullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showTable && (
              <>
                <CMainGrid
                  sx={{
                    marginTop: "1em",
                    marginBottom: "3em",
                    marginLeft: "1.5em",
                    marginRight: "1.5em",
                  }}>
                  <Grid container marginTop={3}>
                    <CTable
                      cHeaderData={infoHeadData}
                      cRowData={infoRowData}
                      cActualData={tableData}
                      cIsPagination={true}
                      cSmallSize={true}
                      showSearch={true}
                    />
                  </Grid>
                </CMainGrid>
              </>
            )}
          </Card>
        </Box>
      </Grid>
    </Grid>
    </>
  );
};

export default HistoricalJobs;
