import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchContinentData,
  fetchCountryData,
  fetchRegionData,
  fetchCityData,
  fetchFacilityData,
  getDownloadExportFile,
} from "../model/GLNModel";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { CombinedConstKeys } from "../../assets/VarConstants";

const GLNFilterVM = ({
  handleSubmit,
  setHeaderCheckboxState,
  headerCheckboxState,
  setFacilityHeaderCheckboxState,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const glnId = queryParams.get("glnId");
  const pathName = `${window.location.origin}${location.pathname}${location.search}`;

  const initializeMap = () => ({
    data: new Map(),
    search: "",
    filters: new Set(),
    checks: new Set(),
  });
  const { handleToastOpen } = useToast();

  const [lastSearch, setLastSearch] = useState([-1, ""]);

  const [resetKeys, setResetKeys] = useState(() => {
    const initialMap = new Map();
    initialMap.set(CombinedConstKeys.CONTINENT_KEY, 1);
    initialMap.set(CombinedConstKeys.COUNTRY_KEY, 1);
    initialMap.set(CombinedConstKeys.REGION_KEY, 1);
    initialMap.set(CombinedConstKeys.CITY_KEY, 1);
    initialMap.set(CombinedConstKeys.GLN_ID_KEY, 1);
    initialMap.set(CombinedConstKeys.FACILITY_NAME_KEY, 1);
    initialMap.set(CombinedConstKeys.OTHER_TYPE_KEY, 1);
    return initialMap;
  });

  const [allData, setAllData] = useState(() => {
    const initialMap = new Map();
    initialMap.set(CombinedConstKeys.CONTINENT_KEY, initializeMap());
    initialMap.set(CombinedConstKeys.COUNTRY_KEY, initializeMap());
    initialMap.set(CombinedConstKeys.REGION_KEY, initializeMap());
    initialMap.set(CombinedConstKeys.CITY_KEY, initializeMap());
    const dataMap = new Map();
    dataMap.set("BIC", "BIC");
    dataMap.set("IATA", "IATA");
    dataMap.set("IMO", "Port Facility Code");
    dataMap.set("SMDG", "SMDG");
    const filterSet = new Set(dataMap.keys());
    initialMap.set(CombinedConstKeys.OTHER_TYPE_KEY, {
      data: dataMap,
      search: "",
      filters: filterSet,
      checks: new Set(),
    });
    initialMap.set(CombinedConstKeys.FACILITY_NAME_KEY, initializeMap());
    return initialMap;
  });

  const [inputData, setInputData] = useState(() => {
    const initialMap = new Map();
    initialMap.set(CombinedConstKeys.CITY_KEY, null);
    initialMap.set(CombinedConstKeys.GLN_ID_KEY, null);
    initialMap.set(CombinedConstKeys.FACILITY_NAME_KEY, null);
    initialMap.set(CombinedConstKeys.OTHER_TYPE_KEY, null);
    return initialMap;
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const props = [
    {
      fetchMethod: fetchContinentData,
      localDataName: "continentData",
      keyIndex: CombinedConstKeys.CONTINENT_KEY,
      code: "continentCode",
      name: "continentName",
    },
    {
      fetchMethod: fetchCountryData,
      localDataName: "countryData",
      keyIndex: CombinedConstKeys.COUNTRY_KEY,
      code: "countryCode",
      name: "countryName",
    },
    {
      fetchMethod: fetchRegionData,
      keyIndex: CombinedConstKeys.REGION_KEY,
      code: "regionCode",
      name: "regionName",
    },
    {
      fetchMethod: fetchCityData,
      keyIndex: CombinedConstKeys.CITY_KEY,
      code: "cityCode",
      name: "cityName",
      prefixCode: "unlocode",
    },
    {
      fetchMethod: fetchFacilityData,
      keyIndex: CombinedConstKeys.FACILITY_NAME_KEY,
      code: "facilityCode",
      name: "facilityName",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      //console.log("This is called at the time of initialization only");

      const updatedData = new Map(allData);

      for (const {
        localDataName,
        fetchMethod,
        keyIndex,
        code,
        name,
      } of props) {
        if (localDataName) {
          let data = sessionStorage.getItem(localDataName);

          if (!data) {
            try {
              const fetchedData = await fetchMethod();
              sessionStorage.setItem(
                localDataName,
                JSON.stringify(fetchedData)
              );
              data = fetchedData;
            } catch (error) {
              // console.log(error.message);
            }
          } else {
            data = JSON.parse(data);
          }

          if (data) {
            const { dataToUpdate } = updateToCheckFormat(
              updatedData,
              keyIndex,
              data,
              code,
              name
            );
            updatedData.set(keyIndex, dataToUpdate);
          }
        }
      }

      setAllData(updatedData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    //console.log("lastSearch  ...   ", lastSearch);
    const keyIndex = lastSearch[0];
    if (
      keyIndex === CombinedConstKeys.CONTINENT_KEY ||
      keyIndex === CombinedConstKeys.COUNTRY_KEY
    ) {
      const searchData = [
        allData.get(CombinedConstKeys.CONTINENT_KEY).search,
        allData.get(CombinedConstKeys.COUNTRY_KEY).search,
        allData.get(CombinedConstKeys.REGION_KEY).search,
        allData.get(CombinedConstKeys.CITY_KEY).search,
      ];
      const updatedMap = [
        allData.get(CombinedConstKeys.CONTINENT_KEY).data,
        allData.get(CombinedConstKeys.COUNTRY_KEY).data,
        allData.get(CombinedConstKeys.REGION_KEY).data,
        allData.get(CombinedConstKeys.CITY_KEY).data,
      ];
      /**
       * For making sure earlier checked values are included checks is added.
       * Else remove this and add condition for union of checks and filters etc
       */
      const filterList = [
        new Set(allData.get(CombinedConstKeys.CONTINENT_KEY).checks),
        new Set(allData.get(CombinedConstKeys.COUNTRY_KEY).checks),
        new Set(),
        new Set(),
      ];
      const updatedChecks = [
        allData.get(CombinedConstKeys.CONTINENT_KEY).checks,
        allData.get(CombinedConstKeys.COUNTRY_KEY).checks,
        allData.get(CombinedConstKeys.REGION_KEY).checks,
        allData.get(CombinedConstKeys.CITY_KEY).checks,
      ];

      for (const [key, value] of updatedMap[0].entries()) {
        const isMatch = value.toLowerCase().startsWith(searchData[0]);
        if (isMatch) {
          const splitData = key.split("_");
          filterList[0].add(splitData[0]);
        }
      }
      const prevFilterList = new Set(filterList[0]);
      filterList[0].clear();

      for (const [key, value] of updatedMap[1].entries()) {
        const split = key.split("_")[0];
        const isMatch =
          prevFilterList.has(split) &&
          (searchData[1]
            ? value.toLowerCase().startsWith(searchData[1])
            : true);
        const isChecked =
          updatedChecks[0].size > 0 ? updatedChecks[0].has(split) : true;

        if (isMatch && isChecked) {
          filterList[1].add(key);
        }
        if (isMatch && updatedChecks[0].size === 0) {
          filterList[0].add(split);
        }
      }
      if (filterList[0].size === 0) {
        filterList[0] = prevFilterList;
      }
      const updatedData = new Map(allData);
      for (let i = 1; i < 3; i++) {
        const newData = updatedData.get(i);
        newData.filters = filterList[i - 1];
        updatedData.set(i, newData);
      }
      setAllData(updatedData);
    }
  }, [lastSearch]);

  useEffect(() => {
    if (isSubmitClicked) {
      setIsSubmitClicked(false);
      handleSubmit(inputData);
    }
  }, [inputData]);

  const updateSearch = (keyIndex) => (newValue) => {
    //console.log("Searched ...  ", keyIndex, newValue);
    const updatedData = new Map(allData);
    const defaultDataToUpdate = updatedData.get(keyIndex);
    defaultDataToUpdate.search =
      newValue === null ? "" : newValue.toLowerCase();
    setAllData(updatedData);
  };

  const handleSearchClick = async (keyIndex) => {
    //console.log("Searched clicked ...  ", keyIndex);
    const updatedData = new Map(allData);
    const valueToSearch = updatedData.get(keyIndex).search;

    const fetchDataAndUpdate = async (
      fetchMethod,
      filterVal1,
      filterVal2,
      code,
      name,
      otherVal,
      filterVal3
    ) => {
      try {
        const fetchedData = await fetchMethod(
          valueToSearch,
          filterVal1,
          filterVal2 ?? undefined,
          otherVal ?? undefined,
          filterVal3 ?? undefined
        );
        const { dataToUpdate, filterSet } = updateToCheckFormat(
          updatedData,
          keyIndex,
          fetchedData,
          code,
          name
        );

        if (
          keyIndex === CombinedConstKeys.REGION_KEY ||
          keyIndex === CombinedConstKeys.CITY_KEY
        ) {
          const intersection = new Set(
            [...dataToUpdate.checks].filter((element) => filterSet.has(element))
          );
          dataToUpdate.checks = intersection;
          if (keyIndex === CombinedConstKeys.CITY_KEY) {
            const { code, name } = props[keyIndex - 2];
            updateToCheckFormat(
              updatedData,
              keyIndex - 1,
              fetchedData,
              code,
              name
            );
          }
        }
      } catch (error) {
        //console.log('Error while fetching:', error);
        const dataToUpdateOnError = updatedData.get(keyIndex);
        dataToUpdateOnError.filters.clear();
        dataToUpdateOnError.data.clear();
      } finally {
        setAllData(updatedData);
        setLastSearch([keyIndex, "search"]);
      }
    };

    switch (keyIndex) {
      case CombinedConstKeys.FACILITY_NAME_KEY:
        {
          const prevKeyIndex1 = CombinedConstKeys.CITY_KEY;
          const prevKeyIndex2 = CombinedConstKeys.COUNTRY_KEY;
          const prevKeyIndex3 = CombinedConstKeys.REGION_KEY;
          const filterVal1 = Array.from(
            updatedData.get(prevKeyIndex1).checks
          ).join(",");
          const filterVal2 = Array.from(
            updatedData.get(prevKeyIndex2).checks
          ).join(",");
          const filterVal3 = Array.from(
            updatedData.get(prevKeyIndex3).checks
          ).join(",");
          const otherCodes = Array.from(
            allData.get(CombinedConstKeys.OTHER_TYPE_KEY).checks
          );
          if (
            valueToSearch ||
            filterVal1 ||
            filterVal2 ||
            filterVal3 ||
            otherCodes.length > 0
          ) {
            const { fetchMethod, code, name } = props[prevKeyIndex1];
            await fetchDataAndUpdate(
              fetchMethod,
              filterVal1,
              filterVal2,
              code,
              name,
              otherCodes,
              filterVal3
            );
          }
        }
        break;
      case CombinedConstKeys.CITY_KEY:
        {
          const prevKeyIndex1 = CombinedConstKeys.REGION_KEY;
          const prevKeyIndex2 = CombinedConstKeys.COUNTRY_KEY;
          const filterVal1 = Array.from(
            updatedData.get(prevKeyIndex1).checks
          ).join(",");
          const filterVal2 = Array.from(
            updatedData.get(prevKeyIndex2).checks
          ).join(",");
          if (valueToSearch || filterVal1 || filterVal2) {
            const { fetchMethod, code, name } = props[prevKeyIndex1];
            await fetchDataAndUpdate(
              fetchMethod,
              filterVal1,
              filterVal2,
              code,
              name
            );
          }
        }
        break;
      case CombinedConstKeys.REGION_KEY:
        {
          const prevKeyIndex = CombinedConstKeys.COUNTRY_KEY;
          const filterVal = Array.from(
            updatedData.get(prevKeyIndex).checks
          ).join(",");
          if (valueToSearch || filterVal) {
            const { fetchMethod, code, name } = props[prevKeyIndex];
            await fetchDataAndUpdate(fetchMethod, filterVal, null, code, name);
          }
        }
        break;
      default:
        setLastSearch([keyIndex, "search"]);
        break;
    }
  };
  const updateChecks =
    (keyIndex) =>
    (itemKey, isChecked, isHeaderCheckbox = false) => {
      const updateChecksForTypeOrFacility = (
        keyIndex,
        data,
        itemKey,
        isChecked,
        isOtherType
      ) => {
        const checkList = data.get(keyIndex).checks;

        if (isHeaderCheckbox) {
          // If header checkbox, process all items in data
          const currentData = data.get(keyIndex).data;
          if (isChecked) {
            // Add all items to checks
            currentData.forEach((_, key) => {
              checkList.add(key);
            });
          } else {
            // Clear all checks
            checkList.clear();
          }
        } else {
          // Original single item check logic
          if (isChecked) {
            checkList.add(itemKey);
          } else {
            checkList.delete(itemKey);
          }
        }

        setAllData(new Map(data));
      };

      const updateCheck = (checksSet, key, isChecked) => {
        if (isChecked) {
          checksSet.add(key);
        }
      };

      const updateRelatedFilters = (
        updatedData,
        filterIndex,
        itemKey,
        isChecked,
        isHeaderCheckbox = false
      ) => {
        const relatedChecks = updatedData.get(filterIndex).checks;

        if (isHeaderCheckbox) {
          // Clear all related checks if unchecking header
          if (!isChecked) {
            relatedChecks.clear();
            return;
          }

          // Get all available items and process them
          const currentData = updatedData.get(keyIndex).data;
          currentData.forEach((value, key) => {
            // For header checkbox, we'll use the key directly since itemKey won't be available
            switch (keyIndex) {
              case CombinedConstKeys.COUNTRY_KEY:
                // For country, we assume the key is already in the correct format
                updateCheck(relatedChecks, key, isChecked);
                break;
              case CombinedConstKeys.REGION_KEY:
                // For region, handle numeric keys differently
                if (typeof key === "number") {
                  updateCheck(relatedChecks, key, isChecked);
                } else {
                  const [continent, country] = key.split("_");
                  updateCheck(
                    relatedChecks,
                    `${continent}_${country}`,
                    isChecked
                  );
                  updateCheck(
                    updatedData.get(filterIndex - 1).checks,
                    continent,
                    isChecked
                  );
                }
                break;
              case CombinedConstKeys.CITY_KEY:
                // For city, handle numeric keys differently
                if (typeof key === "number") {
                  updateCheck(relatedChecks, key, isChecked);
                } else {
                  const [continent, country, region] = key.split("_");
                  updateCheck(
                    relatedChecks,
                    `${continent}_${country}_${region}`,
                    isChecked
                  );
                  updateCheck(
                    updatedData.get(filterIndex - 1).checks,
                    `${continent}_${country}`,
                    isChecked
                  );
                  updateCheck(
                    updatedData.get(filterIndex - 2).checks,
                    continent,
                    isChecked
                  );
                }
                break;
            }
          });
        } else {
          // Original single item update logic
          switch (keyIndex) {
            case CombinedConstKeys.COUNTRY_KEY:
              // For country, use the numeric key directly
              updateCheck(relatedChecks, itemKey, isChecked);
              break;
            case CombinedConstKeys.REGION_KEY:
              // For region, handle numeric keys
              if (typeof itemKey === "number") {
                updateCheck(relatedChecks, itemKey, isChecked);
              } else {
                const [continent, country] = itemKey.split("_");
                updateCheck(
                  relatedChecks,
                  `${continent}_${country}`,
                  isChecked
                );
                updateCheck(
                  updatedData.get(filterIndex - 1).checks,
                  continent,
                  isChecked
                );
              }
              break;
            case CombinedConstKeys.CITY_KEY:
              // For city, handle numeric keys
              if (typeof itemKey === "number") {
                updateCheck(relatedChecks, itemKey, isChecked);
              } else {
                const [continent, country, region] = itemKey.split("_");
                updateCheck(
                  relatedChecks,
                  `${continent}_${country}_${region}`,
                  isChecked
                );
                updateCheck(
                  updatedData.get(filterIndex - 1).checks,
                  `${continent}_${country}`,
                  isChecked
                );
                updateCheck(
                  updatedData.get(filterIndex - 2).checks,
                  continent,
                  isChecked
                );
              }
              break;
          }
        }
      };

      // Rest of the function remains the same...
      const performDataFetchAndUpdate = async (updatedData, keyIndex) => {
        const fetchKeyIndex = keyIndex + 1;
        const checkVal = updatedData.get(fetchKeyIndex).checks;
        const filterVal1 = Array.from(updatedData.get(keyIndex).checks).join(
          ","
        );
        const filterVal2 = Array.from(
          updatedData.get(keyIndex - 1).checks
        ).join(",");
        const { fetchMethod, code, name } = props[keyIndex];
        try {
          const valueToSearch = allData.get(fetchKeyIndex).search;
          if (valueToSearch || filterVal1) {
            const result = await fetchMethod(
              valueToSearch,
              filterVal1,
              filterVal2
            );
            updateToCheckFormat(updatedData, fetchKeyIndex, result, code, name);
          } else if (checkVal.size === 0) {
            const dataToUpdate = updatedData.get(fetchKeyIndex);
            dataToUpdate.data.clear();
            updatedData.set(fetchKeyIndex, dataToUpdate);
          }
        } catch (error) {
          // console.log(error.message);
        } finally {
          setAllData(updatedData);
          setLastSearch([keyIndex, "filter"]);
        }
      };

      const updatedData = new Map(allData);

      if (keyIndex === CombinedConstKeys.OTHER_TYPE_KEY) {
        updateChecksForTypeOrFacility(
          keyIndex,
          updatedData,
          itemKey,
          isChecked,
          true
        );
      } else if (keyIndex === CombinedConstKeys.FACILITY_NAME_KEY) {
        updateChecksForTypeOrFacility(
          keyIndex,
          updatedData,
          itemKey,
          isChecked,
          false
        );
      } else {
        if (isHeaderCheckbox) {
          // Handle header checkbox for other cases
          const currentData = updatedData.get(keyIndex).data;
          const currentChecks = updatedData.get(keyIndex).checks;

          if (isChecked) {
            // Add all items to checks
            currentData.forEach((_, key) => {
              currentChecks.add(key);
            });
          } else {
            // Clear all checks
            currentChecks.clear();
          }

          if (
            [
              CombinedConstKeys.COUNTRY_KEY,
              CombinedConstKeys.REGION_KEY,
              CombinedConstKeys.CITY_KEY,
            ].includes(keyIndex)
          ) {
            updateRelatedFilters(
              updatedData,
              keyIndex - 1,
              itemKey,
              isChecked,
              true
            );
          }
        } else {
          // Original single item check logic
          const currentChecks = updatedData.get(keyIndex).checks;
          if (isChecked) {
            currentChecks.add(itemKey);
          } else {
            currentChecks.delete(itemKey);
          }

          if (
            [
              CombinedConstKeys.COUNTRY_KEY,
              CombinedConstKeys.REGION_KEY,
              CombinedConstKeys.CITY_KEY,
            ].includes(keyIndex)
          ) {
            updateRelatedFilters(updatedData, keyIndex - 1, itemKey, isChecked);
          }
        }

        if (
          [
            CombinedConstKeys.COUNTRY_KEY,
            CombinedConstKeys.REGION_KEY,
          ].includes(keyIndex)
        ) {
          performDataFetchAndUpdate(updatedData, keyIndex);
        } else {
          setAllData(updatedData);
          setLastSearch([keyIndex, "filter"]);
        }
      }
    };

  const updateToCheckFormat = (
    updatedData,
    keyIndex,
    originalDataValue,
    code,
    name
  ) => {
    const dMap = new Map();
    const filterSet = new Set();
    const isFacility = keyIndex === CombinedConstKeys.FACILITY_NAME_KEY;
    const isCity = keyIndex === CombinedConstKeys.CITY_KEY;
    const isRegion = keyIndex === CombinedConstKeys.REGION_KEY;
    originalDataValue.forEach((data) => {
      let nameVal = data[name];
      let codeVal = data[code];
      if (isFacility) {
        const split = codeVal.split("_");
        nameVal = `${split[split.length - 1]} - ${nameVal}`;
      } else if (isCity) {
        const split = codeVal.split("_");
        nameVal = `${split[1]}${split[3]} - ${nameVal}`;
      } else if (isRegion && !codeVal) {
        const split = data["cityCode"].split("_");
        codeVal = `${split[0]}_${split[1]}_${split[2]}`;
      }

      dMap.set(codeVal, nameVal);
      filterSet.add(codeVal);
    });
    const dataToUpdate = updatedData.get(keyIndex) || {};
    dataToUpdate.filters = filterSet;
    dataToUpdate.data = dMap;
    return { dataToUpdate, dMap, filterSet };
  };

  const handleInputChange = (type) => (newValue) => {
    setInputData((prevData) => {
      const updatedData = new Map(prevData);
      updatedData.set(type, newValue);
      return updatedData;
    });
  };
  const handleSubmitClick = () => {
    const checkedData = new Map([
      [
        CombinedConstKeys.CITY_KEY,
        allData.get(CombinedConstKeys.CITY_KEY).checks,
      ],
      [
        CombinedConstKeys.FACILITY_NAME_KEY,
        allData.get(CombinedConstKeys.FACILITY_NAME_KEY).checks,
      ],
    ]);
    setIsSubmitClicked(true);
    const concatenatedCodes = {};

    const selectedCodeData = extractSelectedCodes();
    const alternateTypeCode = selectedCodeData.facilityName;

    checkedData.set(CombinedConstKeys.OTHER_TYPE_KEY, alternateTypeCode);

    checkedData.forEach((value, key) => {
      if (key === CombinedConstKeys.OTHER_TYPE_KEY) {
        handleInputChange(key)(value);
      } else {
        const concatenatedValues = [...value]
          .map((val) => `'${val}'`)
          .join(",");

        handleInputChange(key)(concatenatedValues);
        concatenatedCodes[key] = concatenatedValues;
      }
    });
  };

  const resetFilters = (keyIndex) => {
    if (
      keyIndex === CombinedConstKeys.CONTINENT_KEY ||
      keyIndex === CombinedConstKeys.COUNTRY_KEY ||
      keyIndex === CombinedConstKeys.REGION_KEY ||
      keyIndex === CombinedConstKeys.CITY_KEY ||
      keyIndex === CombinedConstKeys.OTHER_TYPE_KEY ||
      keyIndex === CombinedConstKeys.FACILITY_NAME_KEY
    ) {
      setAllData((prevData) => {
        const updatedData = new Map(prevData);
        const data = updatedData.get(keyIndex);
        data.search = "";
        data.checks.clear();

        if (
          keyIndex === CombinedConstKeys.CONTINENT_KEY ||
          keyIndex === CombinedConstKeys.COUNTRY_KEY
        ) {
          data.filters = new Set(data.data.keys());
        } else if (keyIndex !== CombinedConstKeys.OTHER_TYPE_KEY) {
          data.filters.clear();
          data.data.clear();
        }
        return updatedData;
      });
    } else if (keyIndex === CombinedConstKeys.GLN_ID_KEY) {
      setInputData((prevData) => {
        const updatedData = new Map(prevData);
        for (const key of updatedData.keys()) {
          updatedData.set(key, null);
        }
        return updatedData;
      });
    }
  };

  const handleResetClick = (keyIndex) => {
    setResetKeys((prevKeys) => {
      const updatedKeys = new Map(prevKeys);

      if (keyIndex === -1) {
        setIsSubmitClicked(true);
        for (const [key, value] of updatedKeys.entries()) {
          updatedKeys.set(key, -value);
          resetFilters(key);
        }
        setHeaderCheckboxState(false);
        setFacilityHeaderCheckboxState(false);
      } else {
        const value = prevKeys.get(keyIndex);
        updatedKeys.set(keyIndex, -value);
        resetFilters(keyIndex);
      }

      return updatedKeys;
    });
  };

  const extractSelectedCodes = () => {
    const countryCodes = [];
    const continentCodes = [];
    const alternateTypeCodes = [];
    const cityCodes = [];
    const facilityCodes = [];

    allData.forEach((value, key) => {
      // Extract alternate type codes
      if (key === CombinedConstKeys.OTHER_TYPE_KEY && value && value.checks) {
        alternateTypeCodes.push(...Array.from(value.checks));
      }

      // Extract continent codes
      if (
        key === CombinedConstKeys.CONTINENT_KEY &&
        value &&
        value.checks instanceof Set
      ) {
        continentCodes.push(...value.checks);
      }

      // Extract country codes
      if (value && value.checks && value.checks instanceof Set) {
        value.checks.forEach((check) => {
          if (typeof check === "string" && check.includes("_")) {
            const countryCode = check.split("_")[1];
            countryCodes.push(countryCode);
          }
        });
      }

      // Extract city codes (key 4)
      if (key === 4 && value && value.checks instanceof Set) {
        cityCodes.push(...Array.from(value.checks)); // Keep the entire code e.g. "AS_IN_MH_BOM"
      }

      // Extract facility codes (key 5)
      if (key === 5 && value && value.checks instanceof Set) {
        facilityCodes.push(...Array.from(value.checks)); // Keep the entire code e.g. "AS_IN_MH_BOM_SCAA"
      }
    });

    return {
      countryCodes: countryCodes,
      facilityName: alternateTypeCodes,
      continentCodes: continentCodes,
      cityCodes: cityCodes,
      facilityCodes: facilityCodes,
      fe_url: pathName,
      allFilter: headerCheckboxState,
    };
  };

  const downloadExportFile = async () => {
    try {
      setIsLoading(true);
      const selectedCodeData = extractSelectedCodes();
      const response = await getDownloadExportFile(selectedCodeData);
      const blob = new Blob([response.data]);
      const link = document.createElement("a");

      let filename;
      if (
        selectedCodeData.facilityName &&
        selectedCodeData.facilityName.length > 0
      ) {
        if (
          selectedCodeData.facilityName.length === 1 &&
          selectedCodeData.facilityName[0] === "IMO"
        ) {
          // If only IMO is selected
          filename = `Port_Facility.xlsx`;
        } else {
          // Replace IMO with Port_Facility in the array before joining
          const modifiedFacilityNames = selectedCodeData.facilityName.map(
            (name) => (name === "IMO" ? "Port_Facility" : name)
          );
          filename = `${modifiedFacilityNames.join("_")}.xlsx`;
        }
      } else {
        filename = `unlocode.xlsx`;
      }

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File Download Unsuccessful");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (glnId) {
      handleInputChange(CombinedConstKeys.GLN_ID_KEY)(glnId);
      handleSubmitClick();
    }
  }, [glnId]);

  return {
    hasPermission,
    setHasPermission,
    handleInputChange,
    handleSubmitClick,
    handleSearchClick,
    handleResetClick,
    resetKeys,
    updateSearch,
    updateChecks,
    allData,
    downloadExportFile,
    glnId,
    isLoading,
  };
};

export default GLNFilterVM;
