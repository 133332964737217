import { useState } from "react";
import { addAnyFileToTable } from "../model/MCTCommonFilesModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

const MCTCommonFilesVM = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { handleToastOpen } = useToast();

  const [selectedApi, setSelectedApi] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleApiChange = (value) => {
    setSelectedApi(value);
  };

  const handleFileChange = (files) => {
    setSelectedFiles(files);
  };

  const handleFileUpload = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null);

    try {
      // Simulate API call with a delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Perform actual file upload
      await addAnyFileToTable(selectedApi, selectedFiles);

      // Set success state
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      // Set loading state to false after the operation is completed
      setLoading(false);
    }
  };

  return {
    loading,
    success,
    error,
    handleFileChange,
    handleApiChange,
    handleFileUpload,
  };
};

export default MCTCommonFilesVM;
