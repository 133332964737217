// components/Dashboard/PopularPagesChart.js
import React, { useState } from 'react';
import { Paper, Typography, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Area, AreaChart } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        <Typography variant="subtitle2" sx={{ color: '#052660', mb: 1 }}>
          {label}
        </Typography>
        <Typography variant="body2">
          Visits: <strong>{payload[0].value}</strong>
        </Typography>
      </Box>
    );
  }
  return null;
};

const PopularPagesChart = ({ popularPages,title }) => {
  const [chartType, setChartType] = useState('bar');

  // Sort pages by visit count in descending order
  const sortedData = [...popularPages].sort((a, b) => b.visit_count - a.visit_count);

  const handleChartTypeChange = (event, newType) => {
    if (newType !== null) {
      setChartType(newType);
    }
  };

  // Custom tick formatter for better readability
  const formatYAxisTick = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value;
  };

  // Shorten long page paths for better display
  const formatXAxisTick = (value) => {
    return value.length > 20 ? value.substring(0, 17) + '...' : value;
  };

  return (
    <Paper 
      sx={{ 
        p: 3,
        mb: 4,
        backgroundColor: '#ffffff',
        borderRadius: 2,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6">
          {title}
        </Typography>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={handleChartTypeChange}
          size="small"
        >
          <ToggleButton value="bar" aria-label="bar chart">
            <ViewWeekIcon />
          </ToggleButton>
          <ToggleButton value="area" aria-label="area chart">
            <ShowChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box height={400}>
        <ResponsiveContainer width="100%" height="100%">
          {chartType === 'bar' ? (
            <BarChart data={sortedData} margin={{ top: 10, right: 30, left: 0, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="path" 
                tick={{ fontSize: 12 }} 
                interval={0} 
                angle={0} 
                textAnchor="end"
                height={100}
                tickFormatter={formatXAxisTick}
              />
              <YAxis 
                tickFormatter={formatYAxisTick}
                tick={{ fontSize: 12 }}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Legend />
              <Bar 
                dataKey="visit_count" 
                name="Page Visits"
                fill="#63b144"
                radius={[4, 4, 0, 0]}
                maxBarSize={50}
                // Remove hover effect by setting activeBar styles to match normal state
                style={{ cursor: 'pointer' }}
                activeBar={{ fill: '#63b144' }}
              />
            </BarChart>
          ) : (
            <AreaChart data={sortedData} margin={{ top: 10, right: 30, left: 0, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="path" 
                tick={{ fontSize: 12 }} 
                interval={0} 
                angle={0} 
                textAnchor="end"
                height={100}
                tickFormatter={formatXAxisTick}
              />
              <YAxis 
                tickFormatter={formatYAxisTick}
                tick={{ fontSize: 12 }}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Legend />
              <Area 
                type="monotone" 
                dataKey="visit_count" 
                name="Page Visits"
                stroke="#63b144"
                fill="#63b144"
                fillOpacity={0.3}
              />
            </AreaChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default PopularPagesChart;