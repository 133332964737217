const USER_ID = "user_id";
const UPDATE_USER_ID = "update_user_id";
const USER_NAME = "user_name";
const USER_EMAIL = "email";
const USER_ROLE_ID = "user_role_id";
const USER_ROLE_NAME = "user_role_name";
const USER_STATUS = "user_status"
const TERMINAL_ID = "user_terminal_id";
const TERMINAL_NAME = "user_terminal_name";
const NAV_USER = "navuser";

export const UserKeys = {
    USER_ID, USER_NAME, USER_EMAIL, USER_ROLE_ID, USER_ROLE_NAME,
    USER_STATUS, TERMINAL_ID, TERMINAL_NAME, UPDATE_USER_ID, NAV_USER,
}