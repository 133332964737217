import { useState, useEffect } from "react";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { getAllUserType, getAllTerminals, addUser } from "../model/UserModel";
import { UserKeys } from "../../assets/VarConstants";


const ModifyUserVM = () => {

    const { handleToastOpen } = useToast();
    const [userTypes, setUserTypes] = useState([]);
    const [userTerminals, setUserTerminals] = useState([]);
    const [user, setUser] = useState({
        [UserKeys.UPDATE_USER_ID]: null,
        [UserKeys.USER_EMAIL]: "",
        [UserKeys.USER_NAME]: "",
        [UserKeys.USER_ROLE_ID]: "",
        [UserKeys.TERMINAL_NAME]: "",
        [UserKeys.USER_STATUS]: "",
        isUpdate: false,

    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const navUser = JSON.parse(sessionStorage.getItem(UserKeys.NAV_USER));
                if (navUser) {
                    setUser(prevUser => ({
                        ...prevUser,
                        isUpdate: true,
                        [UserKeys.UPDATE_USER_ID]: navUser[UserKeys.USER_ID],
                        [UserKeys.USER_EMAIL]: navUser[UserKeys.USER_EMAIL],
                        [UserKeys.USER_NAME]: navUser[UserKeys.USER_NAME],
                        [UserKeys.USER_ROLE_ID]: navUser[UserKeys.USER_ROLE_ID],
                        [UserKeys.USER_ROLE_NAME]: navUser[UserKeys.USER_ROLE_NAME],
                        [UserKeys.TERMINAL_ID]: navUser[UserKeys.TERMINAL_ID],
                        [UserKeys.TERMINAL_NAME]: navUser[UserKeys.TERMINAL_NAME],
                        [UserKeys.USER_STATUS]: navUser[UserKeys.USER_STATUS],
                    }));
                }
                const [userTypesResponse, userTerminalsResponse] = await Promise.all([
                    getAllUserType(),
                    getAllTerminals()
                ]);
                const userTypes = userTypesResponse.data;
                const userTerminals = userTerminalsResponse.data;
                setUserTypes(userTypes);
                setUserTerminals(userTerminals);
            } catch (error) {
                handleToastOpen("error", error.message);
            }
        }

        fetchData();
    }, []);

    const handleUserChange = (key, isDropDown) => (value) => {
        console.log("handleUserChange  ", "key", key, "value", isDropDown);
        setUser(prevUser => ({
            ...prevUser,
            [key]: (isDropDown === true ? value.id : value)
        }));
    }

    const handleSubmit = async () => {
        try {
            const response = await addUser(user);
            const jsonString = JSON.stringify(user);
            sessionStorage.setItem(UserKeys.NAV_USER, jsonString);
            handleToastOpen("success", response.data.message);
        } catch (error) {
            handleToastOpen("error", error.message);
        }
    }


    return {
        userTypes,
        userTerminals,
        user,
        handleUserChange,
        handleSubmit,
    };
};

export default ModifyUserVM;
