import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import CInput from "./CInput";
import CCheckButton from "./CCheckButton";

const CDropDown = ({
  cId,
  cHelperText,
  cData,
  cErrorMsg,
  cOnChange,
  cKeyReset,
  cFullWidth,
  cIsOptionName,
  cIsMultiple,
  cDefaultValue,
  cIsRequired,
  cIsSession,
  cIsSmall,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(cDefaultValue || "");
  const [inputValue, setInputValue] = useState("");

  const resetRef = useRef(false);

  useEffect(() => {
    // Reset the selected value when resetRef is true
    if (resetRef.current) {
      setSelectedValue("");
      setInputValue("");
      resetRef.current = false;
    }
  }, [resetRef]);

  const renderInputLabel = () => {
    if (cIsRequired) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{cHelperText}</span>
          <span style={{ marginLeft: "4px" }}>*</span>
        </div>
      );
    } else {
      return cHelperText;
    }
  };

  const handleChange = (newValue) => {
    const updatedValue = newValue === null ? "" : newValue;
    setSelectedValue(updatedValue);
    cOnChange(updatedValue);
    // Update session storage if cIsSession is true
    if (cIsSession && updatedValue !== null) {
      if (typeof updatedValue === "string") {
        sessionStorage.setItem(cId, updatedValue);
      } else {
        var updatedValueString = JSON.stringify(updatedValue);
        sessionStorage.setItem(cId, updatedValueString);
      }
    }
  };

  // Check if session storage should be used to populate the value
  useEffect(() => {
    if (selectedValue === "" && cIsSession) {
      const sessionItem = sessionStorage.getItem(cId);
      if (sessionItem) {
        try {
          const json = JSON.parse(sessionItem);
          setSelectedValue(json);
        } catch (error) {
          setSelectedValue(sessionItem);
        }
      }
    }
  }, [selectedValue, cIsSession, cId]);

  // Ensure cData is an array
  const options = Array.isArray(cData) ? cData : [];

  return (
    <Autocomplete
      id={cId}
      multiple={cIsMultiple}
      disableCloseOnSelect={cIsMultiple}
      options={options}
      onChange={(event, newValue) => handleChange(newValue)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      getOptionLabel={(option) =>
        (option &&
          (cIsOptionName === undefined ? option : option[cIsOptionName])) ||
        ""
      }
      value={selectedValue || ""}
      renderOption={
        cIsMultiple &&
        ((props, option, { selected }) => (
          <li {...props}>
            <CCheckButton
              cLabel={
                cIsOptionName === undefined ? option : option[cIsOptionName]
              }
              cChecked={selectedValue.indexOf(option) > -1}
            />
          </li>
        ))
      }
      renderInput={(params) => (
        <CInput
          key={cKeyReset}
          cHintText={renderInputLabel()}
          cDropDown={params}
          cErrorMsg={cErrorMsg}
          cValue={
            cIsOptionName === undefined
              ? selectedValue
              : selectedValue[cIsOptionName]
          }
          cIsSmall={cIsSmall}
        />
      )}
      fullWidth={cFullWidth}
      PaperComponent={({ children, ...rest }) => (
        <Paper {...rest}>{children}</Paper>
      )}
    />
  );
};

export default CDropDown;
