import React from "react";
import { Card, Box } from "@mui/material";
// import { CCheckButton } from "fe_comp_base";
import CCheckButton from "../../../basecomp/components/CCheckButton";


let tColor = "white";
let cVariant = "h6";

const CCheckHeader = ({
    cDataDisplay,
    cDataGroup,
    cChecks,
    cOnChecked
}) => {

    const onChange = (key, isChecked) => {
        if (cOnChecked != undefined) {
            cOnChecked(key, isChecked);
        }
    };

    return (
        Object.entries(cDataGroup).map(([key, array], indexG) => (
            <Card
                key={indexG}
                variant="outlined"
                sx={{ height: "28em", width: "28em", margin: "2em" }}
            >
                <Box bgcolor="#ECEFF1" padding={"0.5em"}>
                    <CCheckButton
                        key={indexG}
                        cLabel={cDataDisplay[key]}
                        cChecked={cChecks.has(key)}
                        cIsSingle={false}
                        cHandleCheck={() => onChange(key, true)}
                        cHandleUnCheck={() => onChange(key, false)}
                    />
                </Box>
                <Box display="flex" flexDirection="column" padding={"0.5em"}>
                    {array.map((item, indexA) => {
                        return (
                            <CCheckButton
                                key={indexA}
                                cLabel={cDataDisplay[item]}
                                cChecked={cChecks.has(item)}
                                cIsSingle={false}
                                cHandleCheck={() => onChange(item, true)}
                                cHandleUnCheck={() => onChange(item, false)}
                            />
                        );
                    })}
                </Box>
            </Card>
        ))
    );

};
export default CCheckHeader;
