import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CFilterItem from "../../../basecomp/components/CFilterItem";
import CButton from "../../../basecomp/components/CButton";
import CInput from "../../../basecomp/components/CInput";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import GLNFilterVM from "../viewmodel/GLNFilterVM";
import { CombinedConstKeys } from "../../assets/VarConstants";
import CBackDrop from "../../../basecomp/components/CBackDrop";

const GLNFilterView = ({
  handleSubmit,
  headerCheckboxState,
  setHeaderCheckboxState,
  facilityHeaderCheckboxState,
  setFacilityHeaderCheckboxState,
}) => {
  const {
    handleInputChange,
    handleSubmitClick,
    handleSearchClick,
    handleResetClick,
    resetKeys,
    updateSearch,
    updateChecks,
    allData,
    downloadExportFile,
    glnId,
    isLoading,
  } = GLNFilterVM({
    handleSubmit,
    headerCheckboxState,
    setHeaderCheckboxState,
    facilityHeaderCheckboxState,
    setFacilityHeaderCheckboxState,
  });

  const hasBtnPermission = () => {
    const permissionData = localStorage.getItem("permissionData");
    const newData = "feature_gln_download";
    if (permissionData && permissionData.includes(newData)) {
      return true;
    }
    return false;
  };
  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnContinentName"
            cHeader="Continent"
            cKey={CombinedConstKeys.CONTINENT_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.CONTINENT_KEY)}
            cData={allData}
            cShowSearch={true}
            cShowDelete={true}
            cOnSearchCheck={updateSearch(CombinedConstKeys.CONTINENT_KEY)}
            cOnFilterCheck={updateChecks(CombinedConstKeys.CONTINENT_KEY)}
            cOnSearchClick={handleSearchClick}
            cOnResetClick={handleResetClick}
            cTooltipText="Select Continent to filter"
            cLabelEmpty={""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnCountryName"
            cHeader="Country"
            cKey={CombinedConstKeys.COUNTRY_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.COUNTRY_KEY)}
            cData={allData}
            cShowSearch={true}
            cShowDelete={true}
            cOnSearchCheck={updateSearch(CombinedConstKeys.COUNTRY_KEY)}
            cOnFilterCheck={updateChecks(CombinedConstKeys.COUNTRY_KEY)}
            cOnSearchClick={handleSearchClick}
            cOnResetClick={handleResetClick}
            cTooltipText="Select Country to filter"
            cLabelEmpty={""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnSubRegionName"
            cHeader="Sub Region"
            cKey={CombinedConstKeys.REGION_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.REGION_KEY)}
            cData={allData}
            cShowSearch={true}
            cShowDelete={true}
            cOnSearchCheck={updateSearch(CombinedConstKeys.REGION_KEY)}
            cOnFilterCheck={updateChecks(CombinedConstKeys.REGION_KEY)}
            cOnSearchClick={handleSearchClick}
            cOnResetClick={handleResetClick}
            cTooltipText="Select Sub Region to filter"
            cLabelEmpty={"No Region Exist"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnLocodeName"
            cHeader="UN/Locode - Name"
            cKey={CombinedConstKeys.CITY_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.CITY_KEY)}
            cData={allData}
            cShowSearch={true}
            cShowDelete={true}
            cOnSearchCheck={updateSearch(CombinedConstKeys.CITY_KEY)}
            cOnFilterCheck={updateChecks(CombinedConstKeys.CITY_KEY)}
            cOnSearchClick={handleSearchClick}
            cOnResetClick={handleResetClick}
            cTooltipText="Select Location Name or UN/Locode to filter"
            cLabelEmpty={""}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: "1em" }}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CInput
            cId="glnId"
            cValue={glnId}
            cType={"search"}
            cKeyReset={resetKeys.get(CombinedConstKeys.GLN_ID_KEY)}
            cHintText="Enter GLN ID"
            cOnChange={handleInputChange(CombinedConstKeys.GLN_ID_KEY)}
            cFullWidth={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnOtherType"
            cHeader="Alternate Type"
            cKey={CombinedConstKeys.OTHER_TYPE_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.OTHER_TYPE_KEY)}
            cData={allData}
            cShowSearch={false}
            // cIsSingle={true}
            cShowDelete={true}
            cTooltipText="Select Alternate Type to filter"
            cOnFilterCheck={updateChecks(CombinedConstKeys.OTHER_TYPE_KEY)}
            cOnResetClick={handleResetClick}
            cLabelEmpty={""}
            cShowHeaderCheckbox={true} // Enable the header checkbox
            cHeaderCheckboxChecked={headerCheckboxState} // Control the checkbox state
            cOnHeaderCheckboxChange={(checked) => {
              setHeaderCheckboxState(checked);
              updateChecks(CombinedConstKeys.OTHER_TYPE_KEY)(
                null,
                checked,
                true
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <CFilterItem
            cId="glnFacilityName"
            cHeader="Alternate Code - Name"
            cKey={CombinedConstKeys.FACILITY_NAME_KEY}
            cKeyReset={resetKeys.get(CombinedConstKeys.FACILITY_NAME_KEY)}
            cData={allData}
            cShowSearch={true}
            cShowDelete={true}
            cTooltipText="Select Alternate Code or Name to filter"
            cOnSearchCheck={updateSearch(CombinedConstKeys.FACILITY_NAME_KEY)}
            cOnFilterCheck={updateChecks(CombinedConstKeys.FACILITY_NAME_KEY)}
            cOnSearchClick={handleSearchClick}
            cOnResetClick={handleResetClick}
            cLabelEmpty={""}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", marginTop: "2em" }}
      >
        <CButtonGroup sx={{ display: "flex", justifyContent: "center" }}>
          <CButton
            cId="glnSubmitBtn"
            cText="Submit"
            cTooltipText="Fetch the search results"
            cOnClick={handleSubmitClick}
          />
          <CButton
            cId="glnClearBtn"
            cText="Clear All"
            cTooltipText="Reset all the fields"
            cOnClick={() => handleResetClick(-1)}
          />
          {hasBtnPermission() && (
            <CButton
              cId="glnDownloadBtn"
              cText="Download Excel"
              cTooltipText="Fetch the search results"
              cOnClick={downloadExportFile}
            />
          )}
        </CButtonGroup>
      </Grid>
    </>
  );
};

export default GLNFilterView;
