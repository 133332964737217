import { useEffect, useState } from "react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";

import {
  getJobDetail,
  pdfDownload,
  nonEamsPdfDownload,
} from "../model/JobDetailsModel";
import { QRCodeSVG } from "qrcode.react";

const JobDetailsVM = (props) => {
  const {
    spDetails,
    jobCardNumber,
    equipmentType,
    equipmentID,
    jobDirective,
    jobType,
    section,
    resources,
    workDesc,
    externalRef,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
  } = props;

  const [jobDropdown, setJobDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { handleToastOpen } = useToast();
  let response;

  //Job Details : Validations
  const isDisabled = !jobDirective || !equipmentType || !equipmentID;

  //send email
  const handleIconButtonClick = async () => {
    // Check for zero quantities and stop execution if found
    for (let i = 0; i < spDetails.length; i++) {
      const item = spDetails[i];
      if (Number(item.quantity) <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Stop execution
      }
    }

    if (isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive, Equipment type, and Equipment Id."
      );
      return;
    }

    if (finishDate && startDate && finishDate <= startDate) {
      handleToastOpen("error", "Invalid date range!");
      return;
    }

    setIsLoading(true); // Start the loader
    const startTime = Date.now(); // Track when the loader started

    try {
      const data = localStorage.getItem("permissionData");
      const dataArray = data ? data.split(",") : [];
      const type = dataArray.includes("feature_eams")
        ? "eams_mail"
        : "non_eams_mail";

      response =
        type === "eams_mail"
          ? await pdfDownload(
              jobCardNumber,
              equipmentID.equipment_number,
              jobDirective,
              jobType ?? "",
              section ?? "",
              resources,
              workDesc,
              externalRef,
              startDate,
              finishDate,
              type
            )
          : await nonEamsPdfDownload(
              jobCardNumber,
              equipmentType,
              equipmentID.equipment_number,
              jobDirective,
              jobType ?? "",
              section ?? "",
              resources,
              workDesc,
              externalRef,
              startDate,
              finishDate,
              type
            );
    } catch (error) {
      console.error("Error sending Email:", error);
    } finally {
      // Ensure loader is hidden after at least 20 seconds
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(20000 - elapsedTime, 0); // Calculate remaining time

      setTimeout(() => {
        setIsLoading(false);
        if (response.status === 200) {
          handleToastOpen("success", "Email sent successfully");
        } else {
          console.error("Error sending email:", response.status);
        }
      }, remainingTime);
    }
  };

  // Job Details: Pdf Download
  const handleButtonDownload = async () => {
    // Check for zero quantities and stop execution if found
    for (let i = 0; i < spDetails.length; i++) {
      const item = spDetails[i];
      if (Number(item.quantity) <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Stop execution
      }
    }

    // Validate date range
    if (finishDate && startDate && finishDate <= startDate) {
      handleToastOpen("error", "Invalid date range!");
      return;
    }

    const data = localStorage.getItem("permissionData");
    const dataArray = data.split(",");
    const containsFeatureEams = dataArray.includes("feature_eams");

    // Check if form is valid
    if (isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive, Equipment type, and Equipment Id."
      );
      return;
    }

    setIsLoading(true);

    // Helper function for creating and clicking a download link
    const triggerDownload = (blob, fileName) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    // Helper function for date formatting
    const getFormattedDate = () => {
      const currentDate = new Date();
      return {
        date: currentDate.toISOString().slice(0, 10).replace(/-/g, "_"),
        time: currentDate.toLocaleTimeString().replace(/:/g, "-"), // Optional: Format time for file name safety
      };
    };

    const { date, time } = getFormattedDate();
    const pdfType = containsFeatureEams ? "eams_pdf" : "non_eams_pdf";
    const response = await pdfDownload(
      jobCardNumber,
      equipmentID.equipment_number,
      jobDirective,
      jobType ?? "",
      section ?? "",
      resources,
      workDesc,
      externalRef,
      startDate,
      finishDate,
      pdfType
    );

    // Handle response status
    if (response === null) return; // Exit if the response is null due to error

    // Determine file name and content type
    const fileName = containsFeatureEams
      ? `Job${jobCardNumber}_${equipmentID.equipment_number}_WorkOrderRequest_${date}_${time}.pdf`
      : `JobDetails_${jobCardNumber}_${equipmentID.equipment_number}_${date}_${time}.zip`;

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    triggerDownload(blob, fileName);
    setIsLoading(false);
  };

  //Job Details : Start Date Onchange function
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  //Job Details : Start Date Onchange function
  const handleFinishDateChange = (value) => {
    setFinishDate(value);
  };

  //Job Details: Get distinct type and section for dropdown in job details page (Job Type)
  const fetchJobDropdown = async () => {
    try {
      const data = await getJobDetail();
      setJobDropdown(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchJobDropdown();
  }, []);

  const infoHeadData = [
    "Location/Bin",
    "Material",
    "Description",
    "QTY",
    "UOM",
    "OHQ",
    "QR code",
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: `storage_bin`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `material_no`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },

    {
      value: `material_desc`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `quantity`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `uom`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },

    {
      value: (actualRow) => (
        <span
          style={{
            color: actualRow.status === 1 ? "green" : "red",
            textAlign: "center",
          }}
        >
          {actualRow.status === 1 ? "Checked" : "Unchecked"}
        </span>
      ),
      tooltipText: (actualRow) =>
        actualRow.status === 1 ? "Checked" : "Unchecked",
      type: "C",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.material_no ? (
          <QRCodeSVG value={actualRow.material_no} size={50} />
        ) : (
          ""
        ),
      tooltipText: (actualRow) => (actualRow.material_no ? null : " "),
      type: "IC",
      actualType: (actualRow) => actualRow.tableMode,
    },
  ]);

  return {
    jobDropdown,
    // jobMail,
    handleStartDateChange,
    handleFinishDateChange,
    startDate,
    finishDate,
    handleIconButtonClick,
    handleButtonDownload,
    isLoading,
    infoHeadData,
    infoRowData,
  };
};

export default JobDetailsVM;
