import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PreviewIcon from "@mui/icons-material/Preview";

import {
  truncates,
  updateQuantity,
  deleteData,
  getJobCardNo,
  postDCatBasketEmail,
  postGreyRecordBasketEmail,
} from "../model/ShoppingBasketModel";

import {
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
} from "../model/MaterialSearchModel";
import { SpDataKeys } from "../../../assets/VarConstants";

const ShoppingBasketVM = () => {

  const [lclMaterialNo, setlclMaterialNo] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lcl_material_no =
    decodeURIComponent(searchParams.get("lcl_mat_no")) !== "null"
      ? decodeURIComponent(searchParams.get("lcl_mat_no"))
      : "";

  const oem_no =
    decodeURIComponent(searchParams.get("oem")) !== "null"
      ? decodeURIComponent(searchParams.get("oem"))
      : "";

  const oem_desc =
    decodeURIComponent(searchParams.get("desc")) !== "null"
      ? decodeURIComponent(searchParams.get("desc"))
      : "";

  const gblno =
    decodeURIComponent(searchParams.get("gblno")) !== "null"
      ? decodeURIComponent(searchParams.get("gblno"))
      : "";

  const user_quantity =
    decodeURIComponent(searchParams.get("quantity")) !== "null"
      ? decodeURIComponent(searchParams.get("quantity"))
      : "";

  const userd =
    decodeURIComponent(searchParams.get("userd")) !== "null"
      ? decodeURIComponent(searchParams.get("userd"))
      : "";

  const notes =
    decodeURIComponent(searchParams.get("notes")) !== "null"
      ? decodeURIComponent(searchParams.get("notes"))
      : "";

  // Use useEffect to set lclMaterialNo based on the search parameter
  useEffect(() => {
    if (lcl_material_no !== "null") {
      setlclMaterialNo(lcl_material_no);
    }
  }, [lcl_material_no]); // Only update when lcl_material_no changes

  const [isMaterialSearchUI, setIsMaterialSearchUI] = useState(
    !lcl_material_no
  );
  const [isShoppingBasketUI, setIsShoppingBasketUI] = useState(
    !!lcl_material_no
  );

  const [isJobDetailsUI, setIsobDetailsUI] = useState(false);
  const [isReplenishmentUI, setIsReplenishmentUI] = useState(false);
  const [jobCardNumber, setJobCardNumber] = useState("");
  const [externalRef, setExternalRef] = useState("");
  const [jobDirective, setJobDirective] = useState("");
  const [equipmentType, setEquipmentType] = useState(null);
  const [equipmentID, setEquipmentID] = useState(null);
  const [spDetails, setSpDetails] = useState([]);
  const [section, setSection] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState("");
  const [resources, setResources] = useState(1);
  const [workDesc, setWorkDesc] = useState("");
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [sliderImages, setSliderImages] = useState([]);
  const [spData, setSpData] = useState([]);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const { handleToastOpen } = useToast();

  // ---------------------------------------changes-------------------------------------
  let tableStickerDataGlobal = {};
  let isTableEdit = false;

  const handleTableEditClick = (key) => (value) => {
    tableStickerDataGlobal[key] = value;
  };

  // const [spData, setSpData] = useState([])
  const handleEditClick = async (data, index) => {
    if (data.tableMode === "E") {
      try {
        if (Number(tableStickerDataGlobal.quantity) <= 0) {
          // Convert to Number and check if it's less than or equal to 0
          handleToastOpen("error", "Quantity must be greater than 0");
          return; // Exit the function early if quantity is 0 or negative
        }

        const response = await updateQuantity(
          tableStickerDataGlobal.id,
          tableStickerDataGlobal.quantity
        );

        const updatedData = response.data;

        isTableEdit = false;
        setSpData((prevSpData) => {
          const spData = [...prevSpData];
          spData[index].tableMode = "T";
          spData[index].quantity = updatedData.quantity;
          return spData;
        });
        fetchSpBasketAll();
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    } else {
      if (isTableEdit === false) {
        isTableEdit = true;
        const updatedSpData = { ...data };

        updatedSpData.tableMode = "E";

        tableStickerDataGlobal = updatedSpData; //state was not working, used a variable
        setSpData((prevLabelData) => {
          const updatedLabelData = [...prevLabelData];
          updatedLabelData[index].tableMode = "E";
          return updatedLabelData;
        });
        fetchSpBasketAll();
      }
    }
  };


  const infoHeadData = [
    <div style={{ marginLeft: "7px" }}>
      <CameraAltIcon />
    </div>,
    "OEM No.",
    "OEM Description",
    "Notes",
    "Global Material No.",
    "Material",
    "Description",
    "QTY",
    "UOM",
    <div style={{ marginLeft: "7px" }}>
      <EditIcon />
    </div>,
    <div style={{ marginLeft: "7px" }}>
      <DeleteIcon />
    </div>,
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: (actualRow) =>
        actualRow.image_link.length === 0 ? <CameraAltIcon /> : <PreviewIcon />,
      tooltipText: (actualRow) =>
        actualRow.image_link.length === 0
          ? "Upload Material Images"
          : "View Material Images",
      type: "IC",
      actionV: (actualRow) => handleImageClick(actualRow),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
    {
      value: `oem_no`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },

    {
      value: `oem_description`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `notes`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.gbl_material_number
          ? actualRow.gbl_material_number
          : actualRow.global_material_no,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "F",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `material_no`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.gbl_material_desc
          ? actualRow.gbl_material_desc
          : actualRow.global_material_desc,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "F",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `quantity`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "M",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(SpDataKeys.QUANTITY_KEY)(value),
    },
    {
      value: `uom`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
    {
      value: (actualRow) =>
        actualRow.tableMode === "T" ? <EditIcon /> : <SaveIcon />,
      tooltipText: (actualRow) =>
        actualRow.tableMode === "T" ? "Edit" : "Save",
      type: "IC",
      actionV: (actualRow, index) => handleEditClick(actualRow, index),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
    {
      value: <DeleteIcon />,
      tooltipText: "Delete",
      type: "I",
      actionV: (actualRow) => handleDelete(actualRow.id),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
  ]);

  // ---------------------------------------changes-------------------------------------

  // Retrieve values from session storage when the component mounts
  useEffect(() => {
    const storedJobDirective = sessionStorage.getItem("sb_job_directive");
    const storedExternalRef = sessionStorage.getItem("sb_external_ref");
    const storedEquipmentType = sessionStorage.getItem("sb_equipment_type");
    const storedJobType = sessionStorage.getItem("sb_job_type");
    const storedSection = sessionStorage.getItem("sb_section");
    const storedWorkDesc = sessionStorage.getItem("sb_work_desc");
    const storedResources = sessionStorage.getItem("sb_resources");
    const storedstartDate = sessionStorage.getItem("sb_start_date_time");
    const storedfinishDate = sessionStorage.getItem("sb_finish_date_time");

    if (storedJobDirective !== "") {
      setJobDirective(storedJobDirective);
    }
    if (storedExternalRef !== "") {
      setExternalRef(storedExternalRef);
    }
    if (storedWorkDesc !== "") {
      setWorkDesc(storedWorkDesc);
    }
    if (!storedResources) {
      setResources(1); // If empty, set it to 1
    } else {
      setResources(storedResources); // If not empty, set it to the value of storedResources
    }
    if (storedEquipmentType !== "null") {
      setEquipmentType(storedEquipmentType);
    }
    if (storedJobType !== "null") {
      setJobType(storedJobType);
    }
    if (storedSection !== "null") {
      setSection(storedSection);
    }
    if (storedstartDate !== "null") {
      setStartDate(storedstartDate);
    }
    if (storedfinishDate !== "null") {
      setFinishDate(storedfinishDate);
    }
  }, []);

  //Shopping basket : Validations
  const isDisabled = !jobDirective || !equipmentType || !equipmentID;
  const handleIconButtonClick = () => {
    if (spDetails.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      sendEmail();
    }
  };

  //Material Search : To view image in carousel
  const handleImageClick = async (actualRow) => {
    if (actualRow.image_link.length !== 0) {
      setCarouselImages(actualRow.image_link);
      setOpenCarousel(true);
    } else {
      window.location.href = `/warehouse/image-upload?mat_no=${actualRow.material_no}`;
    }
  };

  //Shopping Basket : Truncate sp_basket_table all of latest user and terminal id (Sp Basket)
  const DeleteAll = async () => {
    try {
      const response = await truncates();
      if (response.data.error) {
        console.error(
          "Error occurred while truncating table:",
          response.data.error
        );
      } else {
        setSpDetails([]);
        setSpData([]);
        handleToastOpen("success", "Table truncated successfully");
      }
    } catch (error) {
      console.error("Error occurred while truncating table:", error);
    }
  };

  //Shopping Basket : Delete row data by its ID (Sp Basket)
  const handleDelete = async (id) => {
    try {
      await deleteData(id);
      fetchSpDataAll();
      fetchSpBasketAll();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  //Shopping Basket : Get all data from sp_basket_table by terminal_id  and type="SB" (Sp Basket)
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        setSpDetails(data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketAll();
  }, []);

  //D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        const updatedData = data.map((obj) => ({
          ...obj,
          tableMode: "T",
        }));

        setSpData(updatedData);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpDataAll();
  }, []);

  //Shopping Basket : Generate new Job card number logic (Job Id Catalogue)
  useEffect(() => {
    const fetchJobCardNumber = async () => {
      try {
        const response = await getJobCardNo();
        const data = await response.data;
        if (data) {
          setJobCardNumber((prevJobCardNumber) => {
            return data.job_card_no;
          });
        } else {
          console.error(data.error);
        }
      } catch (error) {
        console.error("Error occurred during API request:", error);
      }
    };

    fetchJobCardNumber();
  }, []);

  //Shopping Basket: Email all records in sp basket based on latest user and terminal
  const sendEmail = async () => {
    try {
      setIsLoading(true); // Start the loader

      // Check for zero quantities and stop execution if found
      for (let i = 0; i < spDetails.length; i++) {
        const item = spDetails[i];
        if (Number(item.quantity) <= 0) {
          handleToastOpen("error", "Quantity must be greater than 0");
          return; // Stop execution
        }
      }

      // Proceed with email sending if no zero quantities are found
      const response = await postDCatBasketEmail();

      if (response.status === 200) {
        handleToastOpen("success", "Email sent successfully");
      } else {
        console.error("Error sending email:", response.status);
      }
    } catch (error) {
      // Handle any errors during the email sending process
      let errorMessage = "An error occurred";
      if (error.response && error.response.data) {
        // If there's a response from the server and it contains error data
        errorMessage = error.response.data;
      } else if (error.message) {
        // If there's an error message in the error object
        errorMessage = error.message;
      }

      handleToastOpen("error", errorMessage); // Display the error message in handleToastOpen
      console.error("Error sending email:", error);
    } finally {
      setIsLoading(false); // Stop the loader regardless of success or error
    }
  };

  //Shopping Basket: Email only grey records in sp basket based on latest user and terminal
  const handleGreyRecords = async () => {

    // Convert quantity to a number and check if it's zero
    for (let i = 0; i < spData.length; i++) {
      const item = spData[i];
      const quantity = Number(item.quantity);

      // Log the quantity to the console
      console.log(`Item ${i} quantity: ${quantity}`);

      if (quantity <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Stop execution
      }
    }


    if (spData.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      try {
        setIsLoading(true); // Start the loader
        const response = await postGreyRecordBasketEmail(
          jobCardNumber,
          jobDirective,
          equipmentID.equipment_number
        );

        if (response.status === 200) {
          handleToastOpen("success", "Email sent successfully");
        } else {
          console.error("Error sending email:", response.status);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //Job Details : Start Date Onchange function
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  //Job Details : Finish Date Onchange function
  const handleFinishDateChange = (value) => {
    setFinishDate(value);
  };

  return {
    handleImageClick,
    DeleteAll,
    handleDelete,
    sendEmail,
    handleGreyRecords,
    setExternalRef,
    setJobDirective,
    setEquipmentType,
    setEquipmentID,
    setStartDate,
    setFinishDate,
    startDate,
    finishDate,
    handleStartDateChange,
    handleFinishDateChange,
    clickedImageIndex,
    sliderImages,
    isMaterialSearchUI,
    setIsMaterialSearchUI,
    isShoppingBasketUI,
    setIsShoppingBasketUI,
    isJobDetailsUI,
    setIsobDetailsUI,
    isReplenishmentUI,
    setIsReplenishmentUI,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    externalRef,
    jobDirective,
    equipmentType,
    equipmentID,
    spDetails,
    setClickedImageIndex,
    editedQuantity,
    setEditedQuantity,
    setSpDetails,
    setSliderImages,
    jobCardNumber,
    handleIconButtonClick,
    setSpData,
    spData,
    fetchSpDataAll,
    lclMaterialNo,
    setlclMaterialNo,
    sliderImages,
    openCarousel,
    clickedImageIndex,
    setOpenCarousel,
    isLoading,
    fetchSpBasketAll,
    infoHeadData,
    infoRowData,
    fetchSpDataAll,
    carouselImages,
  };
};

export default ShoppingBasketVM;
