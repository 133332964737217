import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const {
  axiosGetCallWithToken,
  axiosDeleteCallWithToken,
  axiosPutCallWithToken,
} = AxiosWrapper;

export const truncates = async () => {
  const response = await axiosDeleteCallWithToken(`/dcatalogue/deleteSpBasket`);
  return response;
};

export const updateQuantity = async (id, editedQuantity) => {
  const response = await axiosPutCallWithToken(`/dcatalogue/updateQuantity`, {
    basket_id: id,
    quantity_ord: editedQuantity,
  });
  return response;
};

export const deleteData = async (id) => {
  const response = await axiosDeleteCallWithToken(
    `/dcatalogue/deleteSpBasket?id=${id}`
  );
  return response;
};

export const getJobCardNo = async () => {
  const response = await axiosGetCallWithToken(`/dcatalogue/getJobCardNo`);
  return response;
};

export const postDCatBasketEmail = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getDCatBasketEmail`
  );
  return response;
};

export const postGreyRecordBasketEmail = async (
  jobCardNumber,
  jobDirective,
  equipmentID
) => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getGreyRecordBasketEmail?job_card_no=${jobCardNumber}&job_desc=${jobDirective}&equipment_id=${equipmentID}`
  );
  return response;
};
