import { useState, useEffect } from "react";
import {
  fetchJobDetails,
  getSpBasketID,
  getSq01All,
  postSpBasket,
  pdfDownload,
} from "../model/HistoricalJobDetailsModel";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { getGlobalAndSpBasket } from "../model/HistoricalJobDetailsModel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import shoppingBasketVM from "../../sp_basket/viewmodel/ShoppingBasketVM";

const HistoricalJobDetailsVM = (id) => {
  const { jobCardNumber } = shoppingBasketVM();
  const [jobDetails, setJobDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [spBasketId, setSpBasketId] = useState("");
  const [spDetails, setSpDetails] = useState([]);
  const [clickedData, setClickedData] = useState(null);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const { handleToastOpen } = useToast();

  //D-Catalogue Job Catalogue Details
  useEffect(() => {
    const fetchJobDetailsData = async () => {
      try {
        const data = await fetchJobDetails(id);
        setJobDetails(data[0]);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
    fetchJobDetailsData();
  }, [id]);

  //D-Catalogue Work Order Details
  useEffect(() => {
    const fetchSpDetailsData = async () => {
      try {
        const data1 = await getGlobalAndSpBasket(id);
        setSpDetails(data1);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
    fetchSpDetailsData();
  }, [id]);

  //Historical Detail : Generate new sp_basket id logic (Job Id Catalogue)
  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        setSpBasketId(data.sp_basket_id);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketID();
  }, []);

  const onButtonClick = async () => {
    try {
      setIsLoading(true);
      const type = "work_order";
      const jobId = jobDetails.id;
      const response = await pdfDownload(jobId, type);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "_");
      const currentTime = currentDate.toLocaleTimeString();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Work_Order_Request_${formattedDate} ${currentTime}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching job details:", error);
    }
  };

  // Function to match inserted data with spData
  function matchInsertedData(spData, insertedData) {
    // Convert spData to an array of objects if it's not already
    const spDataArray = Array.isArray(spData) ? spData : Object.values(spData);

    // Check if insertedData is an array
    if (!Array.isArray(insertedData)) {
      console.error('insertedData should be an array. Provided:', insertedData);
      return false;
    }

    // Function to check if a data entry matches any entry in spData
    function isMatch(entry) {
      // Check if spItem is defined and has the properties
      return spDataArray.some(spItem => {
        return (entry.material_no === spItem.material_no ||
          entry.global_material_no === spItem.global_material_no) &&
          entry.terminal_id === spItem.terminal_id;
      });
    }

    // Check each entry in insertedData for a match
    return insertedData.every(insertedItem => {
      // Check if duplicates is defined and is an array
      if (!Array.isArray(insertedItem.duplicates)) {
        console.error('duplicates should be an array. Provided:', insertedItem.duplicates);
        return false;
      }

      // Check if at least one duplicate entry matches
      return insertedItem.duplicates.some(isMatch);
    });
  }

  //Historical Job : Get all Sq01 details for filtering table (SQ01) && Insert or Add data in sp_basket (Sp Basket)
  const handleAddToCart = async () => {
    setIsLoading(true);
    try {
      const spData = spDetails;
      const sq01AllData = await getSq01All(); // Assuming this retrieves sq01AllData

      const result = spData.map((data, index) => {
        // Add an index parameter
        const materialNoToSearch = data.material_no;
        const terminalToSearch = data.terminal_id;

        // Find a matching entry in sq01AllData based on material and plant_id
        const Sq01Data = sq01AllData.find(
          (sq01) =>
            sq01.material_no == materialNoToSearch &&
            sq01.plant_id == terminalToSearch
        );

        if (Sq01Data) {
          const completeData = {
            ...data, // Copy data from spData
            ...Sq01Data, // Spread all fields from completeData
          };

          // You found a matching entry, now you can process it
          const qty_val = completeData.ohqty - data.quantity;
          const status_qty = qty_val >= 0 ? 1 : 0;
          const storage_bin_loc =
            completeData.storage_loc +
            (completeData.bin_location === null
              ? ""
              : " / " + completeData.bin_location);

          // Generate a new spBasketId for each row by appending the index
          const newSpBasketId = spBasketId + index;
          return {
            id: newSpBasketId, // Use the new spBasketId
            oem_no: completeData.oem_no || "",
            oem_description: completeData.oem_description || "",
            notes: completeData.notes || "",
            quantity: completeData.quantity || "",
            uom: completeData.b_un || "",
            material_no: completeData.material_no || "",
            storage_bin: storage_bin_loc || "",
            status: status_qty,
            job_id: jobCardNumber || "",
            type: "SB" || "",
            global_material_no: completeData.global_material_no || "",
            global_material_desc: completeData.global_material_desc || "",
          };
        } else {
          // Generate a new spBasketId for each row by appending the index
          const newSpBasketId = spBasketId + index;
          return {
            id: newSpBasketId, // Use the new spBasketId
            oem_no: data.oem_no || "",
            oem_description: data.oem_description || "",
            notes: data.notes || "",
            quantity: data.quantity || "",
            uom: data.b_un || "",
            material_no: data.material_no || "",
            storage_bin: data.storage_bin || "",
            status: data.status || 0, // You can set a default value here
            job_id: jobCardNumber || "",
            type: "SB" || "",
            global_material_no: data.global_material_no || "",
            global_material_desc: data.global_material_desc || "",
          };
        }
      });

      if (result) {
        const response = await postSpBasket(result);

        // Extract insertedData from the response
        const insertedData = response.data.insertedData;

        // Check if the inserted data matches the spData
        const finalResult = matchInsertedData(spData, insertedData);

        if (finalResult === true) {
          setModalOpen(false);
          handleToastOpen("error", "Record already present!");
        } else {
          setModalOpen(false);
          handleToastOpen("success", "Data added successfully!");
          fetchSpBasketID();
        }
      } else {
        handleToastOpen("error", "Error occurred during API request:");
      }
    } catch (error) {
      handleToastOpen("error", "Result is empty. No API call made.");
    } finally {
      setIsLoading(false); // Set isLoading to false after try-catch block execution
    }
  };

  //Historical Job : Get all Sq01 details for filtering table (SQ01)
  const handleSaveData = async () => {
    setModalOpen(false)
    setIsLoading(true);
    try {
      if (quantity <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Exit function if quantity is not valid
      }
      const data = clickedData;
      const newQuantity = quantity;

      const sq01AllData = await getSq01All();

      const materialNoToSearch = data.material_no;
      const terminalToSearch = data.terminal_id;

      // Find a matching entry in Sq01Data based on material and plant_id
      const Sq01Data = sq01AllData.find(
        (sq01) =>
          sq01.material_no == materialNoToSearch &&
          sq01.plant_id == terminalToSearch
      );

      let results = []; // Initialize an empty array to store multiple results

      if (Sq01Data) {
        // Merge the data from sq01AllData and clickedData
        const completeData = {
          ...Sq01Data, // Data from sq01AllData
          ...data, // Data from clickedData
        };

        const qty_val = completeData.ohqty - newQuantity; // Use newQuantity here
        const status_qty = qty_val >= 0 ? 1 : 0;
        const storage_bin_loc =
          completeData.storage_loc +
          (completeData.bin_location === null
            ? ""
            : " / " + completeData.bin_location);

        const spId = spBasketId;
        const result = {
          id: spId,
          oem_no: data.oem_no || "",
          oem_description: data.oem_description || "",
          notes: data.notes || "",
          quantity: newQuantity || "",
          uom: completeData.b_un || "",
          material_no: completeData.material_no || "",
          storage_bin: storage_bin_loc || "",
          status: status_qty,
          job_id: jobCardNumber || "",
          type: "SB" || "",
          global_material_no: completeData.global_material_no || "",
          global_material_desc: completeData.global_material_desc || "",
        };

        results.push(result); // Push the result object into the results array
      } else {
        const spId = spBasketId;
        const result = {
          id: spId,
          oem_no: data.oem_no || "",
          oem_description: data.oem_description || "",
          notes: data.notes || "",
          quantity: newQuantity || "",
          uom: data.b_un || "",
          material_no: data.material_no || "",
          storage_bin: data.storage_bin || "",
          status: data.status || 0, // You can set a default value here
          job_id: jobCardNumber || "",
          type: "SB" || "",
          global_material_no: data.global_material_no || "",
          global_material_desc: data.global_material_desc || "",
        };

        results.push(result); // Push the result object into the results array
      }

      const response = await postSpBasket(results);

      // Extract insertedData from the response
      const insertedData = response.data.insertedData;

      // Check if the inserted data matches the spData
      const finalResult = matchInsertedData(spDetails, insertedData);

      if (finalResult === true) {
        setModalOpen(false);
        handleToastOpen("error", "Record already present!");
      } else {
        setModalOpen(false);
        handleToastOpen("success", "Data added successfully!");
        fetchSpBasketID();
      }

    } catch (error) {
      handleToastOpen("error", "Result is empty. No API call made.");
    } finally {
      setIsLoading(false); // Set isLoading to false after try-catch block execution
    }
  };

  //Historical Job :Open Modal for quantity
  const handleOpenModal = async (actualRow) => {
    try {
      setClickedData(actualRow);
      setQuantity(1);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const clearSelection = async () => {
    setModalOpen(false);
  };

  //Historical Job : To view image in carousel
  const handleImageClick = async (actualRow) => {
    if (actualRow.image_link.length !== 0) {
      setCarouselImages(actualRow.image_link);
      setOpenCarousel(true);
    } else {
      window.location.href = `/warehouse/image-upload?mat_no=${actualRow.material_no}`;
    }
  };

  const infoHeadData = [
    <div style={{ marginLeft: "7px" }}>
      <ShoppingCartOutlinedIcon />
    </div>,
    <div style={{ marginLeft: "7px" }}>
      <CameraAltIcon />
    </div>,
    "OEM No.",
    "OEM Description",
    "Notes",
    "Global Material No.",
    "Material",
    "Description",
    "QTY",
    "UOM",
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: (actualRow) =>
        actualRow.length === 0 ? null : <ShoppingCartOutlinedIcon />,
      tooltipText: (actualRow) =>
        actualRow.length === 0 ? null : "Add to cart",
      type: "IC",
      actionV: (actualRow) => handleOpenModal(actualRow),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
    {
      value: (actualRow) =>
        actualRow.image_link.length === 0 ? <CameraAltIcon /> : <PreviewIcon />,
      tooltipText: (actualRow) =>
        actualRow.image_link.length === 0
          ? "Upload Material Images"
          : "View Material Images",
      type: "IC",
      actionV: (actualRow) => handleImageClick(actualRow),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
    {
      value: `oem_no`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },

    {
      value: `oem_description`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `notes`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.gbl_material_number
          ? actualRow.gbl_material_number
          : actualRow.global_material_no,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "F",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `material_no`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: (actualRow) =>
        actualRow.gbl_material_desc
          ? actualRow.gbl_material_desc
          : actualRow.global_material_desc,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "F",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `quantity`,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
    },
    {
      value: `uom`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.material_no && actualRow.material_no !== null ? 1 : 0,
    },
  ]);

  //Material Search : Check quantity must be numeric and update new quantity
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      setQuantity("");
      setError("Value must be numeric");
    }
  };

  return {
    jobDetails,
    modalOpen,
    selectedWorkOrderId,
    onButtonClick,
    handleImageClick,
    handleAddToCart,
    setSelectedWorkOrderId,
    error,
    quantity,
    spDetails,
    handleSaveData,
    setOpenCarousel,
    openCarousel,
    handleToastOpen,
    isLoading,
    infoHeadData,
    infoRowData,
    carouselImages,
    clearSelection,
    handleChange,
  };
};

export default HistoricalJobDetailsVM;
