/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import Port from "../assets/port.png";
import Warehouse from "../assets/warehouse.png";
import Manufacturing from "../assets/manufacturing.png";
import Building from "../assets/building.png";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
function IndustryverticlesPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Industry Verticals
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <Grid
          container
          spacing={3}
          style={{ justifyContent: "center", marginTop: "0.5em" }}
        >
          <Grid item xs={12} sm={6} md={2.5}>
            <Card
              variant="outlined"
              style={{ border: "none", borderRadius: "10px 10px 0 0" }}
            >
              <div className="image-box">
                <img src={Port} className="image" />
              </div>
              <div className="bg-light p-4 fontclass">
                <center>
                  <h3 style={{ whiteSpace: "nowrap", margin: "1.5em" }}>
                    Port & Terminals
                  </h3>
                </center>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2.5}>
            <Card
              variant="outlined"
              style={{ border: "none", borderRadius: "10px 10px 0 0" }}
            >
              <div className="image-box">
                <img src={Warehouse} className="image" />
              </div>
              <div className="bg-light p-4 fontclass">
                <center>
                  <h3 style={{ whiteSpace: "nowrap", margin: "1.5em" }}>
                    Warehouse and Logistics
                  </h3>
                </center>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2.5}>
            <Card
              variant="outlined"
              style={{ border: "none", borderRadius: "10px 10px 0 0" }}
            >
              <Link to="/Warehouse">
                <div className="image-box">
                  <img src={Building} className="image" />
                </div>
              </Link>
              <div className="bg-light p-4 fontclass">
                <center>
                  <h3 style={{ whiteSpace: "nowrap", margin: "1.5em" }}>
                    Building & Construction
                  </h3>
                </center>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2.5}>
            <Card
              variant="outlined"
              style={{ border: "none", borderRadius: "10px 10px 0 0" }}
            >
              <Link to="/PowerBI">
                <div className="image-box">
                  <img src={Manufacturing} className="image" />
                </div>
              </Link>
              <div className="bg-light p-4 fontclass">
                <center>
                  <h3 style={{ whiteSpace: "nowrap", margin: "1.5em" }}>
                    Manufacturing
                  </h3>
                </center>
              </div>
            </Card>
          </Grid>
        </Grid>

        <Footer />
      </div>
    </>
  );
}

export default IndustryverticlesPage;
