import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CButton from "../../../basecomp/components/CButton";
import CInput from "../../../basecomp/components/CInput";
import CText from "../../../basecomp/components/CText";
import CCheckButton from "../../../basecomp/components/CCheckButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.common.black,
    fontSize: 14,
    border: "1px solid black",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid black",
  },
}));

const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_STATUSES = ["In Progress", "Pending", "On Hold"];

const MCTTable = ({
  cHeaderData,
  cRowData,
  cActualData,
  cTooltipText,
  cIsPagination,
  cSmallSize,
  cCustomRows,
  showSearch = true,
}) => {
  console.log("cActualData", cActualData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredData = React.useMemo(() => {
    let filtered = [...cActualData];

    if (searchTerm) {
      const trimmedSearch = searchTerm.trim().toLowerCase(); // Convert search to lowercase

      return filtered.filter((row) => {
        return cRowData.some((rowData) => {
          const cellValue =
            typeof rowData.value === "function"
              ? rowData.value(row)
              : row[rowData.value];

          // Always convert cell value to lowercase for consistent comparison
          const cellValueString =
            cellValue != null ? cellValue.toString().toLowerCase() : "";

          // Return true if it's an exact match OR if it starts with the search term
          return (
            cellValueString === trimmedSearch ||
            cellValueString.startsWith(trimmedSearch)
          );
        });
      });
    }

    return filtered.filter((row) => ALLOWED_STATUSES.includes(row.status));
  }, [searchTerm, cActualData, cRowData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    console.log("event.target.value", event.target.value);
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  // Rest of the component code remains the same...
  const getCellBackgroundColor = (rowData, actualRow) => {
    if (rowData.statusType === "bg") {
      if (typeof rowData.status === "function") {
        const statusValue = rowData.status(actualRow);
        switch (statusValue) {
          case 0:
            return "#E0E0E0";
          case 1:
            return "#00CC00";
          case 2:
            return "#FF7F7F";
          case 3:
            return "#ffe0b2";
          default:
            return "#FFFFFF";
        }
      }
    }
    return "inherit";
  };

  const handleCellData = (rowData, actualRow, rowIndex) => {
    let cellContent;
    let rowDataType = rowData.type;
    if (rowDataType === "M") {
      rowDataType = rowData.actualType(actualRow);
    }
    if (rowDataType === "T") {
      if (rowData.tooltipText) {
        cellContent = (
          <Tooltip
            title={
              rowData.tooltipText && (
                <Typography
                  style={{ whiteSpace: "pre-line" }}
                  sx={{ fontSize: "1em" }}
                >
                  {rowData.tooltipText(actualRow)}
                </Typography>
              )
            }
          >
            <span>
              <CText
                cText={actualRow[rowData.value]}
                cIsJson={rowData.isJson}
              />
            </span>
          </Tooltip>
        );
      } else {
        cellContent = (
          <CText cText={actualRow[rowData.value]} cIsJson={rowData.isJson} />
        );
      }
    } else if (rowDataType === "B") {
      cellContent = (
        <CButton
          cText={rowData.value}
          cOnClick={() => rowData.actionV(actualRow)}
          cDisabled={rowData.isDisable(actualRow)}
        />
      );
    } else if (rowDataType === "I" || rowDataType === "IC") {
      let isShow = true;
      if (rowData.status !== undefined && actualRow[rowData.status] === 0) {
        isShow = false;
      }
      if (isShow) {
        cellContent = (
          <CButton
            cSrc={
              rowDataType === "IC" ? rowData.value(actualRow) : rowData.value
            }
            cTooltipText={
              rowDataType === "IC"
                ? rowData.tooltipText(actualRow)
                : rowData.tooltipText
            }
            cOnClick={() =>
              rowData.actionV(actualRow, page * rowsPerPage + rowIndex)
            }
          />
        );
      }
    } else if (rowDataType === "E") {
      cellContent = (
        <CInput
          cValue={actualRow[rowData.value]}
          cOnChange={rowData.actionV}
          cType={rowData.eType}
        />
      );
    } else if (rowDataType === "C") {
      cellContent = (
        <CCheckButton
          cChecked={actualRow.status === 1}
          cDisabled={true}
          cTooltipText={actualRow.status === 1 ? "Checked" : "Unchecked"}
        />
      );
    } else if (rowDataType === "S") {
      cellContent = (rowIndex + 1).toString() + ".";
    } else if (rowDataType === "L") {
      const tooltipContent = rowData.tooltipText
        ? typeof rowData.tooltipText === "function"
          ? rowData.tooltipText(actualRow)
          : rowData.tooltipText
        : "";

      cellContent = (
        <Tooltip
          title={
            tooltipContent && (
              <Typography
                style={{ whiteSpace: "pre-line" }}
                sx={{ fontSize: "1em" }}
              >
                {tooltipContent}
              </Typography>
            )
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              rowData.actionV(actualRow);
            }}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
          >
            {actualRow[rowData.value]}
          </a>
        </Tooltip>
      );
    } else if (rowDataType === "F") {
      const value =
        typeof rowData.value === "function"
          ? rowData.value(actualRow)
          : rowData.value;
      cellContent = <CText cText={value} />;
    }
    return cellContent;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        {showSearch && (
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "200px" }}
          />
        )}
      </div>
      <TableContainer>
        <Table
          aria-label={cTooltipText}
          size={cSmallSize === true ? "small" : "inherit"}
          stickyHeader
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {cHeaderData.map((headData, headIndex) =>
                headData.tooltipText ? (
                  <Tooltip
                    key={headIndex}
                    title={
                      <Typography
                        style={{ whiteSpace: "pre-line" }}
                        sx={{ fontSize: "1em" }}
                      >
                        {headData.tooltipText}
                      </Typography>
                    }
                  >
                    <StyledTableCell
                      key={headIndex}
                      keyIndex={headIndex}
                      sx={{
                        minWidth: "175px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {headData.text}
                    </StyledTableCell>
                  </Tooltip>
                ) : (
                  <StyledTableCell
                    key={headIndex}
                    keyIndex={headIndex}
                    sx={{
                      minWidth: "175px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {headData}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((actualRow, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {cRowData.map((rowData, cellIndex) => (
                      <StyledTableCell
                        component="th"
                        scope="row"
                        key={cellIndex}
                        sx={{
                          color:
                            rowData.status === undefined ||
                            rowData.statusType !== "text"
                              ? "inherit"
                              : actualRow[rowData.status] === 0
                              ? "red"
                              : "green",
                          backgroundColor: getCellBackgroundColor(
                            rowData,
                            actualRow
                          ),
                          textAlign: "left",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {handleCellData(rowData, actualRow, rowIndex)}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <StyledTableCell
                  colSpan={cHeaderData.length}
                  sx={{ textAlign: "center" }}
                >
                  No data found
                </StyledTableCell>
              </TableRow>
            )}
            {cCustomRows &&
              Object.keys(cCustomRows).map((key, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index === Object.keys(cCustomRows).length - 1
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                >
                  <StyledTableCell colSpan={cHeaderData.length - 2} />
                  <StyledTableCell align="right">
                    <b>{key}:</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <span style={{ marginLeft: "2.5em" }}>
                      {cCustomRows[key]}
                    </span>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {cIsPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default MCTTable;
