import { useState } from "react";
import {
    testAllCodes
} from "../model/TestAllCodeModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

const TestAllCodeVM = () => {

    const { handleToastOpen } = useToast();
    const [selectedCode, setSelectedCode] = useState(0);

    const handleCodeChange = (value) => {
        setSelectedCode(value);
    };

    const handleSubmit = async () => {
        try {
            if (selectedCode === 0) {
                handleToastOpen("error", "Please select a code");
            }
            const response = await testAllCodes(selectedCode);
            handleToastOpen("success", response.data.message);
        } catch (error) {
            handleToastOpen("error", error.message);
        }
    };

    return {
        handleCodeChange,
        handleSubmit
    };
};

export default TestAllCodeVM;
