import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CBackDrop from "../../basecomp/components/CBackDrop";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ApproverDashboard = () => {
  const [visitors, setVisitors] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    fetchVisitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVisitors = async () => {
    setLoading(true);
    try {
      const contactInfo = localStorage.getItem("contactInfo");
      console.log("contactInfo", contactInfo);
      if (!contactInfo) {
        navigate("/visitor-mgmt/login");
        return;
      }

      // Pass contactInfo as a query parameter
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/visitors/approver`,
        {
          params: { approver: contactInfo }, // Pass approver in params
        }
      );

      console.log("response.data.data", response.data.data);
      setVisitors(response.data.data);
    } catch (error) {
      console.error("Error fetching visitors:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const filterVisitors = (tabIndex) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    switch (tabIndex) {
      case 0:
        // Today's Visitors
        return visitors.filter((visitor) => {
          if (!visitor.visit_from) return false;
          const visitDate = new Date(visitor.visit_from);
          visitDate.setHours(0, 0, 0, 0);
          return visitDate.getTime() === today.getTime();
        });
      case 1:
        // Upcoming Visitors
        return visitors.filter(
          (visitor) =>
            visitor.visit_from &&
            new Date(visitor.visit_from) > today &&
            visitor.status === "Approved"
        );
      case 2:
        // Pending Requests
        return visitors.filter(
          (visitor) =>
            visitor.status &&
            ["Not Processed", "Resubmitted", "On Hold"].includes(visitor.status)
        );
      case 3:
        // Past Requests
        return visitors.filter((visitor) => {
          if (!visitor.visit_till || !visitor.status) return false;

          const visitTillDate = new Date(visitor.visit_till);
          visitTillDate.setHours(0, 0, 0, 0);
          return (
            visitTillDate < today &&
            ["Approved", "Rejected"].includes(visitor.status)
          );
        });
      default:
        return [];
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleViewDetails = (application) => {
    navigate(`/visitor-mgmt/approve-visitor/${application.id}`, {
      state: { application },
    });
  };

  const theme = createTheme({
    typography: {
      h4: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: isSmallScreen ? "1.5rem" : "2.125rem", // Responsive font size
      },
      body1: {
        fontWeight: "400",
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: isSmallScreen ? "12px" : "16px", // Responsive table text size
            padding: isSmallScreen ? "8px" : "16px", // Adjust padding for small screens
          },
        },
      },
    },
  });

  const VisitorTable = ({ filteredVisitors }) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#19B63E" }}>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Name
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Contact
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Email
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Visit From
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Visit Till
            </TableCell>{" "}
            {/* New column */}
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Status
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredVisitors.length > 0 ? (
            filteredVisitors.map((application) => (
              <TableRow key={application.id}>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.full_name}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.contact_number}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.email}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {new Date(application.visit_from).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {" "}
                  {/* New cell for Visit Till */}
                  {new Date(application.visit_till).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.status}
                </TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleViewDetails(application)}
                        sx={{ fontSize: isSmallScreen ? "0.75rem" : "1rem" }}
                      >
                        View Details
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                {" "}
                {/* Updated colspan for new column */}
                <Typography variant="body1" color="textSecondary">
                  No visitors found for this category.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <ThemeProvider theme={theme}>
      <CBackDrop cOpen={loading} />
      <Grid
        container
        justifyContent="center"
        sx={{
          padding: {
            xs: "1em",
            sm: "2em",
            md: "5em 3em 0.5em 3em",
          },
          flexDirection: isSmallScreen ? "column" : "row", // Responsive layout
        }}
      >
        <Grid item xs={12}>
          <Card
            sx={{
              padding: { xs: "1em", sm: "2em", md: "2em" },
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              marginTop: "3.5em", // Add margin to move it down
            }}
          >
            <Typography
              variant="h4"
              sx={{
                marginBottom: "1em",
                marginTop: {
                  xs: "1em", // Increased top margin for extra small screens
                  sm: "0.5em", // Slightly increased for small screens
                  md: "0.5em", // Kept the same for medium and larger screens
                },
              }}
            >
              VISITOR APPLICATIONS
            </Typography>

            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              centered
              variant={isSmallScreen ? "fullWidth" : "standard"}
              aria-label="Visitor Applications Tabs"
            >
              <Tab
                label="Today's Visitors"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <Tab
                label="Upcoming Visitors"
                id="tab-1"
                aria-controls="tabpanel-1"
              />{" "}
              {/* New Tab */}
              <Tab
                label="Pending Requests"
                id="tab-2"
                aria-controls="tabpanel-2"
              />
              <Tab
                label="Past Requests"
                id="tab-3"
                aria-controls="tabpanel-3"
              />
            </Tabs>

            <TabPanel value={currentTab} index={0}>
              <VisitorTable filteredVisitors={filterVisitors(0)} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <VisitorTable filteredVisitors={filterVisitors(1)} />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <VisitorTable filteredVisitors={filterVisitors(2)} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <VisitorTable filteredVisitors={filterVisitors(3)} />
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ApproverDashboard;
