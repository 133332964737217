import { useState } from "react";
import { downloadExcelByDateRange } from "../model/MatRequestModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { formattedDate } from "../model/MaterialCreationMethods";

const UploadTemplatesVM = () => {

  const { handleToastOpen } = useToast();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  const handleUpload = async (buttonKey) => {
    try {
      const response = await downloadExcelByDateRange(startDate, endDate, buttonKey);
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${buttonKey}_${formattedDate}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  return {
    handleStartDateChange,
    handleEndDateChange,
    handleUpload
  }

}

export default UploadTemplatesVM;
