import React, { useState } from "react";
// import { CDropDown, CButton, CMainCard, CMainGrid, CInput } from "fe_comp_base";
import CInput from "../../../basecomp/components/CInput";
import CDropDown from "../../../basecomp/components/CDropDown";
import CButton from "../../../basecomp/components/CButton";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import CTable from "../../../basecomp/components/CTable";
import AnySQLVM from "../viewmodel/AnySQLVM";


const AnySQLView = () => {
    const {
        categoryTablesJson,
        selectedCategory,
        handleCategoryChange,
        handleTableChange,
        handleQueryChange,
        queryValue,
        handleQueryValueChange,
        handleSubmit,
        listQueries,
        tableActualData,
        tableRowData,
        tableHeadData
    } = AnySQLVM();

    return (
        <CMainGrid item xs={10} sm={8} md={10} marginLeft={6} marginRight={6} marginTop={12} >
            <CMainCard>
                <CMainGrid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CMainGrid item xs={4} sm={4} md={4} lg={4} >
                        <CDropDown
                            cId="dd_c"
                            cHelperText="Choose Table Category"
                            cData={
                                Object.keys(categoryTablesJson)
                            }
                            cFullWidth={true}
                            cOnChange={handleCategoryChange}
                        />
                    </CMainGrid>
                    <CMainGrid item xs={4} sm={4} md={4} lg={4} >
                        <CDropDown
                            cId="dd_t"
                            cHelperText="Choose Table Name"
                            cData={
                                selectedCategory === "" ? [] : Object.keys(categoryTablesJson[selectedCategory].table)
                            }
                            cFullWidth={true}
                            cOnChange={handleTableChange}
                        />
                    </CMainGrid>
                    <CMainGrid item xs={4} sm={4} md={4} lg={4} >
                        <CDropDown
                            cId="dd_q"
                            cHelperText="Choose Query"
                            cData={listQueries}
                            cFullWidth={true}
                            cOnChange={handleQueryChange}
                        />
                    </CMainGrid>
                    <CMainGrid item xs={12} sm={12} md={12}>
                        <CInput
                            cHintText=""
                            cRows={10}
                            cValue={queryValue}
                            cFullWidth={true}
                            cOnChange={handleQueryValueChange}
                        />

                    </CMainGrid>
                    <CMainGrid item xs={12} sm={6} md={1.5} paddingLeft={2} paddingBottom={3}>
                        <CButton
                            cText="Submit"
                            cOnClick={() => {
                                handleSubmit();
                            }}
                        />
                    </CMainGrid>
                    <CTable
                        cHeaderData={tableHeadData}
                        cRowData={tableRowData}
                        cActualData={
                            tableActualData
                        }
                        cIsPagination={true}
                        cSmallSize={false}
                    />
                </CMainGrid>
            </CMainCard>
        </CMainGrid>
    );
};

export default AnySQLView;
