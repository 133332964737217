import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const { axiosGetCallWithToken, axiosPostCallWithToken, axiosPutCallWithToken } =
  AxiosWrapper;

export const addJobbIdCatalogue = async (data) => {
  const response = await axiosPostCallWithToken(
    `/dcatalogue/addJobIdCatalogue`,
    data
  );
  return response;
};

export const updateType = async () => {
  const response = await axiosPutCallWithToken(`/dcatalogue/updateType`);
  return response;
};

export async function getEquipmentForDropdown() {
  const response = await axiosGetCallWithToken(`/dcatalogue/getEquipData`);
  return response;
}

export const getJobCardNo = async () => {
  const response = await axiosGetCallWithToken(`/dcatalogue/getJobCardNo`);
  return response;
};


