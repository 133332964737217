import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Badge,
  Typography,
  Box,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SecurityIcon from "@mui/icons-material/Security";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { useToast } from "../../basecomp/components/provider/ToastProvider";
import axios from "axios";

const IconTabs = ({
  approvers,
  gatekeepers,
  visitors,
  handleViewDetails,
  handleDelete,
  onAddUser,
  setApprovers,
  setGatekeepers,
  setVisitors,
}) => {
  const { handleToastOpen } = useToast();
  const [activeTab, setActiveTab] = useState("Approver");
  const [newUser, setNewUser] = useState({
    full_name: "",
    contact_number: "",
    email: "",
  });
  const [showNewRow, setShowNewRow] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const fetchData = async () => {
    try {
      const [approverRes, gatekeeperRes, visitorRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
          params: { role: "Approver" },
        }),
        axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
          params: { role: "GateKeeper" },
        }),
        axios.get(`${process.env.REACT_APP_BE_URL2}/visitor-mgmt/roles`, {
          params: { role: "Visitor" },
        }),
      ]);

      setApprovers(approverRes.data);
      setGatekeepers(gatekeeperRes.data);
      setVisitors(visitorRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddUser = async (user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/addUser`,
        {
          role: activeTab,
          user,
        }
      );
      if (response.status === 201 && response.data.user) {
        handleToastOpen("success", "User added successfully!");
        onAddUser(response.data.user, activeTab);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        handleToastOpen("error", `${error.response.data.error}`);
      } else {
        handleToastOpen("error", "Failed to add user");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddRow = () => {
    setShowNewRow(true);
  };

  const handleSaveUser = async () => {
    if (!newUser.full_name || !newUser.contact_number || !newUser.email) {
      handleToastOpen("error", "All fields are required");
      return;
    }

    if (!newUser.full_name || !newUser.contact_number || !newUser.email) {
      handleToastOpen("error", "All fields are required");
      return;
    }

    // Validate contact_number
    const contactNumberRegex = /^\d{10}$/;
    console.log("contactNumberRegex", contactNumberRegex);
    if (!contactNumberRegex.test(newUser.contact_number)) {
      handleToastOpen("error", "Contact number must be exactly 10 digits");
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newUser.email)) {
      handleToastOpen("error", "Invalid email format");
      return;
    }

    try {
      await handleAddUser(newUser);
      setNewUser({
        full_name: "",
        contact_number: "",
        email: "",
      });
      setShowNewRow(false);
    } catch (error) {
      handleToastOpen("error", "Failed to add user");
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return ""; // Return an empty string if no date is provided
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // 24-hour format
    }).format(date);
  };

  const renderActiveTab = () => {
    let data = [];
    if (activeTab === "Approver") data = approvers;
    if (activeTab === "Gatekeeper") data = gatekeepers;
    if (activeTab === "Visitor") data = visitors;

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "10px",
          }}
        >
          <Grid container direction="column" spacing={2}>
            {(activeTab === "Approver" || activeTab === "Gatekeeper") && (
              <Grid item>
                <Tooltip title="Add Row" arrow>
                  <IconButton
                    sx={{
                      color: "#28a745",
                      alignSelf: isSmallScreen ? "center" : "flex-start",
                    }}
                    onClick={handleAddRow}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <TableContainer
                sx={{
                  overflowX: "auto",
                  maxWidth: "100vw",
                  WebkitOverflowScrolling: "touch",
                  "-ms-overflow-style": "none",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Table
                  sx={{
                    borderCollapse: "collapse",
                    minWidth: 600,
                    tableLayout: "fixed",
                    width: "100%",
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#19B63E" }}>
                      <TableCell
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1.05rem",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1.05rem",
                        }}
                      >
                        Contact
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1.05rem",
                        }}
                      >
                        Email
                      </TableCell>
                      {activeTab === "Visitor" && (
                        <>
                          <TableCell
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "1.05rem",
                            }}
                          >
                            Check In
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "1.05rem",
                            }}
                          >
                            Check Out
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1.05rem",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell sx={{ textAlign: "center" }}>
                          {user.full_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {user.contact_number}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {user.email}
                        </TableCell>
                        {activeTab === "Visitor" && (
                          <>
                            <TableCell sx={{ textAlign: "center" }}>
                              {formatDateTime(user.check_in)}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {formatDateTime(user.check_out)}
                            </TableCell>
                          </>
                        )}
                        <TableCell sx={{ textAlign: "center" }}>
                          <Tooltip title="Delete" arrow>
                            <IconButton
                              sx={{ color: "#6c757d" }}
                              onClick={() => handleDelete(user.id, activeTab)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {showNewRow && (
                      <TableRow>
                        <TableCell>
                          <TextField
                            variant="standard"
                            name="full_name"
                            value={newUser.full_name}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{
                              input: { padding: "6px 0", fontSize: "0.875rem" },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="standard"
                            name="contact_number"
                            value={newUser.contact_number}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{
                              input: { padding: "6px 0", fontSize: "0.875rem" },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="standard"
                            name="email"
                            value={newUser.email}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{
                              input: { padding: "6px 0", fontSize: "0.875rem" },
                            }}
                          />
                        </TableCell>
                        {activeTab === "Visitor" && (
                          <>
                            <TableCell />
                            <TableCell />
                          </>
                        )}
                        <TableCell>
                          <Tooltip title="Save" arrow>
                            <IconButton
                              sx={{ color: "#28a745" }}
                              onClick={handleSaveUser}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  return (
    <Grid container direction="column" spacing={2}>
      {/* Icons Bar */}
      <Grid item>
        <Box
          sx={{
            backgroundColor: "#e0e0e0",
            padding: "0.3em",
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Tooltip title="Approver" arrow>
                <IconButton
                  onClick={() => setActiveTab("Approver")}
                  color={activeTab === "Approver" ? "primary" : "default"}
                >
                  <Badge badgeContent={approvers.length} color="secondary">
                    <AdminPanelSettingsIcon fontSize="medium" />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Gatekeeper" arrow>
                <IconButton
                  onClick={() => setActiveTab("Gatekeeper")}
                  color={activeTab === "Gatekeeper" ? "primary" : "default"}
                >
                  <Badge badgeContent={gatekeepers.length} color="secondary">
                    <SecurityIcon fontSize="medium" />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Visitor" arrow>
                <IconButton
                  onClick={() => setActiveTab("Visitor")}
                  color={activeTab === "Visitor" ? "primary" : "default"}
                >
                  <Badge badgeContent={visitors.length} color="secondary">
                    <PeopleIcon fontSize="medium" />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Header Bar */}
      <Grid item>
        <Box
          sx={{
            backgroundColor: "#19B63E", // Changed to light green
            color: "#fff",
            padding: "1em 0",
            marginTop: "0.3em",
            textAlign: "center",
            fontWeight: "bold", // Ensuring bold font
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {" "}
            {/* Added bold font here */}
            {activeTab}
          </Typography>
        </Box>
      </Grid>

      {/* Content */}
      <Grid item>{renderActiveTab()}</Grid>
    </Grid>
  );
};

export default IconTabs;
