import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
import { Environment, MaterialImagesUploaded } from "../../assets/Strings";
const environment = process.env.REACT_APP_ENV;
const { axiosGetCallWithToken, axiosPostCallWithToken, axiosDeleteCallWithToken } = AxiosWrapper;

export const ImageUpload = async (
  materialReq,
  isGlobal,
  selectedImages,
  handleToastOpen
) => {
  const formData = new FormData();
  formData.append("material_req", materialReq);
  formData.append("is_global", isGlobal);
  formData.append("environment", environment);
  for (let i = 0; i < selectedImages.length; i++) {
    formData.append("images", selectedImages[i]);
  }
  try {
    if (environment !== "Production") {
      return handleToastOpen("error", Environment);
    }
    const response = await axiosPostCallWithToken(
      `/image_upload/addMaterialImages`,
      formData
    );
    if (response.data.status >= 400) {
      handleToastOpen("error", response.data.message);
      return;
    }
    if (response) {
      await handleToastOpen("success", MaterialImagesUploaded);
      // window.location.reload();
    }
  } catch (error) {
    Logger.printStackTrace("Error fetching data:", error);
    throw error;
  }
};

export const gblNameOrLocalNameCheck = async (email) => {
  try {
    return await axiosGetCallWithToken(
      `/image_upload/getTerminalTypeDetails?email=${email}`
    );
  } catch (error) {
    Logger.printStackTrace(error);
  }
};

export const getLocalAndGlobalMaterialImages = async (materialNo,globalType) => {
  try {
    return await axiosGetCallWithToken(
      `/image_upload/getLocalAndGlobalMaterialImages?materialNo=${materialNo}&globalType=${globalType}`
    );
  } catch (error) {
    Logger.printStackTrace(error);
  }
};


export const deleteLocalAndGlobalMaterialImages = async (materialNo,globalType,imageLink) => {
  try {
    return await axiosDeleteCallWithToken(
      `/image_upload/deleteLocalAndGlobalMaterialImagesRoute?materialReq=${materialNo}&globalType=${globalType}&imageLink=${imageLink}`
    );
  } catch (error) {
    Logger.printStackTrace(error);
  }
};