import React from "react";
import ModifyUserVM from "../viewmodel/ModifyUserVM";
// import { CButton, CMainGrid } from "fe_comp_base"; 
import CInput from "../../../basecomp/components/CInput";
import CDropDown from "../../../basecomp/components/CDropDown";
import CButton from "../../../basecomp/components/CButton";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import { UserKeys } from "../../assets/VarConstants";


const ModifyUserView = () => {
    const {
        userTypes,
        userTerminals,
        user,
        handleUserChange,
        handleSubmit,
    } = ModifyUserVM();

    return (
        <CMainGrid sx={{ marginTop: '6em', marginBottom: '3em', marginLeft: '1.5em', marginRight: '1.5em' }}>
            <CMainGrid container alignItems="center" spacing={2} marginTop={3}>

                <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <CInput
                        cType="email"
                        cHintText="Enter email"
                        cFullWidth={true}
                        cOnChange={handleUserChange(UserKeys.USER_EMAIL, false)}
                        cValue={user[UserKeys.USER_EMAIL]}
                        cIsRequired="true"
                    />
                </CMainGrid>
                <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <CInput
                        cHintText="Enter user name"
                        cFullWidth={true}
                        cOnChange={handleUserChange(UserKeys.USER_NAME, false)}
                        cValue={user[UserKeys.USER_NAME]}
                        cIsRequired="true"
                    />
                </CMainGrid>
                <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <CDropDown
                        cData={userTypes}
                        cIsOptionName={"role"}
                        cHelperText="Select user type"
                        cOnChange={handleUserChange(UserKeys.USER_ROLE_ID, true)}
                        cValue={user.selectedUserType}
                        cIsRequired="true"
                    />
                </CMainGrid>
                <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <CDropDown
                        cData={userTerminals}
                        cIsOptionName={"name"}
                        cHelperText="Select terminal"
                        cOnChange={handleUserChange(UserKeys.TERMINAL_ID, true)}
                        cValue={user.selectedUserTerminal}
                        cIsRequired="true"
                    />
                </CMainGrid>
                <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <CDropDown
                        cData={["0", "1"]}
                        cHelperText="Select user status"
                        cOnChange={handleUserChange(UserKeys.USER_STATUS, false)}
                        cIsRequired="true"
                    />
                </CMainGrid>

            </CMainGrid>

            <CMainGrid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <CButton
                    cText="Save"
                    cTooltipText="Save to Database"
                    cOnClick={handleSubmit}
                />
            </CMainGrid>


        </CMainGrid>
    );
};

export default ModifyUserView;
