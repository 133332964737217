import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import LoginVM from "../viewmodel/LoginVM";
import CInput from "../../components/CInput";

const LoginView = ({ cLogo, cBgImg, handleToastOpen }) => {
  const { email, setEmail, handleLogin,loading } = LoginVM({ handleToastOpen });

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${cBgImg})`,
        backgroundSize: "cover"
      }}
    >
      <Card
        style={{
          padding: "4em",
          maxWidth: 320,
          minWidth: 200,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "9em",
        }}
      >
        <img
          src={cLogo}
          alt="Company Logo"
          style={{
            width: "100%",
            height: "50%",
            marginBottom: "4em"
          }}
        />

        <CInput
          cId="login_email"
          cHintText="Email"
          cOnChange={setEmail}
          cIsRequired="true"
          cFullWidth="true"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            borderRadius: "0.5em",
            marginTop: "1.5em",
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          Login
        </Button>

      </Card>
    </div>
  );
};

export default LoginView;
