import { useState, useEffect } from "react";
import {
  fetchDataAll,
  fetchDropdownData,
  getEquipmentForDropdown,
} from "../model/HistoricalJobModel";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { JobIdKeys } from "../../../assets/VarConstants";

const HistoricalJobVM = () => {
  const [dropdownData, setDropdownData] = useState([]); //To get options in all dropdown
  const [equipmentdropdownData, setEquipmentDropdownData] = useState([]); //To get options in all dropdown
  const [selectedJobID, setSelectedJobID] = useState(null); //To store selected Job Card No/Id from Dropdown
  const [selectedJobType, setSelectedJobType] = useState(null); //To store selected Job Type from Dropdown
  const [selectedCreatedBy, setSelectedCreatedBy] = useState(null); //To store selected Created By from Dropdown
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null); //To store selected Equipment Type from Dropdown
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null); //To store selected Equipment Id from Dropdown
  const [selectedJobDesc, setSelectedJobDesc] = useState(""); //To store selected Job Directive from Textfield
  const [tableData, setTableData] = useState([]); //To store table data
  const [showTable, setShowTable] = useState(false); //To hide and show table data
  const [startDate, setStartDate] = useState(""); //To store selected Start Date from Date picker
  const [finishDate, setFinishDate] = useState(""); //To store selected Finish Date from Date picker
  const [page, setPage] = useState(0); //To set new page in pagaination
  const [rowsPerPage, setRowsPerPage] = useState(5); //To set 5 row in one page
  const [searchValue, setSearchValue] = useState(""); //To search any data in table
  const [isLoading, setIsLoading] = useState(false);
  const { handleToastOpen } = useToast();

  // Historical Job :  Create the formatted date and time string
  const formatDateTime = (value) => {
    const date = new Date(value);
    return date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // Historical Job :  To get selected Start Date from Date picker
  const handleFromChange = (value) => {
    const formattedDate = formatDateTime(value);
    setStartDate(formattedDate);
  };

  // Historical Job : To get selected Finish Date from Date picker
  const handleToChange = (value) => {
    const formattedDate = formatDateTime(value);
    setFinishDate(formattedDate);
  };

  // Historical Job : To get selected Job Card No/ID from Dropdown
  const handleJobIdChange = (value) => {
    setSelectedJobID(value);
  };

  // Historical Job : To get selected Job Type from Dropdown
  const handleJobTypeChange = (value) => {
    setSelectedJobType(value);
  };

  // Historical Job : To get selected Created By from Dropdown
  const handleCreatedByChange = (value) => {
    setSelectedCreatedBy(value);
  };

  // Historical Job : To get selected Equipment Type from Dropdown
  const handleEquipmentTypeChange = (value) => {
    setSelectedEquipmentType(value);
  };

  // Historical Job : To get selected Equipment Id from Dropdown
  const handleEquipmentIdChange = (value) => {
    setSelectedEquipmentId(value);
  };

  // Historical Job : To get Input Job Directive from TextField
  const handleJobDirectiveChange = (value) => {
    setSelectedJobDesc(value);
  };

  // Historical Job : Get all data from job_id_catalogue_table by terminal_id && To get values in dropdown in historical jobs page
  const fetchData = async () => {
    const dropdownData = await fetchDropdownData();
    setDropdownData(dropdownData);
  };

  //Historical Job : Get unique equipment_id and equipment_number for shopping basket dropdown
  const fetchEquipmentDropdownData = async () => {
    try {
      const response = await getEquipmentForDropdown();
      setEquipmentDropdownData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Historical Job : Filter options for Equipment ID based on selected Equipment Type
  const filteredEquipmentIDs = equipmentdropdownData
    .filter(
      (item) =>
        !selectedEquipmentType || item.equipment_type === selectedEquipmentType
    )
    .map((item) => item.equipment_number);

  // Historical Job : Filter options for Equipment Type based on selected Equipment ID
  const filteredEquipmentTypes = equipmentdropdownData
    .filter(
      (item) =>
        !selectedEquipmentId || item.equipment_number === selectedEquipmentId
    )
    .map((item) => item.equipment_type);

  useEffect(() => {
    fetchData();
    fetchEquipmentDropdownData();
  }, []);

  // Historical Job :  Filter dropdown options based on selected values
  const handleSearchClick = async () => {

    setIsLoading(true);
    const filterStartDate = startDate
      ? new Date(startDate).toISOString().slice(0, 10)
      : "";
    const filterFinishDate = finishDate
      ? new Date(finishDate).toISOString().slice(0, 10)
      : "";

    const currentDate = new Date();

    if (
      (filterStartDate !== "" && new Date(filterStartDate) > currentDate) ||
      (filterFinishDate !== "" && new Date(filterFinishDate) > currentDate) ||
      (filterStartDate !== "" &&
        filterFinishDate !== "" &&
        new Date(filterStartDate) > new Date(filterFinishDate)) ||
      (filterStartDate !== "" && filterFinishDate === "") ||
      (filterStartDate === "" && filterFinishDate !== "")
    ) {
      setIsLoading(false);
      handleToastOpen("error", "Invalid date range!");
      setShowTable(false);
      return;
    }

    if (
      selectedJobID === null &&
      selectedEquipmentType === null &&
      selectedJobType === null &&
      selectedEquipmentId === null &&
      selectedCreatedBy === null &&
      selectedJobDesc.trim() === "" &&
      startDate === "" &&
      finishDate === ""
    ) {
      setTableData([]);
      setShowTable(false);
      setIsLoading(false);
      handleToastOpen("error", "Please select any filter");
      return;
    }

    // Fetch data on search click
    try {
      const historicalData = await fetchDataAll(
        selectedJobID,
        selectedEquipmentType,
        selectedJobType,
        selectedEquipmentId,
        selectedCreatedBy,
        selectedJobDesc,
        startDate,
        finishDate
      );

      if (!historicalData) {
        setTableData([]);
        setShowTable(false);
        setIsLoading(false);
        handleToastOpen("error", "Data not found");
        return;
      }

      // Function to replace "NULL" with an empty string
      function replaceNulls(data) {
        return data.map(item => {
          let newItem = { ...item };
          for (let key in newItem) {
            if (newItem[key] === "NULL") {
              newItem[key] = "";
            }
          }
          return newItem;
        });
      }

      // Transform historicalData
      let updatedHistoricalData = replaceNulls(historicalData);

      setTableData(updatedHistoricalData);
      setShowTable(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching historical data:", error);
      setShowTable(false);
    }
  };

  // Historical Job : Reset Filters and hide table
  const handleResetClick = () => {
    // Reload the page
    window.location.reload();
  };

  // Historical Job : Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Historical Job : page number
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const infoHeadData = [
    "Job ID",
    "Reference No",
    "Job Type",
    "Job Directive",
    "Equipment Type",
    "Equipment ID",
    "Created By",
    "Date/Time Created",
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: JobIdKeys.JOB_ID,
      type: "L",
      tooltipText: (actualRow) =>
        actualRow.id ? "Click here to see the details" : "",
      actionV: (actualRow) => {
        if (actualRow.id) {
          window.open(
            `/d-catalogue/historical-job-detail?job_id=${actualRow.id}`,
            "_blank"
          );
        }
      },
    },
    {
      value: JobIdKeys.REFERENCE_NO,
      type: "T",
    },
    {
      value: JobIdKeys.JOB_TYPE,
      type: "T",
    },
    {
      value: JobIdKeys.JOB_DIRECTIVE,
      type: "T",
    },
    {
      value: JobIdKeys.EQUIPMENT_TYPE,
      type: "T",
    },

    {
      value: JobIdKeys.EQUIPMENT_ID,
      type: "T",
    },
    {
      value: JobIdKeys.USER_NAME,
      type: "T",
    },
    {
      value: JobIdKeys.CREATED_AT,
      type: "T",
    },
  ]);

  const searchItems = (searchValue) => {
    setSearchValue(searchValue);
  };

  const onSearchClick = () => {
    const searchArr = [
      JobIdKeys.JOB_ID,
      JobIdKeys.REFERENCE_NO,
      JobIdKeys.JOB_TYPE,
      JobIdKeys.JOB_DIRECTIVE,
      JobIdKeys.EQUIPMENT_TYPE,
      JobIdKeys.EQUIPMENT_ID,
      JobIdKeys.USER_NAME,
      JobIdKeys.CREATED_AT,
    ];
    let filteredData = [...tableData];
    const filteredResult = filteredData.filter((rowVal) => {
      return searchArr.some((key) => {
        let value = rowVal[key];
        if (value !== null && typeof value === "string") {
          value = value.toLowerCase();
          return value.includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    if (filteredResult.length > 0) {
      setTableData(filteredResult);
    } else {
      setTableData(filteredData);
    }
  };

  return {
    dropdownData,
    tableData,
    showTable,
    page,
    rowsPerPage,
    handleSearchClick,
    handleResetClick,
    handleFromChange,
    handleToChange,
    handleJobIdChange,
    handleJobTypeChange,
    handleCreatedByChange,
    handleEquipmentTypeChange,
    handleEquipmentIdChange,
    handleJobDirectiveChange,
    handleChangePage,
    handleChangeRowsPerPage,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    infoHeadData,
    infoRowData,
    setTableData,
    searchItems,
    onSearchClick,
    isLoading
  };
};

export default HistoricalJobVM;
