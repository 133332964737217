import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, verificationUser, verifyLogin } from "../model/LoginModel";
import Logger from "../../base/Logger";
import UserUtils from "../UserUtils";
import AxiosWrapper from "../../base/AxiosWrapper";
import { useToast } from "../../components/provider/ToastProvider";

const { handleLoggedIn, getUserName } = UserUtils;
const storedEmail = sessionStorage.getItem("email");
const currentUrl = window.location.href;
const loginParam = new URL(currentUrl).searchParams.get("login");
const { getBEUrl } = AxiosWrapper;
if (loginParam === "true") {
  const userName = getUserName();
  if (userName === null || userName === "") {
    const resultPromise = loginUser(storedEmail);
    resultPromise
      .then((result) => {
        handleLoggedIn(result.data);

        const event = new CustomEvent("cStorage", {});
        window.dispatchEvent(event);
        const sessionItem = sessionStorage.getItem("logingsoneurl");
        if (sessionItem) {
          const url = sessionItem;
          const currentUrl = window.location.origin;
          window.location.href = currentUrl + url;
        }
      })
      .catch((error) => {});
  } else {
    try {
      await verifyLogin();
    } catch (error) {
      Logger.e("Something went wrong in verification");
    }
  }
}

const LoginVM = () => {
  const { handleToastOpen } = useToast();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const verifyAndRedirect = async () => {
    const userName = getUserName();
    if (userName !== null && userName !== "") {
      try {
        await verifyLogin();
        setLoading(false)
        navigate("/services");
      } catch (error) {
        setLoading(false)
        Logger.e("Something went wrong in verification");
      }
    }
  };
  verifyAndRedirect();

  const handleLogin = async () => {
    setLoading(true)

    try {
      const msAuthClick = async (email) => {
        try {
          const redirectUrl = `${getBEUrl()}/users/auth/microsoft?feURL=${encodeURIComponent(
            currentUrl
          )}&email=${encodeURIComponent(email)}`;
          window.location.href = redirectUrl;
        } catch (error) {
          Logger.e("Something went wrong in authentication");
          setLoading(false)
        }
      };

      await verificationUser(email);
      sessionStorage.setItem("email", email);
      await msAuthClick(email);
    } catch (error) {
      handleToastOpen("error", error.message);
      setLoading(false)
    }
  };

  return {
    email,
    setEmail,
    handleLogin,
    loading
  };
};

export default LoginVM;
