import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

const CCheckButton = ({
  cId,
  cLabel,
  cChecked,
  cHandleCheck,
  cHandleUnCheck,
  cDisabled,
  cIsSingle,
  cIsSmall,
  cTooltipText,
  dcat, // Added dcat prop
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked !== cChecked) {
      setChecked(cChecked);
    }
  }, [cChecked]);

  const onHandleChange = (event) => {
    setChecked(event.target.checked);
    if (cHandleCheck !== undefined && event.target.checked === true) {
      cHandleCheck();
    } else if (cHandleUnCheck !== undefined && event.target.checked === false) {
      cHandleUnCheck();
    }
  };

  // Determine font size based on the dcat prop
  const fontSize = dcat ? "0.75rem" : "1rem"; // Adjust sizes as needed

  const controlProps = {
    checked,
    onChange: onHandleChange,
    disabled: cDisabled,
    color: "secondary",
    id: cId,
    size: cIsSmall ? "small" : "medium",
  };

  const labelStyle = {
    fontSize,
    marginLeft: "0.5em", // Adjust as needed
  };

  if (cIsSingle === true) {
    return (
      <FormControlLabel
        control={<Radio {...controlProps} />}
        label={<Typography style={labelStyle}>{cLabel}</Typography>}
        aria-label={cTooltipText}
      />
    );
  } else {
    return (
      <FormControlLabel
        control={<Checkbox {...controlProps} />}
        label={<Typography style={labelStyle}>{cLabel}</Typography>}
        aria-label={cTooltipText}
      />
    );
  }
};

export default CCheckButton;
