import React from "react";
// import { CDropDown, CButton, CMainCard, CMainGrid } from "fe_comp_base";
import CDropDown from "../../../basecomp/components/CDropDown";
import CButton from "../../../basecomp/components/CButton";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";

import TestAllCodeVM from "../viewmodel/TestAllCodeVM";


const TestAllCodeView = () => {
  const { handleSubmit, handleCodeChange } = TestAllCodeVM();

  return (
    <CMainGrid item xs={10} sm={8} md={10} marginLeft={6} marginRight={6} marginTop={12} >
      <CMainCard>
        <CMainGrid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CMainGrid item xs={12} sm={12} md={12} lg={12} >
            <CDropDown
              cId="dd_1"
              cHelperText="Choose Status Code"
              cData={[
                200,
                201,
                202,
                204,
                400,
                401,
                403,
                404,
                405,
                409,
                500,
                501,
                502,
                503,
                504
              ]}
              cFullWidth={true}
              cOnChange={handleCodeChange}
            />
          </CMainGrid>
          <CMainGrid item xs={12} sm={6} md={1.5} paddingLeft={2} paddingBottom={3}>
            <CButton
              cText="Submit"
              cOnClick={() => {
                handleSubmit();
              }}
            />
          </CMainGrid>
        </CMainGrid>
      </CMainCard>
    </CMainGrid>
  );
};

export default TestAllCodeView;
