import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home"; // Add this import
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Container,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableRow,
  Paper,
  Button,
  IconButton, // Add this import
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../basecomp/components/provider/ToastProvider";
import CBackDrop from "../../basecomp/components/CBackDrop";

const VisitorDetails = () => {
  const { handleToastOpen } = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const [visitorData, setVisitorData] = useState(null);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [openPhotoPreview, setOpenPhotoPreview] = useState(false);
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [showActionMessage, setShowActionMessage] = useState(false);

  const isFinalStatus =
    status && ["Approved", "Rejected", "On Hold"].includes(status);
  const showButtons = status && !["Approved", "Rejected"].includes(status);

  const handleHomeClick = () => {
    navigate("/visitor-mgmt/approver-dashboard");
  };

  useEffect(() => {
    const userRole = localStorage.getItem("userRole");

    if (userRole !== "Approver") {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/visitor-mgmt/login");
      return;
    }

    const fetchVisitorData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/visitor/${id}`
        );
        setVisitorData(response.data);
        setStatus(response.data.status);
      } catch (error) {
        console.error("Error fetching visitor data:", error);
        handleToastOpen("error", "Failed to fetch visitor data.");
      } finally {
        setLoading(false);
      }
    };

    fetchVisitorData();
  }, [id, navigate, handleToastOpen]);

  const handleOpenPhotoPreview = () => {
    setPhotoPreview(`data:image/jpeg;base64,${visitorData.photo}`);
    setOpenPhotoPreview(true);
  };

  const handleClosePhotoPreview = () => {
    setOpenPhotoPreview(false);
    setPhotoPreview(null);
  };

  const handleOpenDocumentPreview = () => {
    setDocumentPreview(`data:image/jpeg;base64,${visitorData.document}`);
    setOpenDocumentPreview(true);
  };

  const handleCloseDocumentPreview = () => {
    setOpenDocumentPreview(false);
    setDocumentPreview(null);
  };

  const updateStatus = async (newStatus) => {
    setDisabled(true);
    try {
      const fe_url = window.location.origin;
      await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/updateVisitorStatusAndProcess/${id}?status=${newStatus}&fe_url=${fe_url}`,
        { responseType: "blob" }
      );

      setStatus(newStatus);
      setShowActionMessage(true);

      if (newStatus === "Approved") {
        handleToastOpen(
          "success",
          "Visitor request has been approved, and the details have been emailed and sent via SMS."
        );
      } else if (newStatus === "Rejected") {
        handleToastOpen("success", "Visitor request has been rejected");
      } else if (newStatus === "On Hold") {
        handleToastOpen("success", "Visitor request is now on hold");
      }
    } catch (error) {
      console.error(`Error updating status to ${newStatus}:`, error);
      handleToastOpen("error", `Error updating status to ${newStatus}.`);
    } finally {
      setDisabled(false);
    }
  };

  const handleApprove = () => updateStatus("Approved");
  const handleReject = () => updateStatus("Rejected");
  const handleOnHold = () => updateStatus("On Hold");

  if (loading) return <CBackDrop cOpen={loading} />;

  return (
    <Box sx={{ mt: 5 }}>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <Card
          sx={{
            padding: "2em",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton
              color="primary"
              onClick={handleHomeClick}
              sx={{
                backgroundColor: "#f5f5f5",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              <HomeIcon />
            </IconButton>
          </Box>

          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold", marginBottom: "1em", color: "#333" }}
          >
            VISITOR APPROVAL REQUEST
          </Typography>

          {showActionMessage ? (
            <Typography
              variant="body1"
              align="center"
              sx={{ color: "#666", fontWeight: "500", marginBottom: "1em" }}
            >
              This visitor request is <strong>{status}</strong>.
              {status !== "On Hold" && " No further actions are possible."}
            </Typography>
          ) : (
            <>
              {isFinalStatus && (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    color: "#666",
                    fontWeight: "500",
                    marginBottom: "1em",
                  }}
                >
                  Current Status: <strong>{status}</strong>
                </Typography>
              )}
              {/* Visitor Information Table */}
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", marginTop: "1em" }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Visitor Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {visitorData.full_name}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Visitor Mobile
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {visitorData.contact_number}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Visitor Email
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {visitorData.email}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Visit From
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {formatDate(visitorData.visit_from)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Visit Till
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {formatDate(visitorData.visit_till)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Meeting Purpose
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {visitorData.purpose}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Whom to Visit
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">
                          {visitorData.whom_to_visit}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Photo
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1em",
                          }}
                        >
                          {visitorData.photo ? (
                            <img
                              src={`data:image/jpeg;base64,${visitorData.photo}`}
                              alt="Visitor Photo"
                              onClick={handleOpenPhotoPreview}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "";
                                handleToastOpen(
                                  "error",
                                  "Failed to load visitor photo."
                                );
                              }}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "8px",
                                border: "1px solid #ccc",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No Photo Available
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#555" }}
                        >
                          Document
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1em",
                          }}
                        >
                          {visitorData.document ? (
                            <img
                              src={`data:image/jpeg;base64,${visitorData.document}`}
                              alt="Visitor Document"
                              onClick={handleOpenDocumentPreview}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "";
                                handleToastOpen(
                                  "error",
                                  "Failed to load visitor document."
                                );
                              }}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "8px",
                                border: "1px solid #ccc",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No Document Available
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Buttons */}
              {showButtons && (
                <Box sx={{ textAlign: "center", marginTop: "1.5em" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApprove}
                    disabled={disabled}
                    sx={{ margin: "0.5em" }}
                  >
                    Approve
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleReject}
                    disabled={disabled}
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      margin: "0.5em",
                      "&:hover": {
                        backgroundColor: "darkred",
                      },
                    }}
                  >
                    Reject
                  </Button>

                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleOnHold}
                    disabled={disabled}
                    sx={{ margin: "0.5em" }}
                  >
                    On Hold
                  </Button>
                </Box>
              )}
            </>
          )}
        </Card>
      </Container>
      {/* Photo Preview Dialog */}
      <Dialog open={openPhotoPreview} onClose={handleClosePhotoPreview}>
        <DialogTitle>Visitor Photo Preview</DialogTitle>
        <DialogContent>
          {photoPreview ? (
            <img
              src={photoPreview}
              alt="Visitor Photo Preview"
              style={{ width: "100%" }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              Photo not available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhotoPreview}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* Document Preview Dialog */}
      <Dialog open={openDocumentPreview} onClose={handleCloseDocumentPreview}>
        <DialogTitle>Visitor Document Preview</DialogTitle>
        <DialogContent>
          {documentPreview ? (
            <img
              src={documentPreview}
              alt="Visitor Document Preview"
              style={{ width: "100%" }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              Document not available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDocumentPreview}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export default VisitorDetails;
