import React, { useState } from "react";
import {
  Button,
  Container,
  Card,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);

  const handleAgree = () => {
    navigate("/visitor-mgmt/visitor-form");
  };

  const handleCheckboxChange = (event) => {
    setAgreed(event.target.checked);
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <Card
          sx={{
            p: { xs: 2, sm: 4 },
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Terms and Conditions
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "1.2rem", mb: 2 }}>
            Please read and agree to the following terms and conditions and
            safety instructions to proceed.
          </Typography>
          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: 2,
              p: { xs: 2, sm: 3 },
              mb: 2,
              bgcolor: "#fafafa",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Introduction
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "1.1rem", mb: 2 }}>
              Welcome to our Visitor Management System. By proceeding, you agree
              to abide by the following terms and conditions as well as our
              safety guidelines. Please read carefully before continuing.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Terms of Use
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "1.1rem", mb: 2 }}>
              1. You must comply with all safety instructions and guidelines
              provided by our staff.
              <br />
              2. You agree to provide accurate and truthful information during
              the registration process.
              <br />
              3. Unauthorized access to restricted areas is prohibited.
              <br />
              4. All visitors must wear their visitor badges at all times while
              on the premises.
              <br />
              5. Photography and videography are not allowed unless explicitly
              permitted.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Safety Instructions
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "1.1rem", mb: 2 }}>
              1. In case of an emergency, please follow the instructions of our
              staff and proceed to the nearest exit.
              <br />
              2. Do not touch or interfere with any equipment or machinery.
              <br />
              3. Report any suspicious activity to our security personnel
              immediately.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Privacy Policy
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "1.1rem" }}>
              We are committed to protecting your privacy. The information you
              provide will only be used for visitor management purposes and will
              not be shared with third parties without your consent.
            </Typography>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={handleCheckboxChange}
                name="termsCheckbox"
                color="primary"
              />
            }
            label="I have read and agree to the terms and conditions"
            sx={{ fontSize: "1.1rem", mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAgree}
            disabled={!agreed}
            sx={{ fontSize: "1.2rem", py: 1 }}
          >
            I Agree
          </Button>
        </Card>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
