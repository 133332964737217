import React, { useState } from "react";
import PermissionVM from "../viewmodel/PermissionVM";
// import { CMainGrid, CButton } from "fe_comp_base";
import CButton from "../../../basecomp/components/CButton";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import CDropDown from "../../../basecomp/components/CDropDown";

import { Card } from "@mui/material";
import CCheckHeader from "../components/CCheckHeader";

const PermissionView = () => {
    const {
        dataListByType,
        dataDisplay,
        dataGroup,
        checksList,
        onCheckChange,
        handlePermTypeChange,
        handleValueChange,
        handleSubmitClick
    } = PermissionVM();

    return (
        <CMainGrid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            sx={{ margin: "5em 0.5em 0.5em 0.5em" }}>
            <Card sx={{ padding: "2.5em" }}>
                <CMainGrid
                    container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <CMainGrid
                        container
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <CMainGrid item xs={6} sm={6} md={6} lg={6}>
                            <CDropDown
                                cId="dd_user_perm_type"
                                cHelperText="Choose Permission Type"
                                cData={[
                                    "User",
                                    "UserType",
                                    "UserTerminal",
                                ]}
                                cOnChange={handlePermTypeChange}
                            />
                        </CMainGrid>
                        <CMainGrid item xs={6} sm={6} md={6} lg={6}>
                            <CDropDown
                                cId="dd_list"
                                cHelperText="Choose"
                                cData={dataListByType}
                                cIsOptionName={"display_val"}
                                cOnChange={handleValueChange}
                            />
                        </CMainGrid>
                    </CMainGrid>
                    <CCheckHeader
                        cDataDisplay={dataDisplay}
                        cDataGroup={dataGroup}
                        cChecks={checksList}
                        cOnChecked={onCheckChange}
                    />
                    <CMainGrid
                        container
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <CButton
                            cText="Submit"
                            cTooltipText="Submit the selected permissions"
                            cOnClick={handleSubmitClick}
                            cFullWidth={true}
                        />
                    </CMainGrid>
                </CMainGrid>
            </Card>
        </CMainGrid>
    );
};

export default PermissionView;
