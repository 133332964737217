import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";

const { axiosGetCall, axiosGetCallHeader } = AxiosWrapper;
// import Logger from "../../../basecomp/base/Logger";

const fetchChargesTypeData = async () => {
  const response = await axiosGetCall(`/tariff/getChargesType`);
  return response;
};

const fetchChargesDetailData = async (chargeId) => {
  const response = await axiosGetCall(
    `/tariff/getChargesDetails?` + `chargeId=${chargeId}`
  );

  return response;
};

const fetchChargesTable = async (selected) => {
  const response = await axiosGetCall(
    `/tariff/getChargesTable?` +
      `selectedData=${encodeURIComponent(JSON.stringify(selected))}`
  );
  return response;
};

export const fetchPrintPdfData = async (tariffData, selected) => {
  const tariffPDFData = encodeURIComponent(JSON.stringify(tariffData));
  const selectedPDFData = encodeURIComponent(JSON.stringify(selected));

  const response = await axiosGetCallHeader(
    `/tariff/pdf?tariffPDFData=${tariffPDFData}&selectedPDFData=${selectedPDFData}`,
    "application/pdf"
  );
  return response;
};

// export const fetchPrintPdfData = async (tariffData, selected) => {
//   const tariffPDFData = encodeURIComponent(JSON.stringify(tariffData));
//   const selectedPDFData = encodeURIComponent(JSON.stringify(selected));
//   const response = await axiosGetCallHeaderWithToken(
//     `/tariff/pdf?tariffPDFData=${tariffPDFData}&selectedPDFData=${selectedPDFData}`,
//     "application/pdf"
//   );
//   return response;
// };

export { fetchChargesTypeData, fetchChargesDetailData, fetchChargesTable };
