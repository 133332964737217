import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const {
  axiosGetCallWithToken,
  axiosGetCallHeaderWithToken
} = AxiosWrapper;

export const getJobDetail = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getJobDetails`
  );
  return response.data.data;
};

export const getPermissionId = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getPermissionId`
  );
  return response.data;
};

export const pdfDownload = async (
  jobId,
  equipmentId,
  jobDirective,
  jobType,
  section,
  resources,
  workDesc,
  externalRef,
  startDate,
  finishDate,
  type
) => {
  const response = await axiosGetCallHeaderWithToken(
    `/dcatalogue/getPdf?jobId=${jobId}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&jobType=${jobType}&section=${section}&resources=${resources}&workDesc=${workDesc}&externalRef=${externalRef}&startDate=${startDate}&finishDate=${finishDate}&type=${type}`,
    "application/pdf"
  );
  return response;
};

export const nonEamsPdfDownload = async (
  jobId,
  equipmentType,
  equipmentId,
  jobDirective,
  jobType,
  section,
  resources,
  workDesc,
  externalRef,
  startDate,
  finishDate,
  type
) => {
  const response = await axiosGetCallHeaderWithToken(
    `/dcatalogue/getPdf?jobId=${jobId}&equipmentType=${equipmentType}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&jobType=${jobType}&section=${section}&resources=${resources}&workDesc=${workDesc}&externalRef=${externalRef}&startDate=${startDate}&finishDate=${finishDate}&type=${type}`
  );
  return response;
};
