import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CText from "../../../../basecomp/components/CText";
import CBackDrop from "../../../../basecomp/components/CBackDrop";
import CButton from "../../../../basecomp/components/CButton";
import CTable from "../../../../basecomp/components/CTable";
import HistoricalJobDetailsVM from "../viewmodel/HistoricalJobDetailsVM";
import CDialog from "../../../../basecomp/components/CDialog";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const HistoricalJobDetails = ({ value, data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const jobIdFromUrl = urlParams.get("job_id");
  const id = jobIdFromUrl || "";

  const {
    jobDetails,
    modalOpen,
    onButtonClick,
    handleSaveData,
    handleAddToCart,
    quantity,
    spDetails,
    setOpenCarousel,
    openCarousel,
    isLoading,
    infoHeadData,
    infoRowData,
    carouselImages,
    clearSelection,
    handleChange,
  } = HistoricalJobDetailsVM(id);

  return (
    <>
     <CBackDrop cOpen={isLoading} />
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Card sx={{ padding: "2em" }}>
              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={12} md={2.3}>
                  <CText
                    cText={
                      <>
                        <strong>Job Card No :</strong> {jobDetails?.id}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.2}>
                  <CText
                    cText={
                      <>
                        <strong>Equipment Type : </strong>{" "}
                        {jobDetails?.job_directive}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <CText
                    cText={
                      <>
                        <strong>Job Directive : </strong>{" "}
                        {jobDetails?.equipment_type}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                  <CText
                    cText={
                      <>
                        <strong>Section : </strong>{" "}
                        {jobDetails?.section ? jobDetails?.section : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <CText
                    cText={
                      <>
                        <strong>Start Date/Time : </strong>{" "}
                        {jobDetails?.start_date_time
                          ? jobDetails?.start_date_time
                          : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={6} md={2.3}>
                  <CText
                    cText={
                      <>
                        <strong>Reference No : </strong>{" "}
                        {jobDetails?.reference_no
                          ? jobDetails?.reference_no
                          : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3.2}>
                  <CText
                    cText={
                      <>
                        <strong>Job Type : </strong>{" "}
                        {jobDetails?.job_type ? jobDetails?.job_type : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <CText
                    cText={
                      <>
                        <strong>Equipment ID : </strong>{" "}
                        {jobDetails?.equipment_id}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.5}>
                  <CText
                    cText={
                      <>
                        <strong>Resources : </strong> {jobDetails?.resources}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CText
                    cText={
                      <>
                        <strong>Finish Date/Time : </strong>{" "}
                        {jobDetails?.finish_date_time
                          ? jobDetails?.finish_date_time
                          : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <CText
                    cText={
                      <>
                        <strong>Work Description : </strong>{" "}
                        {jobDetails?.detail ? jobDetails?.detail : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container paddingBottom={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={11.9}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.5em",
                  }}>
                  <div>
                    <CButton
                      cId="add_to_cart"
                      cIsMain={true}
                      cSrc={<ShoppingCartIcon />}
                      cTooltipText="Add all materials to basket"
                      cOnClick={handleAddToCart}
                      cDisabled={spDetails.length === 0}
                    />
                  </div>

                  <div>
                    <CButton
                      cId="download_pdf"
                      cIsMain={true}
                      cSrc={<CloudDownloadIcon />}
                      cTooltipText="Download Pdf"
                      cOnClick={onButtonClick}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}> 
                  <CTable
                    cHeaderData={infoHeadData}
                    cRowData={infoRowData}
                    cActualData={spDetails}
                    cIsPagination={true}
                    cSmallSize={true}
                    showSearch={true}
                  />
              </Grid>

              {openCarousel && (
                <CCarousel
                  cOpen={openCarousel}
                  cCloseClicked={() => setOpenCarousel(false)}
                  cImages={carouselImages} // Ensure this matches with the prop name in CCarousel
                />
              )}

              <CDialog
                cOpen={modalOpen}
                cOnSubmitClick={() => handleSaveData(data, quantity)}
                cHandleClose={clearSelection}
                cTitle=<ShoppingCartOutlinedIcon />
                cDialogContent={
                  <>
                    <Typography
                      style={{
                        color: "#1976d2",
                        marginBottom: "1em",
                      }}>
                      QTY
                    </Typography>
                    <TextField
                      id="standard-basic"
                      fullWidth
                      value={quantity}
                      onChange={handleChange}
                      variant="standard"
                    />
                  </>
                }
              />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HistoricalJobDetails;
