import React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const SmallSquareButton = styled(Button)(({ theme }) => ({
  width: "50px", // Adjust size as needed
  height: "35px", // Adjust size as needed
  padding: 0,
  borderRadius: "4px", // Ensures the button has sharp corners
  minWidth: "auto", // Removes any default minimum width
  fontSize: "1rem", // Adjust font size if necessary
}));

const CButton = ({
  cId,
  cText,
  cSrc,
  cIsMain,
  cTooltipText,
  cDisabled,
  cOnClick,
  cLink,
  cFullWidth,
}) => {
  const handleClick = () => {
    if (cOnClick !== undefined) {
      cOnClick();
    }
  };

  // span is added in order to show tooltip on disabled element
  return (
    <Tooltip
      title={
        cTooltipText && (
          <Typography
            style={{ whiteSpace: "pre-line" }}
            sx={{ fontSize: "1em" }}>
            {cTooltipText}
          </Typography>
        )
      }
      arrow>
      <span>
        {(cText !== undefined && (
          <Button
            id={cId}
            aria-label={cTooltipText}
            disabled={cDisabled}
            onClick={handleClick}
            color="primary"
            variant="contained"
            fullWidth={cFullWidth}>
            {cText}
          </Button>
        )) ||
          (cSrc !== undefined &&
            (cIsMain === true ? (
              <SmallSquareButton
                id={cId}
                aria-label={cTooltipText}
                disabled={cDisabled}
                onClick={handleClick}
                color="primary"
                variant="contained">
                {cSrc}
              </SmallSquareButton>
            ) : (
              <IconButton
                id={cId}
                aria-label={cTooltipText}
                disabled={cDisabled}
                onClick={handleClick}
                color="primary"
                variant="contained">
                {cSrc}
              </IconButton>
            )))}
      </span>
    </Tooltip>
  );
};

export default CButton;
