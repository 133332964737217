const NO_TOKEN_MSG = "No Token Present";

const handleLoggedIn = (result) => {
  localStorage.setItem("email", result.email);
  localStorage.setItem("token", result.token);
  localStorage.setItem("userName", result.userName);
  localStorage.setItem("customerName", result.customerName);
  localStorage.setItem("permissionData", result.permissionData);
  localStorage.setItem("terminal", result.terminal_id);
  localStorage.setItem("terminal_name", result.terminal_name);
};

const handleLogout = () => {
  const currentUrl = window.location.origin; 
  for (let key in sessionStorage) {
    if (!key.startsWith("login")) {
      sessionStorage.removeItem(key);
    }
  }
  // sessionStorage.clear();
  localStorage.clear();
  // const redirectUrl = `${REACT_APP_BE_URL}/users/auth/microsoft?feURL=${encodeURIComponent(
  // currentUrl
  // )}`;
  const redirectUrl = `${currentUrl}/login`;
  window.location.replace(redirectUrl);
};

const getUserName = () => {
  return localStorage.getItem("userName");
};

const getInitials = () => {
  let initials = "";
  const userName = getUserName();
  if (userName !== null) {
    const names = userName.split(" ");
    const filteredNames = [];
    filteredNames.push(names[0]);
    if (names.length > 1) {
      filteredNames.push(names[names.length - 1]);
    }
    initials = filteredNames.map((name) => name[0]).join("");
  }
  return initials;
};

const handleLoginError = (error) => {
  if (error.message === NO_TOKEN_MSG) {
    console.log("Token not found");
    handleLogout();
    return;
  }
  if (error.response) {
    if (error.response.status === 401) {
      handleLogout();
      return;
    } else if (error.response.status === 403) {
      const currentUrl = window.location.origin;
      const redirectUrl = `${currentUrl}/services`;
      window.location.replace(redirectUrl);
      return;
    } else {
      throw error;
    }
  } else {
    //Some error todo
    console.log("TODO check when this happens");
    throw error;
  }
};

const getTokenFromEmail = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken === null) {
    throw new Error(NO_TOKEN_MSG);
  }
  return storedToken;
};

const UserUtils = {
  handleLoggedIn,
  handleLogout,
  getUserName,
  getInitials,
  handleLoginError,
  getTokenFromEmail,
};

export default UserUtils;
