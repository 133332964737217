import React from "react";
import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";

const CBadge = ({ badgeContent, children }) => {
  return (
    <Badge
      badgeContent={badgeContent}
      sx={{
        "& .MuiBadge-dot": {
          backgroundColor: "#3CB043", // Red background for the badge
          color: "white", // White text color for the badge
        },
        "& .MuiBadge-badge": {
          backgroundColor: "#3CB043", // Red background for the badge
          color: "white", // White text color for the badge
        },
      }}>
      {children}
    </Badge>
  );
};

CBadge.propTypes = {
  badgeContent: PropTypes.number.isRequired, // Expect a number for the badge content
  children: PropTypes.node.isRequired, // Children components to wrap with the badge
};

export default CBadge;
