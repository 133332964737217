import React from "react";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import BarChartIcon from "@mui/icons-material/BarChart";
import { formatDateTime, formatTime } from "./utils";
import CDateTimePicker from "../../basecomp/components/CDateTimePicker";
import { useNavigate } from "react-router-dom";

const UserActivityTable = ({
  filteredStats,
  searchQuery,
  setSearchQuery,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  const navigate = useNavigate();
  const handleSessionHist = (id) => {
    navigate(`/dashboard/user/history?id=${id}`);
  };
  return (
    <Paper
      sx={{
        p: 3,
        mb: 4,
        backgroundColor: "#ffffff",
        borderRadius: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h6" gutterBottom>
        User Activity Log
      </Typography>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <div style={{ marginRight: "2rem" }}>
          <CDateTimePicker
            cHelperText="Start Date"
            cOnChange={handleStartDateChange}
            cHideTime={true}
          />
        </div>
        <div>
          <CDateTimePicker
            cHelperText="End Date"
            cOnChange={handleEndDateChange}
            cHideTime={true}
          />
        </div>
        <TextField
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchQuery("")}
                  edge="end"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              height: "60px",
              width: "310px",
            },
          }}
          sx={{
            ml: "auto",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc",
            },
          }}
        />
      </Grid>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#052660",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              overflowX: "auto",
            }}
          >
            <TableRow>
              <TableCell sx={{ color: "white" }}>User</TableCell>
              <TableCell sx={{ color: "white" }}>Terminal</TableCell>
              <TableCell sx={{ color: "white" }}>Login Time</TableCell>
              <TableCell sx={{ color: "white" }}>Logout Time</TableCell>
              <TableCell sx={{ color: "white" }}>Session History</TableCell>
              <TableCell sx={{ color: "white" }}>Time Spent</TableCell>
              <TableCell sx={{ color: "white" }}>IP Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStats.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Person sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2">{row.user_name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.user_email}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{row.terminal_id}</TableCell>
                <TableCell>{formatDateTime(row.login_time)}</TableCell>
                <TableCell>
                  {row.logout_time ? formatDateTime(row.logout_time) : "-"}
                </TableCell>
                <TableCell>
                  <button onClick={()=>handleSessionHist(row.id)} style={{cursor:'pointer', background:'none', border:'none'}}>
                    <BarChartIcon />
                  </button>
                </TableCell>
                <TableCell>
                  {formatTime(
                    row.activities?.reduce(
                      (acc, curr) => acc + curr.time_spent,
                      0
                    ) || 0
                  )}
                </TableCell>
                <TableCell>{row.user_ip}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UserActivityTable;
