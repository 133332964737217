import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from "@mui/icons-material/Cancel";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const iconMap = {
    edit: EditIcon,
    close: CloseIcon,
    cloudDownload: CloudDownloadIcon,
    save: SaveIcon,
    delete: DeleteIcon,
    cancel: CancelIcon,
    addPhoto: AddToPhotosIcon,
    contact: ContactPageIcon,
    postAdd: PostAddIcon,
    personAdd: PersonAddIcon
};

const CAllIcons = ({ name, ...rest }) => {
    const IconComponent = iconMap[name];

    if (!IconComponent) {
        console.error(`Icon "${name}" not found`);
        return null;
    }

    return <IconComponent {...rest} />;
};

export default CAllIcons;
