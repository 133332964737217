import React from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CTable2 from "../../../basecomp/components/CTable2";
import CButton from "../../../basecomp/components/CButton2";
import CDropDown from "../../../basecomp/components/CDropDown3";
import CDropDown2 from "../../../basecomp/components/CDropDown";
import CInput2 from "../../../basecomp/components/CInput2";
import PrintingVM from "../viewmodel/PrintingVM";
import CCarousel from "../../../basecomp/components/CCarousel";
import { AddPrintKeys } from "../../assets/VarConstants";


const PrintingView = () => {

    const {
        poOptions,
        matOptions,
        sizeOptions,
        selectedSize,
        stickerData,
        handleStickerDataEdit,
        buttonJson,
        labelData,
        handlePOSelect,
        handleMaterialSelect,
        handleSizeChange,
        handleButtonClick,
        infoHeadData,
        infoRowData,
        carouselImages,
        openCarousel,
        setOpenCarousel
    } = PrintingVM();

    return (
        <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            sx={{ margin: "5em 0.5em 0.5em 0.5em" }}>
            <Card sx={{ padding: "2.5em" }}>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>

                    {poOptions && poOptions.length > 0 ?
                        <CDropDown
                            cHelperText="Purchase Order"
                            cData={
                                poOptions
                            }
                            cOnChange={(value) => handleStickerDataEdit(AddPrintKeys.PO_KEY)(value)}
                            cFullWidth={true}
                        />
                        :
                        <CInput2
                            cHintText="Purchase Order"
                            cValue={stickerData[AddPrintKeys.PO_KEY]}
                            cOnChange={(value) => handleStickerDataEdit(AddPrintKeys.PO_KEY)(value)}
                            cFullWidth={true}
                        />
                    }
                    {matOptions && matOptions.length > 0 ?
                        <CDropDown
                            cHelperText="Material Number"
                            cData={
                                matOptions
                            }
                            cOnChange={(value) => handleStickerDataEdit(AddPrintKeys.MAT_NO_KEY)(value)}
                            cFullWidth={true}
                            cIsRequired={true}
                        />
                        :
                        <CInput2
                            cHintText="Material Number"
                            cValue={stickerData[AddPrintKeys.MAT_NO_KEY]}
                            cOnChange={(value) => handleStickerDataEdit(AddPrintKeys.MAT_NO_KEY)(value)}
                            cFullWidth={true}
                            cIsRequired={true}
                        />}

                    <CDropDown2
                        cHelperText="Size"
                        cData={
                            sizeOptions.filter(item => item.is_warehouse !== 1)
                        }
                        cIsOptionName={"size"}
                        cOnChange={handleSizeChange}
                        cFullWidth={true}
                        cIsRequired={true}
                        cDefaultValue={selectedSize}
                    />
                    <CInput2
                        cHintText="Quantity"
                        cValue={stickerData[AddPrintKeys.QUANTITY_KEY]}
                        cOnChange={(value) => handleStickerDataEdit(AddPrintKeys.QUANTITY_KEY)(value)}
                        cFullWidth={true}
                        cIsRequired={true}
                        cType="quantity"
                    />

                    <Grid container spacing={2} marginBottom={8} sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>

                        {Object.keys(buttonJson).map((key) => (
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2} key={key}>
                                <CButton
                                    cSrc={buttonJson[key].src}
                                    cTooltipText={buttonJson[key].tooltipText}
                                    cOnClick={() => handleButtonClick(key)}
                                    cFullWidth={true}
                                    cDisabled={buttonJson[key].isDisabled}
                                />
                            </Grid>
                        ))}

                    </Grid>

                    {labelData.length > 0 && (
                        <CTable2
                            cHeaderData={infoHeadData}
                            cRowData={infoRowData}
                            cActualData={labelData}
                            cIsPagination={true}
                            cSmallSize={true}
                        />
                    )}
                    {openCarousel && (
                        <CCarousel
                            cOpen={openCarousel}
                            images={carouselImages}
                            cClose={() => setOpenCarousel(false)}
                        />
                    )}

                </Grid>
            </Card>
        </Grid>
    );
};

export default PrintingView;