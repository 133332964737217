import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CText from "../../../basecomp/components/CText";
import CButton from "../../../basecomp/components/CButton";
import CDropDown from "./CDropDown";
import CInput from "./CInput";
import CTable from "../../../basecomp/components/CTable";
import Divider from "@mui/material/Divider";

import TariffVM from "../viewmodel/TariffVM";
import FinalPage from "./FinalPage";
import CalculationDetailsModal from "./CalculationDetailsModal";
import {
  tariffInfoHeadData,
  finalInfoHeadData,
  finalInfoRowData,
} from "../../assets/Constants";

import TariffBgImage from "../../../main/assets/anybg.png";

export default function Tariff({ isTransparent }) {
  const {
    containerHeight,
    chargesTypeData,
    fetchChargeDetail,
    selectedChargeData,
    selected,
    addToSelected,
    handleFetchTableData,
    tariffData,
    isDisplayTable,
    isCalcModalOpen,
    calcModalDetails,
    closeModal,
    isDisplayFinalPage,
    requiredItemIds,
    selectedData,
    tariffInfoRowData,
    handlePrintPDF,
    inputKey,
  } = TariffVM();

  const [firstItemKey, setFirstItemKey] = useState(0);

  useEffect(() => {
    if (selected.charge_id === 1) {
      setFirstItemKey((prevKey) => prevKey + 1);
    }
  }, [selected.berth_operated_by, selected.charge_id]);

  const renderComponentBasedOnType = (item) => {
    switch (item.type) {
      case "Dropdown": {
        const selectedDataItem = selectedChargeData.find(
          (data) => data.id === item.id
        );

        const checkedIds = selectedDataItem?.checked || [];

        const checkedItems = item.values
          .filter((value) => checkedIds.includes(value.id))
          .map((value) => ({
            id: value.id,
            name: value.name,
            useBerthing: value.useBerthing,
          }));

        return (
          <CDropDown
            key={`tariff_dropd_${item.id}_${inputKey}`}
            cId={`tariff_dropd_${item.name.toLowerCase().replace(/ /g, "_")}`}
            cHelperText={`${item.name}`}
            cData={item.values}
            cOnChange={addToSelected(item.id)}
            cIsMultiple={item.isMultiple}
            cIsRequired={item.isRequired}
            cIsOptionName={item.isMultiple}
            cDefaultValue={checkedItems}
            cKeyReset={inputKey}
          />
        );
      }
      case "Input":
        return (
          <CInput
            key={`tariff_input_${item.id}_${inputKey}`}
            cId={`tariff_input_${item.name.toLowerCase().replace(/ /g, "_")}`}
            cHintText={item.name}
            cEndAdornment={item.unit}
            cType="number"
            cOnChange={(value) => {
              const nonNegativeValue = Math.max(parseFloat(value), 0);
              addToSelected(item.id, item.unit)(nonNegativeValue);
            }}
            cFullWidth={true}
            cIsRequired={item.isRequired}
            cKeyReset={inputKey}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh", // Ensure the background covers the entire viewport height
        backgroundImage: `
          linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
          url(${TariffBgImage})
        `,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        backgroundColor: isTransparent
          ? "transparent"
          : "rgba(255, 255, 255, 0.8)",
        display: "flex",
        flexDirection: "column", // Ensures children stretch to fill height
      }}
    >
      <Card
        variant="outlined"
        sx={{
          padding: "2em",
          backgroundColor: isTransparent
            ? "transparent"
            : "rgba(255, 255, 255, 0.8)",
          flex: "1", // Take remaining space in the flex container
          marginTop: "4.11em", // Adjusted margin to start below header
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CDropDown
              cId="tariff_dropd_charges"
              cHelperText="Choose Charges Type"
              cData={chargesTypeData}
              cFullWidth={true}
              cOnChange={fetchChargeDetail}
              cIsOptionName={true}
            />
          </Grid>
          {selectedChargeData.length > 0 && (
            <Grid container sx={{ paddingLeft: "1em" }}>
              <Grid
                item
                xs={12}
                sx={{ paddingRight: "2em", marginBottom: "2em" }}
              >
                <CText cText={selected.charge_name} cVariant="header_card" />
              </Grid>
              <Grid container spacing={2}>
                {selectedChargeData.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    key={index === 0 ? `first-item-${firstItemKey}` : item.id}
                  >
                    {renderComponentBasedOnType(item)}
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "2em",
                }}
              >
                <CButton
                  cId="tariff_submit_btn"
                  cText="Submit"
                  cOnClick={() => handleFetchTableData(requiredItemIds)}
                />
              </Grid>
              <Divider sx={{ width: "100%", marginTop: "2em" }} />
              {isDisplayTable && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: "right",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CButton
                      cOnClick={() => handlePrintPDF(tariffData, selected)}
                      cText={"Print PDF"}
                    ></CButton>
                  </Grid>
                  <CTable
                    id="tariff_info_table"
                    cHeaderData={tariffInfoHeadData}
                    cRowData={tariffInfoRowData}
                    cActualData={tariffData}
                    cIsPagination={true}
                    cSmallSize={false}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      margin: "2em",
                    }}
                  >
                    <CText
                      cText={
                        "*Note: The estimated prices, including sheet, are based on the service charges available as of the current date only."
                      }
                      cVariant={"caption"}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Card>
      {isCalcModalOpen && (
        <CalculationDetailsModal
          isOpen={isCalcModalOpen}
          handleClose={closeModal}
          details={calcModalDetails}
        />
      )}
    </div>
  );
}
