/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MaterialSearchVM from "../viewmodel/MaterialSearchVM";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CBackDrop from "../../../../basecomp/components/CBackDrop";
import CText from "../../../../basecomp/components/CText";
import CButton from "../../../../basecomp/components/CButton";
import CInput from "../../../../basecomp/components/CInput";
import CTable from "../../../../basecomp/components/CTable";
import CDialog from "../../../../basecomp/components/CDialog";
import CCustomCard from "../../../../basecomp/components/CCustomCard";

const MaterialSearch = (props) => {
  const {
    setSpDetails,
    setSpData,
    setLightboxOpen,
    jobCardNumber,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
    spData
  } = props;
  const {
    dataCheckBox,
    data1CheckBox,
    data2CheckBox,
    data3CheckBox,
    data4CheckBox,
    showTable,
    tableData,
    modalOpen,
    handlePurchasingGroup,
    handleLevel1,
    handleLevel2,
    handleLevel3,
    handleLevel4,
    resetPage,
    handleChange,
    addToCart,
    handleButtonClick,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    setOpenCarousel,
    openCarousel,
    isLoading,
    clearSelection,
    infoHeadData,
    infoRowData,
    carouselImages,
    inputKey,
    checkedPurchasingGroupIndex,
    checkedLevel1Index,
    hideUncheckedPgr,
    hideUncheckedLevel1,
    resetPurchasingGroup,
    resetLevel1,
    quantity,
    hideUncheckedLevel2,
    checkedLevel2Index,
    hideUncheckedLevel3,
    checkedLevel3Index,
    hideUncheckedLevel4,
    checkedLevel4Index,
    resetLevel2,
    resetLevel3,
    resetLevel4,
    handleSearchTermChange,
    searchTerms
  } = MaterialSearchVM({
    setSpDetails,
    setLightboxOpen,
    jobCardNumber,
    setSpData,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
    spData
  });

  return (
    <>
      <>
        <CBackDrop cOpen={isLoading} />
        <Grid item xs={12} sm={12} md={12} margin={1.5}>
          <CText cVariant="header_card" cText="Material Search" />
        </Grid>

        <Grid item xs={12} sm={12} md={12} margin={2}>
        <Grid container spacing={1.5}>
          <CCustomCard
            title="Purchasing Group"
            data={dataCheckBox}
            handleReset={resetPurchasingGroup}
            handleChange={handlePurchasingGroup}
            itemKey="pgr_id"
            descriptionKey="pgr_desc"
            hideUnchecked={hideUncheckedPgr}
            checkedItemIndex={checkedPurchasingGroupIndex}
            searchTerm={searchTerms.purchasingGroup}
            setSearchTerm={handleSearchTermChange('purchasingGroup')}
          />
          <CCustomCard
            title="Taxonomy Level 1"
            data={data1CheckBox}
            handleReset={resetLevel1}
            handleChange={handleLevel1}
            itemKey="level1_id"
            descriptionKey="level1"
            hideUnchecked={hideUncheckedLevel1}
            checkedItemIndex={checkedLevel1Index}
            searchTerm={searchTerms.level1}
            setSearchTerm={handleSearchTermChange('level1')}
          />
          <CCustomCard
            title="Taxonomy Level 2"
            data={data2CheckBox}
            handleReset={resetLevel2}
            handleChange={handleLevel2}
            itemKey="level2_id"
            descriptionKey="level2"
            hideUnchecked={hideUncheckedLevel2}
            checkedItemIndex={checkedLevel2Index}
            searchTerm={searchTerms.level2}
            setSearchTerm={handleSearchTermChange('level2')}
          />
          <CCustomCard
            title="Taxonomy Level 3"
            data={data3CheckBox}
            handleReset={resetLevel3}
            handleChange={handleLevel3}
            itemKey="level3_id"
            descriptionKey="level3"
            hideUnchecked={hideUncheckedLevel3}
            checkedItemIndex={checkedLevel3Index}
            searchTerm={searchTerms.level3}
            setSearchTerm={handleSearchTermChange('level3')}
          />
          <CCustomCard
            title="Taxonomy Level 4"
            data={data4CheckBox}
            handleReset={resetLevel4}
            handleChange={handleLevel4}
            itemKey="level4_id"
            descriptionKey="level4"
            hideUnchecked={hideUncheckedLevel4}
            checkedItemIndex={checkedLevel4Index}
            searchTerm={searchTerms.level4}
            setSearchTerm={handleSearchTermChange('level4')}
          />
        </Grid>
      </Grid>

        <Grid item xs={12} sm={12} md={12} margin={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 9 }}>
                <CText cVariant="normal_text" cText="Local Material Number" />
                <CInput
                  cId="job_directive"
                  value={lclMaterialNo !== "" ? lclMaterialNo : material}
                  cOnChange={(value) => {
                    if (lclMaterialNo !== "") {
                      // If lclMaterialNo is not null, update it
                      setlclMaterialNo(value);
                    } else {
                      // If lclMaterialNo is null, update material
                      setMaterial(value);
                    }
                  }}
                  cIsSmall={true}
                  cFullWidth={true}
                  cKeyReset={inputKey}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 9 }}>
                <CText cVariant="normal_text" cText="Global Material Number" />
                <CInput
                  cId="global_no"
                  value={globalMaterial}
                  cOnChange={(value) => {
                    setGlobalMaterial(value);
                  }}
                  cIsSmall={true}
                  cFullWidth={true}
                  cKeyReset={inputKey}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end", // Align items at the bottom
                  }}>
                  <CText cVariant="normal_text" cText="Material Description" />

                  <div style={{ marginBottom: "-4px" }}>
                    <CButton
                      cId="info"
                      cSrc={<InfoIcon />}
                      cIsMain={false}
                      cTooltipText="Enter any part of the description. In case of multiple
        parts (search strings), use a % sign as separator
        between the parts. For example: gear%hoist or bolt%M%12"
                    />
                  </div>
                </div>
              </Grid>

              <CInput
                cId="mat_desc"
                value={materialDesc}
                cOnChange={(value) => {
                  setMaterialDesc(value);
                }}
                cIsSmall={true}
                cFullWidth={true}
                cKeyReset={inputKey}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end", // Align items at the bottom
                }}>
                <CText cVariant="normal_text" cText="Manufacture Name" />

                <div style={{ marginBottom: "-4px" }}>
                  <CButton
                    cId="info"
                    cSrc={<InfoIcon />}
                    cIsMain={false}
                    cTooltipText="Enter any part of the Manufacturer. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For Example kal, kalmar%bromma"
                  />
                </div>
              </div>

              <CInput
                cId="manu_name"
                value={manufName}
                cOnChange={(value) => {
                  setManufName(value);
                }}
                cIsSmall={true}
                cFullWidth={true}
                cKeyReset={inputKey}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 9 }}>
                <CText cVariant="normal_text" cText="Manufacture Part Number" />
                <CInput
                  cId="manu_no"
                  value={manufNo}
                  cOnChange={(value) => {
                    setManufNo(value);
                  }}
                  cIsSmall={true}
                  cFullWidth={true}
                  cKeyReset={inputKey}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end", // Align items at the bottom
                }}>
                <CText cVariant="normal_text" cText="Equipment Function" />

                <div style={{ marginBottom: "-4px" }}>
                  <CButton
                    cId="info"
                    cSrc={<InfoIcon />}
                    cIsMain={false}
                    cTooltipText="  Enter any part of the Equipment Function. In case of
                        multiple parts (search strings), use a % sign as
                        separator between the parts. For E.g. qc, qc%RTG"
                  />
                </div>
              </div>

              <CInput
                cId="equip_func"
                value={equipFunc}
                cOnChange={(value) => {
                  setEquipFunc(value);
                }}
                cIsSmall={true}
                cFullWidth={true}
                cKeyReset={inputKey}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end", // Align items at the bottom
                }}>
                <CText cVariant="normal_text" cText="Bin Location" />

                <div style={{ marginBottom: "-4px" }}>
                  <CButton
                    cId="info"
                    cSrc={<InfoIcon />}
                    cIsMain={false}
                    cTooltipText="Enter any part of the Bin Location. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For E.g. WF%01"
                  />
                </div>
              </div>

              <CInput
                cId="bin_loc"
                value={binloc}
                cOnChange={(value) => {
                  setBinLoc(value);
                }}
                cIsSmall={true}
                cFullWidth={true}
                cKeyReset={inputKey}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={11.9}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "1em",
              marginTop: "1.5em",
            }}>
            <div style={{ display: "flex", gap: "1em" }}>
              <CButton
                cId="search"
                cIsMain={true}
                cSrc={<SearchIcon />}
                cTooltipText="Search"
                cOnClick={handleButtonClick}
              />

              <CButton
                cId="reset"
                cIsMain={true}
                cSrc={<AutorenewIcon />}
                cTooltipText="Reset"
                cOnClick={resetPage}
              />
            </div>
          </Grid>
        </Grid>

        {showTable && (
          <>
            <Grid container marginTop={3}>
              <Grid item xs={12} sm={12} md={12} margin={1}>
                <CText cVariant="header_card" cText="Search" />
              </Grid>

              <Grid item xs={8} sm={8} md={12} margin={1}>
                <div style={{ overflowX: "auto" }}>
                  {showTable && tableData.length > 0 && (
                    <CTable
                      cHeaderData={infoHeadData}
                      cRowData={infoRowData}
                      cActualData={tableData}
                      cIsPagination={true}
                      cSmallSize={true}
                      showSearch={true}
                    />
                  )}

                  {openCarousel && (
                    <CCarousel
                      cOpen={openCarousel}
                      cCloseClicked={() => setOpenCarousel(false)}
                      cImages={carouselImages} // Ensure this matches with the prop name in CCarousel
                    />
                  )}

                  <CDialog
                    cOpen={modalOpen}
                    cOnSubmitClick={addToCart}
                    cHandleClose={clearSelection}
                    cTitle=<ShoppingCartOutlinedIcon />
                    cDialogContent={
                      <>
                        <Typography
                          style={{
                            color: "#1976d2",
                            marginBottom: "1em",
                          }}>
                          QTY
                        </Typography>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          value={quantity}
                          onChange={handleChange}
                          variant="standard"
                        />
                      </>
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  );
};

export default MaterialSearch;
