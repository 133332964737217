import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";

const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosPutCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallHeaderWithToken
} = AxiosWrapper;

export const uploadFile = async (formData) => {
  const response = await axiosPostCallWithToken(
    `/dcatalogue/postDcatalogueTerminal`,
    formData
  );
  return response;
};

export const getCatalogueTerminal = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getDCatTerminalAll`
  );
  return response.data;
};

export const deleteApi = async (actualRow) => {
  const response = await axiosDeleteCallWithToken(
    `/dcatalogue/deleteCatalogue?equipment_number=${actualRow.equipment_number}&terminal_id=${actualRow.terminal_id}`
  );
  return response;
};

export const updateApi = async (equipment_number, catalogue_id, catalogue_name, terminal_id) => {
  const response = await axiosPutCallWithToken(
    `/dcatalogue/updateCatalogTerminalById?equipment_number=${equipment_number}&catalogue_id=${catalogue_id}&catalogue_name=${catalogue_name}&terminal_id=${terminal_id}`
  );
  return response;
};

export const downloadSample = async () => {
  const response = await axiosGetCallHeaderWithToken(
    `/dcatalogue/downloadCsv`,
    "text/csv"
  );
  return response;
};

