/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import {
  getDataForCheckBox,
  getSQ01ByID,
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
  postSpBasket,
  getSpBasketID,
  getFilterData,
} from "../model/MaterialSearchModel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PreviewIcon from "@mui/icons-material/Preview";

const MaterialSearchVM = (props) => {
  const {
    setSpDetails,
    jobCardNumber,
    setSpData,
    lclMaterialNo,
    setlclMaterialNo,
    spData,
  } = props;
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [spBasketId, setSpBasketId] = useState("");
  const [material, setMaterial] = useState("");
  const [globalMaterial, setGlobalMaterial] = useState("");
  const [materialDesc, setMaterialDesc] = useState("");
  const [manufName, setManufName] = useState("");
  const [manufNo, setManufNo] = useState("");
  const [equipFunc, setEquipFunc] = useState("");
  const [binloc, setBinLoc] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [completeData, setCompleteData] = useState(null);
  const [error, setError] = useState("");
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [data1CheckBox, setData1CheckBox] = useState([]);
  const [data2CheckBox, setData2CheckBox] = useState([]);
  const [data3CheckBox, setData3CheckBox] = useState([]);
  const [data4CheckBox, setData4CheckBox] = useState([]);
  const [initialDataCheckBox, setInitialDataCheckBox] = useState([]);
  const [initialData1CheckBox, setInitialData1CheckBox] = useState([]);
  const [initialData2CheckBox, setInitialData2CheckBox] = useState([]);
  const [initialData3CheckBox, setInitialData3CheckBox] = useState([]);
  const [initialData4CheckBox, setInitialData4CheckBox] = useState([]);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);
  const { handleToastOpen } = useToast();
  const [data, setData] = useState([]);
  const [inputKey, setInputKey] = useState(0);

  //Material Search : Filter Checkbox on card Purchasing Group
  const [hideUncheckedPgr, setHideUncheckedPgr] = useState(false);
  const [checkedPurchasingGroupIndex, setCheckedPurchasingGroupIndex] = useState(null);

  const [hideUncheckedLevel1, setHideUncheckedLevel1] = useState(false);
  const [checkedLevel1Index, setCheckedLevel1Index] = useState(null);

  const [hideUncheckedLevel2, setHideUncheckedLevel2] = useState(false);
  const [checkedLevel2Index, setCheckedLevel2Index] = useState(null);

  const [hideUncheckedLevel3, setHideUncheckedLevel3] = useState(false);
  const [checkedLevel3Index, setCheckedLevel3Index] = useState(null);

  const [hideUncheckedLevel4, setHideUncheckedLevel4] = useState(false);
  const [checkedLevel4Index, setCheckedLevel4Index] = useState(null);


  const [searchTerms, setSearchTerms] = useState({
    purchasingGroup: "",
    level1: "",
    level2: "",
    level3: "",
    level4: "",
  });


  let selectedId;

  // Function to get unique values and sort them
  const getUniqueValuesWithIds = (items, key, idKey) => {
    const uniqueValues = new Map();

    items.forEach((item) => {
      if (item[key] !== null && item[key] !== undefined) {
        uniqueValues.set(item[key], item[idKey]);
      }
    });

    return Array.from(uniqueValues.entries())
      .map(([desc, id]) => ({
        [`${idKey}`]: id,
        [`${key}`]: desc,
      }))
      .sort((a, b) => a[key].localeCompare(b[key])); // Sort in ascending order by the key
  };

  // Fetch data and sort
  const fetchSQ01Dropdown = async () => {
    try {
      setIsLoading(true);
      // Fetch the data once
      const globalData = await getDataForCheckBox();
      const data = globalData.data;
      setData(data);

      // Data extraction and sorting
      const filteredData = getUniqueValuesWithIds(data, "pgr_desc", "pgr_id");
      const filteredData1 = getUniqueValuesWithIds(data, "level1", "level1_id");
      const filteredData2 = getUniqueValuesWithIds(data, "level2", "level2_id");
      const filteredData3 = getUniqueValuesWithIds(data, "level3", "level3_id");
      const filteredData4 = getUniqueValuesWithIds(data, "level4", "level4_id");

      // Set data for checkboxes
      setDataCheckBox(filteredData);
      setInitialDataCheckBox(filteredData);

      setData1CheckBox(filteredData1);
      setInitialData1CheckBox(filteredData1);

      setData2CheckBox(filteredData2);
      setInitialData2CheckBox(filteredData2);

      setData3CheckBox(filteredData3);
      setInitialData3CheckBox(filteredData3);

      setData4CheckBox(filteredData4);
      setInitialData4CheckBox(filteredData4);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSQ01Dropdown();
  }, []);


  const handlePurchasingGroup = async (originalIndex) => {
    // Ensure dataCheckBox is defined and contains expected items
    const item = dataCheckBox[originalIndex];
    if (!item) {
      console.error("Item not found in dataCheckBox");
      return;
    }

    const isChecked = !item.checked;
    const selectedLevel = item.pgr_desc;

    setDataCheckBox(prevData =>
      prevData.map((dataItem, i) =>
        i === originalIndex ? { ...dataItem, checked: isChecked } : dataItem
      )
    );

    if (isChecked) {
      setHideUncheckedPgr(true);
      setCheckedPurchasingGroupIndex(originalIndex);

      const filteredOptions = data.filter((item) => item.pgr_desc === selectedLevel);

      const Level1 = Array.from(new Set(filteredOptions.map((option) => option.level1)));
      const Level2 = Array.from(new Set(filteredOptions.map((option) => option.level2)));
      const Level3 = Array.from(new Set(filteredOptions.map((option) => option.level3)));
      const Level4 = Array.from(new Set(filteredOptions.map((option) => option.level4)));

      setData1CheckBox(prevData => prevData.filter(item => Level1.includes(item.level1)));
      setData2CheckBox(prevData => prevData.filter(item => Level2.includes(item.level2)));
      setData3CheckBox(prevData => prevData.filter(item => Level3.includes(item.level3)));
      setData4CheckBox(prevData => prevData.filter(item => Level4.includes(item.level4)));
    } else {
      setHideUncheckedPgr(false);
      setCheckedPurchasingGroupIndex(null);

      // Reset subsequent levels based on initial data
      setData2CheckBox(prevData2CheckBox => initialData2CheckBox.filter(item => item.pgr_desc === selectedLevel));
      setData3CheckBox(prevData3CheckBox => initialData3CheckBox);
      setData4CheckBox(prevData4CheckBox => initialData4CheckBox);
    }
  };

  const handleLevel1 = async (filteredIndex) => {
    // Find the original index of the item in data1CheckBox
    const originalItem = data1CheckBox[filteredIndex];
    const originalIndex = initialData1CheckBox.findIndex(item => item.level1 === originalItem.level1);
    const isChecked = !initialData1CheckBox[originalIndex].checked;
    const selectedLevel1 = initialData1CheckBox[originalIndex].level1;

    setData1CheckBox(prevData => prevData.map((item, i) =>
      i === filteredIndex ? { ...item, checked: isChecked } : item
    ));

    if (isChecked) {
      setHideUncheckedLevel1(true);
      setCheckedLevel1Index(filteredIndex);

      const filteredOptions = data.filter((item) => item.level1 === selectedLevel1);

      const Pgr = Array.from(new Set(filteredOptions.map((option) => option.pgr_desc)));
      const Level2 = Array.from(new Set(filteredOptions.map((option) => option.level2)));
      const Level3 = Array.from(new Set(filteredOptions.map((option) => option.level3)));
      const Level4 = Array.from(new Set(filteredOptions.map((option) => option.level4)));

      setDataCheckBox(prevData => prevData.filter(item => Pgr.includes(item.pgr_desc)));
      setData2CheckBox(prevData => prevData.filter(item => Level2.includes(item.level2)));
      setData3CheckBox(prevData => prevData.filter(item => Level3.includes(item.level3)));
      setData4CheckBox(prevData => prevData.filter(item => Level4.includes(item.level4)));
    } else {
      setHideUncheckedLevel1(false);
      setCheckedLevel1Index(null);

      // Reset subsequent levels based on initial data
      setData2CheckBox(prevData2CheckBox => initialData2CheckBox.filter(item => item.level1 === selectedLevel1));
      setDataCheckBox(prevDataCheckBox => initialDataCheckBox.filter(item => item.pgr_desc === selectedLevel1));
      setData3CheckBox(prevData3CheckBox => initialData3CheckBox);
      setData4CheckBox(prevData4CheckBox => initialData4CheckBox);
    }
  };

  const handleLevel2 = async (index) => {
    // Ensure data2CheckBox is defined and contains expected items
    const item = data2CheckBox[index];
    if (!item) {
      console.error("Item not found in data2CheckBox");
      return;
    }

    const isChecked = !item.checked;
    const selectedLevel2 = item.level2;

    setData2CheckBox(prevData =>
      prevData.map((dataItem, i) =>
        i === index ? { ...dataItem, checked: isChecked } : dataItem
      )
    );

    if (isChecked) {
      setHideUncheckedLevel2(true);
      setCheckedLevel2Index(index);

      const filteredOptions = data.filter((item) => item.level2 === selectedLevel2);

      const Level1 = Array.from(new Set(filteredOptions.map((option) => option.level1)));
      const Pgr = Array.from(new Set(filteredOptions.map((option) => option.pgr_desc)));
      const Level3 = Array.from(new Set(filteredOptions.map((option) => option.level3)));
      const Level4 = Array.from(new Set(filteredOptions.map((option) => option.level4)));

      setData1CheckBox(prevData => prevData.filter(item => Level1.includes(item.level1)));
      setDataCheckBox(prevData => prevData.filter(item => Pgr.includes(item.pgr_desc)));
      setData3CheckBox(prevData => prevData.filter(item => Level3.includes(item.level3)));
      setData4CheckBox(prevData => prevData.filter(item => Level4.includes(item.level4)));
    } else {
      setHideUncheckedLevel2(false);
      setCheckedLevel2Index(null);

      // Reset subsequent levels based on initial data
      setData1CheckBox(prevData1CheckBox => initialData1CheckBox);
      setDataCheckBox(prevDataCheckBox => initialDataCheckBox);
      setData2CheckBox(prevData2CheckBox => initialData2CheckBox.filter(item => item.level2 === selectedLevel2));
      setData3CheckBox(prevData3CheckBox => initialData3CheckBox);
      setData4CheckBox(prevData4CheckBox => initialData4CheckBox);
    }
  };

  const handleLevel3 = async (index) => {
    // Ensure data3CheckBox is defined and contains expected items
    const item = data3CheckBox[index];
    if (!item) {
      console.error("Item not found in data3CheckBox");
      return;
    }

    const isChecked = !item.checked;
    const selectedLevel3 = item.level3;

    setData3CheckBox(prevData =>
      prevData.map((dataItem, i) =>
        i === index ? { ...dataItem, checked: isChecked } : dataItem
      )
    );

    if (isChecked) {
      setHideUncheckedLevel3(true);
      setCheckedLevel3Index(index);

      const filteredOptions = data.filter((item) => item.level3 === selectedLevel3);

      const Level1 = Array.from(new Set(filteredOptions.map((option) => option.level1)));
      const Pgr = Array.from(new Set(filteredOptions.map((option) => option.pgr_desc)));
      const Level2 = Array.from(new Set(filteredOptions.map((option) => option.level2)));
      const Level4 = Array.from(new Set(filteredOptions.map((option) => option.level4)));

      setData1CheckBox(prevData => prevData.filter(item => Level1.includes(item.level1)));
      setDataCheckBox(prevData => prevData.filter(item => Pgr.includes(item.pgr_desc)));
      setData2CheckBox(prevData => prevData.filter(item => Level2.includes(item.level2)));
      setData4CheckBox(prevData => prevData.filter(item => Level4.includes(item.level4)));
    } else {
      setHideUncheckedLevel3(false);
      setCheckedLevel3Index(null);

      // Reset subsequent levels based on initial data
      setData1CheckBox(prevData1CheckBox => initialData1CheckBox);
      setDataCheckBox(prevDataCheckBox => initialDataCheckBox);
      setData2CheckBox(prevData2CheckBox => initialData2CheckBox);
      setData3CheckBox(prevData3CheckBox => initialData3CheckBox.filter(item => item.level3 === selectedLevel3));
      setData4CheckBox(prevData4CheckBox => initialData4CheckBox);
    }
  };

  const handleLevel4 = async (index) => {
    // Ensure data4CheckBox is defined and contains expected items
    const item = data4CheckBox[index];
    if (!item) {
      console.error("Item not found in data4CheckBox");
      return;
    }

    const isChecked = !item.checked;
    const selectedLevel4 = item.level4;

    setData4CheckBox(prevData =>
      prevData.map((dataItem, i) =>
        i === index ? { ...dataItem, checked: isChecked } : dataItem
      )
    );

    if (isChecked) {
      setHideUncheckedLevel4(true);
      setCheckedLevel4Index(index);

      const filteredOptions = data.filter((item) => item.level4 === selectedLevel4);

      const Level1 = Array.from(new Set(filteredOptions.map((option) => option.level1)));
      const Pgr = Array.from(new Set(filteredOptions.map((option) => option.pgr_desc)));
      const Level2 = Array.from(new Set(filteredOptions.map((option) => option.level2)));
      const Level3 = Array.from(new Set(filteredOptions.map((option) => option.level3)));

      setData1CheckBox(prevData => prevData.filter(item => Level1.includes(item.level1)));
      setDataCheckBox(prevData => prevData.filter(item => Pgr.includes(item.pgr_desc)));
      setData2CheckBox(prevData => prevData.filter(item => Level2.includes(item.level2)));
      setData3CheckBox(prevData => prevData.filter(item => Level3.includes(item.level3)));
    } else {
      setHideUncheckedLevel4(false);
      setCheckedLevel4Index(null);

      // Reset subsequent levels based on initial data
      setData1CheckBox(prevData1CheckBox => initialData1CheckBox);
      setDataCheckBox(prevDataCheckBox => initialDataCheckBox);
      setData2CheckBox(prevData2CheckBox => initialData2CheckBox);
      setData3CheckBox(prevData3CheckBox => initialData3CheckBox);
      setData4CheckBox(prevData4CheckBox => initialData4CheckBox.filter(item => item.level4 === selectedLevel4));
    }
  };

  //Material Search : Reset  all Filters and hide table
  const resetPage = () => {
    setIsLoading(true);
    setInputKey(prevKey => prevKey + 1);
    setMaterial("");
    setlclMaterialNo("");
    setGlobalMaterial("");
    setMaterialDesc("");
    setManufName("");
    setManufNo("");
    setEquipFunc("");
    setBinLoc("");
    setHideUncheckedPgr(false);
    setCheckedPurchasingGroupIndex(null);
    setDataCheckBox(initialDataCheckBox);
    setHideUncheckedLevel1(false);
    setCheckedLevel1Index(null);
    setData1CheckBox(initialData1CheckBox);
    setHideUncheckedLevel2(false);
    setCheckedLevel2Index(null);
    setData2CheckBox(initialData2CheckBox);
    setHideUncheckedLevel3(false);
    setCheckedLevel3Index(null);
    setData3CheckBox(initialData3CheckBox);
    setHideUncheckedLevel4(false);
    setCheckedLevel4Index(null);
    setData4CheckBox(initialData4CheckBox);
    setShowTable(false);
    setIsLoading(false);

    // Clear search terms
    setSearchTerms({
      purchasingGroup: "",
      level1: "",
      level2: "",
      level3: "",
      level4: "",
    });
  };

  const handleSearchTermChange = (key) => (value) => {
    setSearchTerms((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //Material Search : To view image in carousel
  const handleImageClick = async (actualRow) => {
    setCarouselImages(actualRow.image_links);
    setOpenCarousel(true);
  };

  //Material Search : Check quantity must be numeric and update new quantity
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      setQuantity("");
      setError("Value must be numeric");
    }
  };

  //Material Search : Get row data for add to cart by its Id (SQ01)
  const fetchRowData = async (id) => {
    try {
      const data = await getSQ01ByID(id);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //D-Catalogue (most used) : Fetch merge result of spbasket and sq01 data && spbasket and ictsi data(Sp Basket, SQ01 , Global Data, Material Images)
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        const updatedData = data.map((obj) => ({
          ...obj,
          tableMode: "T",
        }));
        setSpDetails(updatedData);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };

  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();

      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        const updatedData = data.map((obj) => ({
          ...obj,
          tableMode: "T",
        }));
        setSpData(updatedData);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };

  //Material Search : Generate new sp_basket id logic (Job Id Catalogue)
  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        setSpBasketId((prevSpBasketID) => {
          return data.sp_basket_id;
        });
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketID();
  }, []);


  // Function to match inserted data with spData
  function matchInsertedData(spData, insertedData) {
    // Convert spData to an array of objects if it's not already
    const spDataArray = Array.isArray(spData) ? spData : Object.values(spData);

    // Check if insertedData is an array
    if (!Array.isArray(insertedData)) {
      console.error('insertedData should be an array. Provided:', insertedData);
      return false;
    }

    // Function to check if a data entry matches any entry in spData
    function isMatch(entry) {
      // Check if spItem is defined and has the properties
      return spDataArray.some(spItem => {
        return (entry.material_no === spItem.material_no ||
          entry.global_material_no === spItem.global_material_no) &&
          entry.terminal_id === spItem.terminal_id;
      });
    }

    // Check each entry in insertedData for a match
    return insertedData.every(insertedItem => {
      // Check if duplicates is defined and is an array
      if (!Array.isArray(insertedItem.duplicates)) {
        console.error('duplicates should be an array. Provided:', insertedItem.duplicates);
        return false;
      }

      // Check if at least one duplicate entry matches
      return insertedItem.duplicates.some(isMatch);
    });
  }

  //Material Search : Insert or Add data in sp_basket (Sp Basket)
  const addToCart = async () => {
    setModalOpen(false);
    setIsLoading(true);
    if (quantity <= 0) {
      handleToastOpen("error", "Quantity must be greater than 0");
      return; // Exit function if quantity is not valid
    }
    const qty_val = completeData[0].ohqty - quantity;
    const status_qty = qty_val >= 0 ? 1 : 0;
    const storage_bin_loc =
      (completeData[0].storage_loc !== null
        ? completeData[0].storage_loc
        : "") +
      (completeData[0].bin_location === null
        ? ""
        : `${completeData[0].storage_loc === null ? "" : " / "}` +
        completeData[0].bin_location);
    // Prepare the data to send as an array

    const data = [
      {
        id: spBasketId,
        oem_no: "", // Use an empty string as a default if oem_no is null
        oem_description: "", // Use an empty string as a default if oem_description is null
        notes: "", // Use an empty string as a default if notes is null
        quantity: quantity || "", // Use an empty string as a default if quantity is null
        uom: completeData[0].b_un || "", // Use an empty string as a default if uom is null
        material_no: completeData[0].material_no || "", // Use an empty string as a default if material_no is null
        global_material_no: "", // Use an empty string as a default if gbl material_no is null
        global_material_desc: "", // Use an empty string as a default if glb material_desc is null
        storage_bin: storage_bin_loc || "", // Use an empty string as a default if storage_bin is null
        status: status_qty, // Use an empty string as a default if status is null
        job_id: jobCardNumber, // Use an empty string as a default if job id is null
        type: "SB" || "", // Default value "SB" if type is not provided
      },
    ];

    try {
      // Send the data to the API endpoint using Axios
      const response = await postSpBasket(data);

      // Extract insertedData from the response
      const insertedData = response.data.insertedData;

      // Check if the inserted data matches the spData
      const finalResult = matchInsertedData(spData, insertedData);

      if (finalResult === true) {
        setModalOpen(false);
        handleToastOpen("error", "Record already present!");
      } else {
        setModalOpen(false);
        handleToastOpen("success", "Data added successfully!");
        setQuantity(1);
      }

      setIsLoading(false);
      fetchSpBasketAll();
      fetchSpDataAll();
      fetchSpBasketID();
    } catch (error) {
      setQuantity(1);
      console.error("Error occurred during API request:", error);
    }
  };

  //Material Search : Filter table on search click
  const handleButtonClick = async () => {
    if (
      material.trim() === "" &&
      globalMaterial.trim() === "" &&
      materialDesc.trim() === "" &&
      manufName.trim() === "" &&
      manufNo.trim() === "" &&
      equipFunc.trim() === "" &&
      binloc.trim() === "" &&
      dataCheckBox.every((item) => !item.checked) &&
      data1CheckBox.every((item) => !item.checked) &&
      data2CheckBox.every((item) => !item.checked) &&
      data3CheckBox.every((item) => !item.checked) &&
      data4CheckBox.every((item) => !item.checked)
    ) {
      setTableData([]);
      setShowTable(false);
      handleToastOpen("error", "Please select any filter");
      return;
    }

    setIsLoading(true);
    // Collect filter criteria
    const filterData = {
      material: material.trim(),
      globalMaterial: globalMaterial.trim(),
      materialDesc: materialDesc.trim(),
      manufName: manufName.trim(),
      manufNo: manufNo.trim(),
      equipFunc: equipFunc.trim(),
      binloc: binloc.trim(),
      dataCheckBox: dataCheckBox
        .filter((item) => item.checked)
        .map((item) => item.pgr_desc),
      data1CheckBox: data1CheckBox
        .filter((item) => item.checked)
        .map((item) => item.level1),
      data2CheckBox: data2CheckBox
        .filter((item) => item.checked)
        .map((item) => item.level2),
      data3CheckBox: data3CheckBox
        .filter((item) => item.checked)
        .map((item) => item.level3),
      data4CheckBox: data4CheckBox
        .filter((item) => item.checked)
        .map((item) => item.level4),
    };

    const filteredData = await getFilterData(filterData);
    setIsLoading(false);
    if (filteredData) {
      setTableData(filteredData);
      setShowTable(true);
    } else {
      setTableData([]);
      setShowTable(false);
      handleToastOpen("error", "Data not found!");
    }
  };

  //GS1
  useEffect(() => {
    // Check if lclMaterialNo exists and is not null
    if (lclMaterialNo) {
      setMaterial(lclMaterialNo);
      handleButtonClick();
    }
  }, [lclMaterialNo, handleButtonClick]);

  //Material Search : Open modal on click
  const handleOpenModal = async (id) => {
    try {
      selectedId = id;
      const completeData = await fetchRowData(id);
      setCompleteData(completeData);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddImage = (materialNo) => {
    window.location.href = `/warehouse/image-upload?mat_no=${materialNo}`;
  };

  const clearSelection = async () => {
    setModalOpen(false);
  };

  const infoHeadData = [
    <div style={{ marginLeft: "7px" }}>
      <ShoppingCartOutlinedIcon />
    </div>,
    <div style={{ marginLeft: "7px" }}>
      <CameraAltIcon />
    </div>,
    "Material",
    "Description",
    "Local Material Description",
    "GMN",
    "Gbl Material Desc.",
    "Equipment Function",
    "Bin Location",
    "OHQ",
    "UOM",
    "Manufacturer Info",
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: (actualRow) =>
        actualRow.length === 0 ? null : <ShoppingCartOutlinedIcon />,
      tooltipText: (actualRow) =>
        actualRow.length === 0 ? null : "Add to cart",
      type: "IC",
      actionV: (actualRow) => handleOpenModal(actualRow.id),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: (actualRow) =>
        actualRow.image_links && actualRow.image_links.length === 0 ? (
          <CameraAltIcon />
        ) : (
          <PreviewIcon />
        ),
      tooltipText: (actualRow) =>
        actualRow.image_links && actualRow.image_links.length === 0
          ? "Upload Material Images"
          : "View Material Images",
      type: "IC",
      actionV: (actualRow) =>
        actualRow.image_links && actualRow.image_links.length === 0
          ? handleAddImage(actualRow.material_no)
          : handleImageClick(actualRow),
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `material_no`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },

    {
      value: `material_desc`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },

    {
      value: `local_material_desc`,
      statusType: "text",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `industry_std_desc`,
      statusType: "bg",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `long_desc`,
      statusType: "bg",
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `equipment_func`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `bin_location`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `ohqty`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      value: `b_un`,
      type: "T",
      actualType: (actualRow) => actualRow.tableMode,
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
    },
    {
      statusType: "bg",
      status: (actualRow) =>
        actualRow.id && actualRow.id !== selectedId ? null : 3,
      value: (actualRow) => {
        const {
          mpn_name1,
          manufac_item_code,
          mpn_name2,
          mpn_number1,
          mpn_name3,
          mpn_number2,
          mpn_name4,
          mpn_number3,
        } = actualRow;

        const getValue = (
          name1,
          itemCode1,
          name2,
          itemCode2,
          name3,
          itemCode3,
          name4,
          itemCode4
        ) => {
          let result = "";

          if (name1 || itemCode1) {
            result +=
              name1 && itemCode1
                ? `${name1} - ${itemCode1}`
                : name1 || itemCode1;
          }

          if (name2 || itemCode2) {
            result +=
              (result ? " " : "") +
              (name2 && itemCode2
                ? `${name2} - ${itemCode2}`
                : name2 || itemCode2);
          }

          if (name3 || itemCode3) {
            result +=
              (result ? " " : "") +
              (name3 && itemCode3
                ? `${name3} - ${itemCode3}`
                : name3 || itemCode3);
          }

          if (name4 || itemCode4) {
            result +=
              (result ? " " : "") +
              (name4 && itemCode4
                ? `${name4} - ${itemCode4}`
                : name4 || itemCode4);
          }

          return result || "";
        };

        return getValue(
          mpn_name1,
          manufac_item_code,
          mpn_name2,
          mpn_number1,
          mpn_name3,
          mpn_number2,
          mpn_name4,
          mpn_number3
        );
      },
      type: "F",
    },
  ]);

  const resetPurchasingGroup = () => {
    setHideUncheckedPgr(false);
    setCheckedPurchasingGroupIndex(null);
    setDataCheckBox(initialDataCheckBox);
  };

  const resetLevel1 = () => {
    setHideUncheckedLevel1(false);
    setCheckedLevel1Index(null);
    setData1CheckBox(initialData1CheckBox);
  };

  const resetLevel2 = () => {
    setHideUncheckedLevel2(false);
    setCheckedLevel2Index(null);
    setData2CheckBox(initialData2CheckBox);
  };

  const resetLevel3 = () => {
    setHideUncheckedLevel3(false);
    setCheckedLevel3Index(null);
    setData3CheckBox(initialData3CheckBox);
  };

  const resetLevel4 = () => {
    setHideUncheckedLevel4(false);
    setCheckedLevel4Index(null);
    setData4CheckBox(initialData4CheckBox);
  };


  return {
    dataCheckBox,
    data1CheckBox,
    data2CheckBox,
    data3CheckBox,
    data4CheckBox,
    showTable,
    tableData,
    modalOpen,
    error,
    handlePurchasingGroup,
    handleLevel1,
    handleLevel2,
    handleLevel3,
    handleLevel4,
    resetPage,
    handleChange,
    addToCart,
    handleButtonClick,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    quantity,
    fetchSpBasketAll,
    setOpenCarousel,
    openCarousel,
    isLoading,
    clearSelection,
    fetchSpDataAll,
    infoHeadData,
    infoRowData,
    carouselImages,
    inputKey,
    checkedPurchasingGroupIndex,
    checkedLevel1Index,
    hideUncheckedPgr,
    hideUncheckedLevel1,
    resetPurchasingGroup,
    resetLevel1,
    hideUncheckedLevel2,
    checkedLevel2Index,
    hideUncheckedLevel3,
    checkedLevel3Index,
    hideUncheckedLevel4,
    checkedLevel4Index,
    resetLevel2,
    resetLevel3,
    resetLevel4,
    handleSearchTermChange,
    searchTerms
  };
};

export default MaterialSearchVM;
