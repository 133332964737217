import { createTheme } from '@mui/material/styles';

export const themeMain = createTheme({
    palette: {
        primary: {
            main: "#042560",
        },
        secondary: {
            main: "#3CB043",
        },
        blurry: {
            main: "#00000080"
        },
        mainbg: {
            main: "white"
        },
        border: {
            main: "#0000001F"
        }
    },
});
