import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const { axiosGetCallWithToken, axiosPostCallWithToken } = AxiosWrapper;

export const getSQ01ForCheckBoxes = async () => {
  const response = await axiosGetCallWithToken(`/common/getSQ01ForCheckBoxes`);
  return response;
};

export const getSQ01ByID = async (id) => {
  const response = await axiosGetCallWithToken(
    `/common/getMergeSpOrSq01ById?sq_id=${id}`
  );
  return response.data;
};

export const getSQ01AndSpBasket = async () => {
  const response = await axiosGetCallWithToken(`/common/getMergeSpOrSq01ById`);
  return response.data;
};

export const getGlobalAndSpBasket = async () => {
  const response = await axiosGetCallWithToken(`/common/getSpAndGlobal`);
  return response.data;
};

export const postSpBasket = async (data) => {
  const response = await axiosPostCallWithToken(
    `/dcatalogue/addSpBasket`,
    data
  );
  return response;
};

export const getSpBasketID = async () => {
  const response = await axiosGetCallWithToken(`/dcatalogue/getSpBasketID`);
  return response;
};

export const getFilterData = async (data) => {
  const response = await axiosPostCallWithToken(`/dcatalogue/addFilter`, data);
  return response.data;
};

export const getDataForCheckBox = async () => {
  const response = await axiosGetCallWithToken(`/dcatalogue/getCardValues`);
  return response;
};

