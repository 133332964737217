import AxiosWrapper, { axiosGetWithTokenHeader } from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
const { axiosGetCallWithToken, axiosPostCallWithToken } = AxiosWrapper;

export const fetchBinLocationDetails = async (startDate, endDate) => {
  let response;
  try {
    response = await axiosGetCallWithToken(
      `/label_print/getBinLoc?startDate=${startDate}&endDate=${endDate}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getDownloadExportFile = async (startDate, endDate) => {
  let response;
  try {
    response = await axiosGetWithTokenHeader(
      `/label_print/getExportBinLoc?startDate=${startDate}&endDate=${endDate}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened. If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);
    throw sendError;
  }

  if (response === undefined) {
    throw new Error("No response");
  }

  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const binLocationUploadCheck = async () => {
  try {
    return await axiosGetCallWithToken(
      `/label_print/getAdminBinPermission`
    );
  } catch (error) {
    Logger.printStackTrace(error);
  }
};

export const uploadBinLocData = async (selectedFiles) => {
  let response;
  try {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
    response = await axiosPostCallWithToken(
      `/label_print/uploadBinData`,
      formData
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};
