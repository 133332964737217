import { useState, useEffect } from "react";

import {
  fetchChargesTypeData,
  fetchChargesDetailData,
  fetchChargesTable,
  fetchPrintPdfData,
} from "../model/TariffModel";

import { ChargeKeys, CommercialKeys, BerthKeys } from "../../assets/Constants";

import HelpIcon from "@mui/icons-material/Help";

import { useToast } from "../../../basecomp/components/provider/ToastProvider";

const TariffVM = () => {
  const [containerHeight, setContainerHeight] = useState("100vh");
  const [chargesTypeData, setChargesTypeData] = useState([]);
  const [selectedChargeData, setSelectedChargeData] = useState([]);
  const [selected, setSelected] = useState({});
  const [tariffData, setTariffData] = useState({});
  const [isDisplayTable, setIsDisplayTable] = useState(false);
  const [isDisplayFinalPage, setIsDisplayFinalPage] = useState(false);
  const [calcModalDetails, setCalcModalDetails] = useState("");
  const [isCalcModalOpen, setIsCalcModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleToastOpen } = useToast();
  const [requiredItemIds, setRequiredItemIds] = useState([]);
  const [selectedData, setSelectedData] = useState(new Set());
  const [initialChargeData, setInitialChargeData] = useState(null);
  const [inputKey, setInputKey] = useState(0);
  const [values, setValues] = useState([]);

  useEffect(() => {
    setSelected({});
  }, [chargesTypeData]);

  useEffect(() => {
    fetchChargesType();
  }, []);

  const fetchChargesType = async () => {
    try {
      const { data } = await fetchChargesTypeData();
      setChargesTypeData(data);
    } catch (error) {
      const { message } = error;
      handleToastOpen("error", message);
    }
  };

  useEffect(() => {
    if (selectedChargeData.length > 0) {
      const ids = selectedChargeData
        .filter((item) => item.isRequired)
        .map((item) => item.id);

      setRequiredItemIds(ids);

      const serviceCategoryItem = selectedChargeData.find(
        (item) => item.id === "service_category"
      );

      if (
        serviceCategoryItem &&
        serviceCategoryItem.checked &&
        serviceCategoryItem.checked.length > 0
      ) {
        const selectedServiceCategoryData = serviceCategoryItem.checked
          .map((categoryId) => {
            const categoryItem = serviceCategoryItem.values.find(
              (value) => value.id === categoryId
            );

            return categoryItem
              ? {
                  id: categoryItem.id,
                  name: categoryItem.name,
                  useBerthing: categoryItem.useBerthing,
                }
              : null;
          })
          .filter((item) => item !== null);

        setSelected((prevSelected) => ({
          ...prevSelected,
          service_category: selectedServiceCategoryData || [],
        }));
      }
    }
  }, [selectedChargeData]);

  useEffect(() => {
    if (selectedChargeData.length > 0 && selected.commercial_type) {
      const commercialType = selected.commercial_type;
      const updatedChargeData = [...selectedChargeData];
      let needsUpdate = false;

      // Determine which vessel type array to use based on commercialType
      const vesselTypeArray =
        commercialType === CommercialKeys.COMMERCIAL_KEY
          ? updatedChargeData[1].commercialVesselType
          : updatedChargeData[1].nonCommercialVesselType;

      if (
        JSON.stringify(updatedChargeData[2].values) !==
        JSON.stringify(vesselTypeArray)
      ) {
        updatedChargeData[2].values = vesselTypeArray;
        needsUpdate = true;
      }

      // Only update state if there are changes
      if (needsUpdate) {
        setSelectedChargeData(updatedChargeData);
      }
    }
  }, [selected.commercial_type, selectedChargeData]);

  useEffect(() => {
    if (selectedChargeData.length > 0 && !initialChargeData) {
      setInitialChargeData(JSON.parse(JSON.stringify(selectedChargeData)));
    }
  }, [selectedChargeData, initialChargeData]);

  useEffect(() => {
    if (
      selectedChargeData.length > 0 &&
      selected.berth_operated_by &&
      selected.charge_id === ChargeKeys.VRC_KEY
    ) {
      const berthOperatedBy = selected.berth_operated_by;
      const pppServices = selectedChargeData[0].pppServices;
      const portServices = selectedChargeData[0].portServices;

      // Create a new copy of selectedChargeData
      const updatedChargeData = JSON.parse(JSON.stringify(selectedChargeData));

      let filteredValues = [];

      let needsUpdate = false;

      if (berthOperatedBy === BerthKeys.PPP_KEY) {
        // Filter values based on pppServices IDs
        filteredValues = values.filter((value) =>
          pppServices.includes(value.id)
        );
        if (
          JSON.stringify(updatedChargeData[0].values) !==
          JSON.stringify(filteredValues)
        ) {
          updatedChargeData[0].values = filteredValues;
          needsUpdate = true;
        }
      } else if (berthOperatedBy === BerthKeys.PORT_KEY) {
        // Filter values based on portServices IDs
        filteredValues = values.filter((value) =>
          portServices.includes(value.id)
        );
        if (
          JSON.stringify(updatedChargeData[0].values) !==
          JSON.stringify(filteredValues)
        ) {
          updatedChargeData[0].values = filteredValues;
          needsUpdate = true;
        }
      }

      // Only update state if there are changes
      if (needsUpdate) {
        setSelectedChargeData(updatedChargeData);
      }
    }
  }, [
    selected.berth_operated_by,
    selected.charge_id,
    selectedChargeData,
    values,
  ]);

  const fetchChargeDetail = async (selectedValue) => {
    try {
      setSelectedChargeData([]);
      setInputKey((prevKey) => prevKey + 1);
      setSelected({}); // Reset selected state

      if (selectedValue) {
        setSelected((prevSelected) => ({
          ...prevSelected,
          charge_id: selectedValue.id,
          charge_name: selectedValue.name,
          useBerthing: selectedValue.useBerthing,
        }));

        const { data } = await fetchChargesDetailData(selectedValue.id);
        setValues(data[0].values || []);
        setSelectedChargeData(data);
      }
    } catch (error) {
      const { message } = error;
      handleToastOpen("error", message);
    }
  };

  const addToSelected = (itemId, itemUnit) => (value) => {
    setSelected((prevSelected) => {
      const updatedSelected = {
        ...prevSelected,
        [itemId]: value,
      };
      return updatedSelected;
    });
  };

  const handleFetchTableData = async (requiredItemIds) => {
    const validateInput = () => {
      // Check both required fields and numeric values simultaneously
      for (const itemId of requiredItemIds) {
        if (
          selected[itemId] === undefined ||
          selected[itemId] === null ||
          (Array.isArray(selected[itemId]) && selected[itemId].length === 0)
        ) {
          handleToastOpen("error", "Please fill all required inputs");
          return false;
        }
        if (typeof selected[itemId] === "number" && selected[itemId] <= 0) {
          handleToastOpen("error", "Please enter values greater than 0");
          return false;
        }
      }
      return true;
    };

    if (!validateInput()) {
      return;
    }

    try {
      setContainerHeight("auto");
      // If all required items are filled, proceed with fetching data
      const response = await fetchChargesTable(selected);
      setTariffData(response.data);
      setIsDisplayTable(true);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const setDataSelection = (row, isChecked) => {
    if (isChecked) {
      setSelectedData((prevSelectedData) => [...prevSelectedData, row]);
    } else {
      setSelectedData((prevSelectedData) =>
        prevSelectedData.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  const handleCalcModalOpen = (actualRow) => {
    setCalcModalDetails(actualRow.details);
    setIsCalcModalOpen(true);
  };

  const closeModal = () => {
    setIsCalcModalOpen(false);
  };

  const handleFinalPage = async () => {
    if (selectedData && selectedData.length > 0) {
      setIsDisplayFinalPage(true);
    } else {
      handleToastOpen("error", "Please select the checkbox");
    }
  };

  const handlePrintPDF = async (tariffData, selected) => {
    try {
      const response = await fetchPrintPdfData(tariffData, selected);

      const currentDate = new Date().toLocaleDateString();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${selected.charge_name}_${currentDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  const tariffInfoRowData = [
    {
      type: "S",
      align: "center",
    },
    { value: `mainType`, type: "T" },
    { value: `serviceType`, type: "T" },
    { value: `rate`, type: "T" },
    { value: `rateC`, type: "T" },
    { value: `rateF`, type: "T" },
    { value: `unit`, type: "T" },
    { value: `quantity`, type: "T" },
    { value: `discount`, type: "T" },
    { value: `amount`, type: "T" },
    {
      value: <HelpIcon />,
      tooltipText: "Calculation Details",
      type: "I",
      actionV: handleCalcModalOpen,
    },
  ];

  return {
    containerHeight,
    chargesTypeData,
    fetchChargeDetail,
    selectedChargeData,
    selected,
    addToSelected,
    handleFetchTableData,
    tariffData,
    isDisplayTable,
    setDataSelection,
    isModalOpen,
    setIsModalOpen,
    isCalcModalOpen,
    setIsCalcModalOpen,
    calcModalDetails,
    handleCalcModalOpen,
    closeModal,
    handleFinalPage,
    isDisplayFinalPage,
    requiredItemIds,
    selectedData,
    tariffInfoRowData,
    handlePrintPDF,
    inputKey,
    setSelected,
  };
};

export default TariffVM;
