// import { AxiosWrapper } from "fe_comp_base";
import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";

const {
    axiosGetCall,
    axiosPostCall
} = AxiosWrapper;

export const testAllCodes = async (selectedCode) => {
    const response = await axiosGetCall(
        `/devtool/testAllCodes?scode=${selectedCode}`
    );
    return response;
};

export const testAnyQuery = async (queryValue) => {
    const formData = new FormData();
    formData.append("queryValue", queryValue);
    const response = await axiosPostCall(
        `/devtool/testAnyQuery`,
        formData
    );
    return response;
};

