// import { AxiosWrapper, Logger } from "fe_comp_base";
import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";


const {
    axiosGetCallWithToken,
    axiosPutCallWithToken
} = AxiosWrapper;


export const fetchTypeList = async (type) => {
    const response = await axiosGetCallWithToken(
        `/users/getTypeList?type=${type}`
    );
    return response;
};

export const fetchPermissionList = async (type) => {
    const response = await axiosGetCallWithToken(
        `/users/getPermissionList`
    );
    return response;
};

export const updatePermissionList = async (type, id, permission_id) => {
    const response = await axiosPutCallWithToken(
        `/users/updatePermissionList?type=${type}&id=${id}&permission_id=${permission_id}`
    );
    return response;
};