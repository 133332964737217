import React, { useState, useEffect, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../basecomp/components/provider/ToastProvider";
import { QrCodeScanner } from "@mui/icons-material";
// import { QrReader } from "react-qr-reader";
import CBackDrop from "../../basecomp/components/CBackDrop";

const VisitorDetails2 = () => {
  const { handleToastOpen } = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const [visitorData, setVisitorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(() => {
    return visitorData?.status
      ? ["Resubmitted"].includes(visitorData.status)
      : false;
  });

  const [openPhotoPreview, setOpenPhotoPreview] = useState(false);
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [canCheckIn, setCanCheckIn] = useState(true);
  const [canCheckOut, setCanCheckOut] = useState(true);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(false);

  // const handleScan = (result) => {
  //   if (result) {
  //     // Redirect or handle scanned data
  //     if (result.startsWith("http://") || result.startsWith("https://")) {
  //       window.location.href = result;
  //     } else {
  //       console.error("Scanned QR code does not contain a valid URL.");
  //     }
  //   }
  // };

  // const handleError = (error) => {
  //   console.error("QR Code scanning error:", error);
  // };

  const handleCheckInOut = async (action) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/updateCheckInOut/${id}`,
        { action }
      );

      if (action === "checkin") {
        setCanCheckIn(false);
        setCanCheckOut(true);
        setIsCheckedIn(true);
      } else if (action === "checkout") {
        setCanCheckOut(false);
      }

      setConfirmationType(action);
      setShowConfirmation(true);
    } catch (error) {
      handleToastOpen("error", `Failed to ${action} visitor`);
      console.error(`Error ${action} visitor:`, error);
    }
  };

  // Add a back handler
  const handleBackFromConfirmation = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (!id) return;

    const userRole = localStorage.getItem("userRole");
    if (userRole !== "Gatekeeper") {
      // Redirect to login if not "GateKeeper"
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/visitor-mgmt/login");
      return;
    }
    const fetchVisitorData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/visitor/${id}`
        );
        const data = response.data;
        setVisitorData(data);
        setDisabled(["Resubmitted"].includes(data.status));
        setLoading(false);

        // Check check-in and check-out status
        const today = new Date().toISOString().split("T")[0];
        const checkInDate = data.check_in
          ? new Date(data.check_in).toISOString().split("T")[0]
          : null;
        const checkOutDate = data.check_out
          ? new Date(data.check_out).toISOString().split("T")[0]
          : null;

        setIsCheckedIn(!!checkInDate);

        setCanCheckIn(!checkInDate || checkInDate !== today);
        setCanCheckOut(
          checkInDate && (!checkOutDate || checkOutDate !== today)
        );
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitorData();
  }, [id]);

  const handleResubmit = async () => {
    try {
      const fe_url = window.location.origin;
      setDisabled(true);
      setConfirmationType("resubmit");
      setShowConfirmation(true);
      await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/updateVisitorStatusAndProcess/${id}?fe_url=${fe_url}`,
        {
          params: {
            status: "Resubmitted", // Send the status as a query parameter
          },
        }
      );

      setVisitorData({ ...visitorData, status: "Resubmitted" }); // Update status in UI
      setDisabled(true);
    } catch (error) {
      handleToastOpen("error", "Failed to update visitor status");
      console.error("Error updating visitor status:", error);
    }
  };

  const handleOpenPhotoPreview = () => {
    setPhotoPreview(`data:image/jpeg;base64,${visitorData.photo}`);
    setOpenPhotoPreview(true);
  };

  const handleClosePhotoPreview = () => {
    setOpenPhotoPreview(false);
    setPhotoPreview(null);
  };

  const handleOpenDocumentPreview = () => {
    setDocumentPreview(`data:image/jpeg;base64,${visitorData.document}`);
    setOpenDocumentPreview(true);
  };

  const handleCloseDocumentPreview = () => {
    setOpenDocumentPreview(false);
    setDocumentPreview(null);
  };

  const handleCheckIn = () => handleCheckInOut("checkin");
  const handleCheckOut = () => handleCheckInOut("checkout");

  // const handleClose = () => {
  //   navigate("/visitor-mgmt/visitor-details");
  // };

  if (showConfirmation) {
    return (
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ padding: "1em", margin: "5em 0.5em" }}
      >
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card
            sx={{
              padding: "2em",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              onClick={handleBackFromConfirmation}
              sx={{ marginBottom: 2 }}
              startIcon={<ArrowBackIcon />}
            ></Button>

            <Typography
              variant="h5"
              align="center"
              sx={{
                marginBottom: 2,
                fontWeight: 600,
              }}
            >
              {confirmationType === "resubmit"
                ? "Visitor Request Resubmitted Successfully"
                : `Visitor ${
                    confirmationType === "checkin"
                      ? "Checked In"
                      : "Checked Out"
                  } Successfully`}
            </Typography>

            <Typography
              variant="body1"
              align="center"
              sx={{ color: "#666", fontWeight: "500" }}
            >
              {confirmationType === "resubmit" ? (
                "This visitor request has been resubmitted for approval."
              ) : (
                <>
                  This visitor has been{" "}
                  <strong>
                    {confirmationType === "checkin"
                      ? "checked in"
                      : "checked out"}
                  </strong>
                  .
                  {confirmationType === "checkout" &&
                    " No further actions are possible."}
                </>
              )}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }

  if (!id) {
    // Render the scanner icon and prompt when no ID is present
    return (
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ padding: "1em", textAlign: "center", margin: "5em 0.5em" }}
      >
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card
            sx={{
              padding: "2em",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: "bold", marginBottom: "1em", color: "#333" }}
            >
              VISITOR GATEPASS DETAILS
            </Typography>

            {/* Tooltip around QR Code Scanner icon */}

            <Tooltip title="Scan QR" arrow>
              <QrCodeScanner
                onClick={() => setScannerOpen(true)}
                sx={{
                  fontSize: "5em",
                  color: "#555",
                  marginBottom: "1em",
                  cursor: "pointer",
                }}
              />
            </Tooltip>

            <Typography variant="body1" sx={{ color: "#555" }}>
              Scan a QR code to view visitor details.
            </Typography>

            {/* QR Code Scanner Modal */}
            <Dialog
              open={scannerOpen}
              onClose={() => setScannerOpen(false)}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>QR Code Scanner</DialogTitle>
              {/*  <DialogContent>
                <QrReader
                  onScan={handleScan}
                  onError={handleError}
                  style={{ width: "100%" }}
                />
              </DialogContent> */}
              <DialogActions>
                <Button onClick={() => setScannerOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Grid>
      </Grid>
    );
  }

  if (loading) return <CBackDrop cOpen={loading} />;

  return (
    <Box sx={{ mt: 5 }}>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <Card
          sx={{
            padding: "2em",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            backgroundColor: "#f9f9f9",
          }}
        >
          {/* Visitor GatePass Details Header */}
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold", marginBottom: "1em", color: "#333" }}
          >
            VISITOR GATEPASS DETAILS
          </Typography>

          <Typography
            variant="h6"
            align="center"
            sx={{
              fontWeight: "bold",
              marginBottom: "1.5em",
              color: visitorData.status === "Approved" ? "green" : "red",
            }}
          >
            Status: {visitorData.status}
          </Typography>
          {/* Visitor Information Table */}
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", marginTop: "1em" }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Visitor Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {visitorData.full_name}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Visitor Mobile
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {visitorData.contact_number}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Visitor Email
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">{visitorData.email}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Visit From
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {formatDate(visitorData.visit_from)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Visit Till
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {formatDate(visitorData.visit_till)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Meeting Purpose
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {visitorData.purpose}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Whom to Visit
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {visitorData.whom_to_visit}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Photo
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                      }}
                    >
                      {visitorData.photo && (
                        <img
                          src={`data:image/jpeg;base64,${visitorData.photo}`}
                          alt="Visitor Photo"
                          onClick={handleOpenPhotoPreview}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "";
                            handleToastOpen(
                              "error",
                              "Failed to load visitor photo."
                            );
                          }}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#555" }}
                    >
                      Document
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                      }}
                    >
                      {visitorData.document && (
                        <img
                          src={`data:image/jpeg;base64,${visitorData.document}`}
                          alt="Visitor Document"
                          onClick={handleOpenDocumentPreview}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "";
                            handleToastOpen(
                              "error",
                              "Failed to load visitor document."
                            );
                          }}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              marginTop: "1em",
            }}
          >
            {/* Buttons Row */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1em",
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleCheckIn}
                disabled={!canCheckIn || disabled}
                sx={{ flex: 1 }}
              >
                Check In
              </Button>

              <Button
                variant="contained"
                color="warning"
                onClick={handleCheckOut}
                disabled={!canCheckOut || disabled}
                sx={{ flex: 1 }}
              >
                Check Out
              </Button>

              {/* <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                sx={{ flex: 1 }}
              >
                Close
              </Button> */}
            </Box>

            {/* Resubmit Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={disabled || isCheckedIn} // Add isCheckedIn to the disabled condition
                onClick={handleResubmit}
              >
                Resubmit
              </Button>
            </Box>
          </Box>
        </Card>
      </Container>

      <Dialog open={openPhotoPreview} onClose={handleClosePhotoPreview}>
        <DialogTitle>Photo Preview</DialogTitle>
        <DialogContent>
          <img
            src={photoPreview}
            alt="Photo Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhotoPreview}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDocumentPreview} onClose={handleCloseDocumentPreview}>
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent>
          <img
            src={documentPreview}
            alt="Document Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDocumentPreview}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export default VisitorDetails2;
