
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserTable } from "../model/UserModel";
// import { useToast, CAllIcons } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import CAllIcons from "../../../basecomp/components/wrapper/CAllIcons";
import { UserKeys } from "../../assets/VarConstants";

const ListUserVM = () => {

    const navigate = useNavigate();
    const { handleToastOpen } = useToast();

    const headerData = [
        "ID",
        "User Name",
        "Email",
        "Position",
        "Terminal",
        "Update",
    ];
    const rowData = [
        { type: "T", value: UserKeys.USER_ID },
        { type: "T", value: UserKeys.USER_NAME },
        { type: "T", value: UserKeys.USER_EMAIL },
        { type: "T", value: UserKeys.USER_ROLE_NAME },
        { type: "T", value: UserKeys.TERMINAL_NAME },
        {
            type: "I",
            value: <CAllIcons name="postAdd" />,
            tooltipText: "Update User",
            actionV: (data) => {
                const jsonString = JSON.stringify(data);
                sessionStorage.setItem(UserKeys.NAV_USER, jsonString);
                navigate(`/users/update-user`);
            },
        },
    ];
    const [userData, setUserData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUserTable();
                setUserData(response.data);
                setFilteredList(response.data);
            } catch (error) {
                handleToastOpen("error", error.message);
            }
        }

        fetchData();
    }, []);

    const searchItems = (searchValue) => {
        setSearchValue(searchValue);
    };

    const onSearchClick = () => {
        const searchArr = [
            UserKeys.USER_NAME,
            UserKeys.USER_EMAIL,
            UserKeys.USER_ROLE,
            UserKeys.TERMINAL_NAME
        ];//user_id - int
        let filteredData = [...userData];
        const filteredResult = filteredData.filter(rowVal => {
            return searchArr.some(key => {
                let value = rowVal[key];
                if (value !== null && typeof value === 'string') {
                    value = value.toLowerCase();
                    return value.includes(searchValue.toLowerCase());
                }
                return false;
            });
        });
        if (filteredResult.length > 0) {
            setFilteredList(filteredResult);
        } else {
            setFilteredList(filteredData);
        }
    };


    return {
        headerData,
        rowData,
        filteredList,
        searchItems,
        onSearchClick
    };
};

export default ListUserVM;
