import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const { axiosGetCallWithToken } = AxiosWrapper;

export const fetchDataAll = async (
  selectedJobID,
  selectedEquipmentType,
  selectedJobType,
  selectedEquipmentId,
  selectedCreatedBy,
  selectedJobDesc,
  startDate,
  finishDate
) => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getJobIdCatalogue?selectedJobID=${selectedJobID}&selectedEquipmentType=${selectedEquipmentType}&selectedJobType=${selectedJobType}&selectedEquipmentId=${selectedEquipmentId}&selectedCreatedBy=${selectedCreatedBy}&startDate=${startDate}&finishDate=${finishDate}&selectedJobDesc=${selectedJobDesc}`
  );
  return response.data;
};

export const fetchDropdownData = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getJobIdCatForDropdown`
  );
  return response.data.data;
};

export const getEquipmentForDropdown = async () => {
  const response = await axiosGetCallWithToken(`/dcatalogue/getEquipData`);
  return response;
};
