import { useState } from "react";
import { testAnyQuery } from "../model/TestAllCodeModel";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

const TestAllCodeVM = () => {
  const initialQueries = ["ALL", "COUNT ALL", "INFORMATION_SCHEMA"];
  const { handleToastOpen } = useToast();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedQuery, setSelectedQuery] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [tableActualData, setTableActualData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [listQueries, setListQueries] = useState(initialQueries);

  const categoryTablesJson = {
    Common: {
      table: {
        sq01_table: {
          queries: ["SELECT * FROM sq01_table WHERE material = '' LIMIT 100"],
        },
        terminal_global_data_table: { queries: [] },
        mb51_table: { queries: [] },
        po_text_table: { queries: [] },
        gt00_mpn: { queries: [] },
        taxonomy_mapping_table: { queries: [] },
        mb52_table: { queries: [] },
        export_table: { queries: [] },
        sq01_gt00_table: { queries: [] },
        mpn_allterminals_table: { queries: [] },
        sq01_allterminals_table: { queries: [] },
      },
    },
    "D-Catalogue": {
      table: {
        abbr_qty_table: { queries: [] },
        d_catalogue_terminal_table: { queries: [] },
        equipment_type_table: { queries: [] },
        oem_uom_table: { queries: [] },
        job_table: { queries: [] },
        job_id_catalogue_table: { queries: [] },
        sp_basket_table: { queries: [] },
      },
    },
    GLN: {
      table: {
        continent_table: { queries: [] },
        country_table: { queries: [] },
        region_table: { queries: [] },
        city_table: { queries: [] },
        facility_table: { queries: [] },
        role_types_table: { queries: [] },
        gln_table: { queries: [] },
      },
    },
    GS1: {
      table: {
        asset_table: { queries: [] },
      },
    },
    "Image Upload": {
      table: {
        material_images_table: { queries: [] },
      },
    },
    "Label Printing": {
      table: {
        sticker_table: { queries: [] },
        measurement_table: { queries: [] },
        measurement_terminal_table: { queries: [] },
        bulk_location_table: { queries: [] },
      },
    },
    "Material Request": {
      table: {
        mct_table: { queries: [] },
      },
    },
    "Tariff Digital Calculator": {
      table: {
        tariff_details_table: { queries: [] },
        charges_table: { queries: [] },
        exchange_rates_table: { queries: [] },
      },
    },
    User: {
      table: {
        user_table: { queries: [] },
        user_type_table: { queries: [] },
        permission_table: { queries: [] },
        customer_table: { queries: [] },
        terminal_table: { queries: [] },
      },
    },
  };

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
  };

  const handleQuerySwitch = (query, table) => {
    let queryValue = "";
    switch (query) {
      case initialQueries[0]:
        queryValue = `SELECT * FROM ${table} LIMIT 100`;
        break;
      case initialQueries[1]:
        queryValue = `SELECT COUNT(*) FROM ${table}`;
        break;
      case initialQueries[2]:
        queryValue = `SELECT 
                COLUMN_NAME,
                DATA_TYPE,
                CHARACTER_MAXIMUM_LENGTH,
                IS_NULLABLE
            FROM 
                INFORMATION_SCHEMA.COLUMNS
            WHERE 
                TABLE_NAME = '${table}'
            ORDER BY 
                ORDINAL_POSITION;`;
        //  TABLE_SCHEMA = 'your_database_name'  AND
        break;
      default:
        queryValue = query;
        break;
    }
    if (queryValue !== "") {
      handleQueryValueChange(queryValue);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleTableChange = (value) => {
    setSelectedTable(value);
    const list = categoryTablesJson[selectedCategory].table[value].queries;
    setListQueries([...initialQueries, ...list]);
    handleQuerySwitch(selectedQuery, value);
  };

  const handleQueryChange = (value) => {
    handleQuerySwitch(value, selectedTable);
    setSelectedQuery(value);
  };

  const handleSubmit = async () => {
    try {
      if (queryValue === "") {
        handleToastOpen("error", "Please add a query");
      }
      const response = await testAnyQuery(queryValue);
      const result = response.data.result;
      const fields = response.data.field;
      const headData = [];
      const rowData = [];
      for (let i = 0; i < fields.length; i++) {
        const name = fields[i];
        headData.push(name); //todo { value: name }
        rowData.push({ type: "T", value: name, isJson: true });
      }
      setTableActualData(result);
      setTableRowData(rowData);
      setTableHeadData(headData);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  return {
    categoryTablesJson,
    selectedCategory,
    handleCategoryChange,
    handleTableChange,
    handleQueryChange,
    handleQueryValueChange,
    queryValue,
    handleSubmit,
    listQueries,
    tableActualData,
    tableRowData,
    tableHeadData,
  };
};

export default TestAllCodeVM;
