import Grid from "@mui/material/Grid";
import MailIcon from "@mui/icons-material/Mail";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import JobDetailsVM from "../viewmodel/JobDetailsVM";
import CDropDown from "../../../../basecomp/components/CDropDown";
import CInput from "../../../../basecomp/components/CInput";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";
import CTable from "../../../../basecomp/components/CTable";
import CButton from "../../../../basecomp/components/CButton";
import CText from "../../../../basecomp/components/CText";
import CBackDrop from "../../../../basecomp/components/CBackDrop";

const JobDetails = (props) => {
  const {
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    jobCardNumber,
    externalRef,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  } = props;
  const {
    jobDropdown,
    handleStartDateChange,
    handleFinishDateChange,
    handleIconButtonClick,
    handleButtonDownload,
    isLoading,
    infoHeadData,
    infoRowData,
  } = JobDetailsVM({
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    jobCardNumber,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  });

  // Filter spDetails to exclude records with an empty material_no
  const filteredSpDetails = spDetails.filter((detail) => detail.material_no);
  
  return (
    <>
      <CBackDrop cOpen={isLoading} />
      <Grid item xs={12} sm={12} md={12}>
        <CText cVariant="header_card" cText="Job Details" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} margin={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2.2}>
            <CText cVariant="normal_text" cText="Job Type" />
            <CDropDown
              cId="sb_job_type"
              cHelperText="Choose..."
              cData={jobDropdown && jobDropdown.type ? jobDropdown.type : []}
              cErrorMsg=""
              cOnChange={(newValue) => setJobType(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
              cIsSmall={true}
            />
          </Grid>

          <Grid item xs={12} sm={2.2}>
            <CText cVariant="normal_text" cText="Section" />
            <CDropDown
              cId="sb_section"
              cHelperText="Choose..."
              cData={
                jobDropdown && jobDropdown.section ? jobDropdown.section : []
              }
              cErrorMsg=""
              cOnChange={(newValue) => setSection(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
              cIsSmall={true}
            />
          </Grid>

          <Grid item xs={12} sm={1.6}>
            <CText cVariant="normal_text" cText="Resource" />
            <CInput
              cId="sb_resources"
              cType="number"
              cIsSession={true}
              cIsSmall
              cIsRequired
              cValue={resources === "" ? 1 : Math.max(1, resources)}
              cHtmlProps={{ min: 0 }}
              cOnChange={(value) => {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                  setResources(value === "" ? "" : parsedValue);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CText cVariant="normal_text" cText="From" />
            <CDateTimePicker
             cId="sb_start_date_time"
             cOnChange={handleStartDateChange}
             cIsSession={true}
             cSmall={true}
          />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CText cVariant="normal_text" cText="To" />
            <CDateTimePicker
            cId="sb_finish_date_time"
            cOnChange={handleFinishDateChange}
            cIsSession={true}
            cSmall={true}
          />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} paddingTop={1}>
          <CText cVariant="normal_text" cText="Job Description" />
          <CInput
            cId="sb_work_desc"
            cValue={workDesc}
            cOnChange={(value) => setWorkDesc(value)}
            cIsSession={true}
            cFullWidth={true}
            cRows={5}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div style={{ overflowX: "auto" }}>
            {filteredSpDetails.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <CTable
                  cHeaderData={infoHeadData}
                  cRowData={infoRowData}
                  cActualData={filteredSpDetails}
                  cIsPagination={true}
                  cSmallSize={true}
                />
              </Grid>
            )}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11.9}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1em",
          }}>
          <div style={{ display: "flex", gap: "1em" }}>
            <CButton
              cId="email_job_details"
              cIsMain={true}
              cSrc={<MailIcon />}
              cTooltipText="Job Details Email"
              cOnClick={handleIconButtonClick}
            />

            <CButton
              cId="download_job_details"
              cIsMain={true}
              cSrc={<CloudDownloadIcon />}
              cTooltipText="Job Details Pdf"
              cOnClick={handleButtonDownload}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
