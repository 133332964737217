// components/SessionHistory/index.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const REACT_BE_URL = process.env.REACT_APP_BE_URL2;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="subtitle2" sx={{ color: "#052660", mb: 1 }}>
          {label}
        </Typography>
        <Typography variant="body2">
          Time Spent: <strong>{formatTime(payload[0].value)}</strong>
        </Typography>
      </Box>
    );
  }
  return null;
};

const formatTime = (seconds) => {
  if (!seconds) return "0s";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  if (remainingSeconds > 0 || parts.length === 0)
    parts.push(`${remainingSeconds}s`);

  return parts.join(" ");
};

const SessionHistory = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = decodeURIComponent(searchParams.get("id")) || null;
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${REACT_BE_URL}/tracker/sessionHistoryDetails?id=${id}`
        );
        console.log(response, response.data);

        setHistory(response.data);
      } catch (error) {
        console.error("Failed to fetch history data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Format long page paths for better display
  const formatPagePath = (path) => {
    return path.length > 30 ? path.substring(0, 27) + "..." : path;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 12, mb: 12 }}>
      {/* Chart Section */}
      <Paper
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h6" sx={{ mb: 3 }}>
          Page Visit Duration
        </Typography>
        <Box height={400}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={history}
              margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis
                dataKey="path"
                tick={{ fontSize: 12 }}
                interval={0}
                angle={0}
                textAnchor="end"
                height={100}
                tickFormatter={formatPagePath}
              />
              <YAxis
                tick={{ fontSize: 12 }}
                label={{
                  value: "Time (seconds)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                dataKey="time_spent"
                name="Time Spent"
                fill="#63b144"
                radius={[4, 4, 0, 0]}
                maxBarSize={50}
                style={{ cursor: "pointer" }}
                activeBar={{ fill: "#63b144" }}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      {/* Table Section */}
      <Paper
        sx={{
          p: 3,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h6" sx={{ mb: 3 }}>
          Session Details
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#052660",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white" }}>Session ID</TableCell>
                <TableCell sx={{ color: "white" }}>Page Name</TableCell>
                <TableCell sx={{ color: "white" }}>Time Spent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(Array.isArray(history) ? history : []).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.session_id}</TableCell>
                  <TableCell>{row.path}</TableCell>
                  <TableCell>{formatTime(row.time_spent)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default SessionHistory;
