import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import { useToast } from "fe_comp_base";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import UserUtils from "../../../basecomp/auth/UserUtils";
import {
  updateStatusMCTTableById,
  updateStatusAndData,
  validateData,
} from "../model/MatRequestModel";
import {
  STATUS_ON_HOLD,
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_IN_PROGRESS,
} from "../../assets/Strings";

const MatProcessVM = () => {
  const { getUserName } = UserUtils;
  const navigate = useNavigate();
  const { handleToastOpen } = useToast();
  const { id } = useParams();
  const [processInput, setProcessInput] = useState([]);
  const [validationStatus, setValidationStatus] = useState(false);
  const [buttonJson, setButtonJson] = useState({
    validate: {
      text: "Validate",
      tooltipText: "Validate the Process",
      isDisabled: false,
    },
    approved: {
      text: STATUS_APPROVED,
      tooltipText: "Approve the Request",
      isDisabled: false,
    },
    rejected: {
      text: STATUS_REJECTED,
      tooltipText: "Reject the Request",
      isDisabled: false,
    },
    onhold: {
      text: STATUS_ON_HOLD,
      tooltipText: "On Hold the Request",
      isDisabled: false,
    },
  });
  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [mpnDialogOpen, setMpnDialogOpen] = useState(false);
  const [tableActualData, setTableActualData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
  const [processingModalMessage, setProcessingModalMessage] = useState("");

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleMpnDialogClose = () => {
    setMpnDialogOpen(false);
  };

  const handleMpnDialogOpen = () => {
    setMpnDialogOpen(true);
  };

  const disableAllButtons = () => {
    setButtonJson((prevState) => {
      const updatedButtonJson = {};
      for (const key in prevState) {
        updatedButtonJson[key] = { ...prevState[key], isDisabled: true };
      }
      return updatedButtonJson;
    });
  };

  // Add an effect to handle the beforeunload event

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("wasdw");
        const userName = getUserName();
        const response = await updateStatusMCTTableById(id);
        let data = response.data;
        if (response.data.message) {
          setIsProcessingModalOpen(true);
          setProcessingModalMessage(response.data.message);
          return;
        }

        if (
          data.status.value === STATUS_APPROVED ||
          data.status.value === STATUS_REJECTED
        ) {
          disableAllButtons();
        } else if (data.status.value === STATUS_ON_HOLD) {
          setButtonJson((prevState) => ({
            ...prevState,
            onhold: {
              ...prevState.onhold,
              isDisabled: true,
            },
          }));
        }

        if (data["material_number"]?.value?.startsWith("8")) {
          if (data["reason"]) {
            data["reason"].isDisabled = false;
          }

          const allowedFields = [
            "material_number",
            "int_material_no",
            "request_date",
            "description",
            "plant_id",
            "reason",
            "user_name",
            "type_of_request",
            "ind_std_desc",
            "status",
            "po_text",
            "mpn_name",
            "mpn_number",
            "material_type",
            "material_group",
            "approvers_comments",
            "standard_comments",
            "rejected_request_comments",
          ];

          const filteredData = {};
          allowedFields.forEach((field) => {
            if (data[field] !== undefined) {
              filteredData[field] = data[field];
            }
          });

          console.log("data", data);

          data = filteredData;
        }

        // Update mdm_name if it exists
        if (data["mdm_name"]) {
          data["mdm_name"].value = userName;
          data["mdm_name"].initialValue = userName;
        }
        console.log("data", data);
        setProcessInput(data);
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleInputChange = (itemId) => (value) => {
    setProcessInput((prevSelected) => {
      const itemObject = prevSelected[itemId];
      const updatedItemObject = {
        ...itemObject,
        value: value,
      };
      return {
        ...prevSelected,
        [itemId]: updatedItemObject,
      };
    });
  };

  // Create a single, shared debounce function
  const createDebouncedFunction = (func, delay) => {
    let timeoutId;
    let lastArgs;
    let context;

    const executeFunction = () => {
      func.apply(context, lastArgs);
      timeoutId = null;
    };

    return function (...args) {
      context = this;
      lastArgs = args;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(executeFunction, delay);
    };
  };

  // Create a closure to manage the debounced function
  const createStatusUpdateHandler = () => {
    let lastId = null;

    const debouncedUpdateStatus = createDebouncedFunction(async (id) => {
      // Additional check to prevent repeated calls with the same ID
      if (id === lastId) return;

      lastId = id;

      try {
        console.log("Updating status for ID:", id);
        const response = await updateStatusMCTTableById(id, true);

        // Check if the update was successful
        if (response && response.status === 200) {
          // Reload the current page
          window.location.reload();
        }
      } catch (error) {
        console.error("Error updating status:", error);
      } finally {
        // Reset lastId after a short delay to allow future updates
        setTimeout(() => {
          lastId = null;
        }, 500);
      }
    }, 300);

    return debouncedUpdateStatus;
  };

  // Create the handler
  const debouncedUpdateStatus = createStatusUpdateHandler();

  // Remove any existing listeners first
  const oldListener = window.onpopstate;
  window.onpopstate = null;

  // Add the new listener
  window.addEventListener("popstate", (event) => {
    console.log("Popstate event triggered", id);
    debouncedUpdateStatus(id);
  });

  const handleBackButtonClick = async () => {
    console.log("dwasda");
    await updateStatusMCTTableById(id, true); // Pass true to indicate that the status update is from the back button click
    navigate("/material-creation-demo/request-tracker");
  };

  const handleInfoClick = (tableView) => {
    const transformedHeadData = tableView.tableHeadData.map(
      (item) => item.value
    );
    setTableHeadData(transformedHeadData);
    setTableRowData(tableView.tableRowData);
    setTableActualData(tableView.tableActualData);
    handleDialogOpen();
  };

  const handleButtonClick = async (keyType, newStatus) => {
    if (keyType === "validate") {
      try {
        const response = await validateData(id, processInput, 1);
        if (response.data.errorMessage) {
          handleToastOpen("error", response.data.errorMessage);
        } else if (response.data.message) {
          handleToastOpen("success", response.data.message);
        }
        const inputJson = response.data.inputJson;
        setProcessInput(inputJson);
        setValidationStatus(response.data.validationStatus);
        if (
          Array.isArray(
            response.data?.inputJson?.mpn_number?.duplicateMpnTableView
              ?.tableActualData
          ) &&
          response.data.inputJson.mpn_number.duplicateMpnTableView
            .tableActualData.length > 0
        ) {
          const duplicateMpnTableView =
            response.data.inputJson.mpn_number.duplicateMpnTableView;
          const transformedHeadData = duplicateMpnTableView.tableHeadData.map(
            (item) => item.value
          );
          setTableHeadData(transformedHeadData);
          setTableRowData(duplicateMpnTableView.tableRowData);
          setTableActualData(duplicateMpnTableView.tableActualData);

          setModalData(response.data.inputJson.mpn_number.duplicateMpnView);
          handleMpnDialogOpen();
        }
      } catch (error) {
        handleToastOpen("error", error.message);
      }
      return;
    }
    if (validationStatus === false) {
      handleToastOpen("error", "Please validate before proceeding");
    }

    let isError = false;
    const newInput = [];
    for (const key in processInput) {
      const item = processInput[key];
      if (item.isRequired && item.value.trim() === "") {
        handleToastOpen(
          "error",
          `Field "${item.enter}" is required but the value is blank.`
        );
        isError = true;
        break;
      }
      newInput.push({ [key]: item.value });
    }
    if (newStatus === STATUS_REJECTED) {
      const comments = processInput["rejected_request_comments"];
      if (comments.value.trim() === "") {
        handleToastOpen(
          "error",
          `Field rejected_request_comments is required in case of rejecting the query.`
        );
        isError = true;
      }
    }
    if (!isError) {
      try {
        const userName = getUserName();
        const response = await updateStatusAndData(
          id,
          newInput,
          newStatus,
          userName
        );
        if (newStatus === STATUS_APPROVED || newStatus === STATUS_REJECTED) {
          disableAllButtons();
          navigate("/material-creation-demo/request-tracker");
        } else if (newStatus === STATUS_ON_HOLD) {
          setButtonJson((prevState) => ({
            ...prevState,
            onhold: {
              ...prevState.onhold,
              isDisabled: true,
            },
          }));
          navigate("/material-creation-demo/request-tracker");
        }
        handleToastOpen("success", response.data.message);
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    }
  };
  const handleModalClose = () => {
    setIsProcessingModalOpen(false);
    navigate(-1);
  };

  // useEffect(() => {
  //   if (processInput["material_number"]?.value?.startsWith("8")) {
  //     console.log("dwas");
  //     const allowedFields = [
  //       "material_number",
  //       "request_date",
  //       "plant_id",
  //       "type_of_request",
  //       "reason",
  //       "material_type",
  //       "description",
  //       "int_material_no",
  //       "ind_std_desc",
  //       "b_un",
  //       "material_group",
  //       "part_type_code",
  //       // "mpn_number",
  //       "long_description",
  //       "po_text",
  //       "mrp_type",
  //       "user_name",
  //       "status",
  //       "mpn_name",
  //       "approvers_comments",
  //       "standard_comments",
  //       "rejected_request_comments",
  //     ];

  //     // Check if we need to update the state
  //     let needsUpdate = false;
  //     const newState = { ...processInput };

  //     // Check if reason needs to be made editable
  //     if (newState["reason"] && newState["reason"].isDisabled) {
  //       newState["reason"].isDisabled = false;
  //       needsUpdate = true;
  //     }

  //     // Check if there are any fields that need to be removed
  //     const currentFields = Object.keys(processInput);
  //     const fieldsToRemove = currentFields.filter(
  //       (field) => !allowedFields.includes(field)
  //     );

  //     if (fieldsToRemove.length > 0) {
  //       fieldsToRemove.forEach((field) => {
  //         delete newState[field];
  //       });
  //       needsUpdate = true;
  //     }

  //     // Only update state if necessary
  //     if (needsUpdate) {
  //       console.log("newState", newState);
  //       setProcessInput(newState);
  //     }
  //   }
  // }, [processInput["material_number"]?.value]);

  return {
    processInput,
    setProcessInput,
    handleInputChange,
    handleInfoClick,
    handleBackButtonClick,
    buttonJson,
    handleButtonClick,
    dialogOpen,
    mpnDialogOpen,
    handleMpnDialogClose,
    handleDialogClose,
    tableActualData,
    tableRowData,
    tableHeadData,
    modalData,
    setModalData,
    isProcessingModalOpen,
    handleModalClose,
    processingModalMessage,
  };
};

export default MatProcessVM;
