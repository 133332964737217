import React from "react";
// import {
//   CChooseFile,
//   CDropDown,
//   CButton,
//   CMainCard,
//   CMainGrid,
// } from "fe_comp_base";
import CChooseFile from "../../../basecomp/components/CChooseFile";
import CDropDown from "../../../basecomp/components/CDropDown";
import CButton from "../../../basecomp/components/CButton";
import CMainCard from "../../../basecomp/components/wrapper/CMainCard";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import MCTCommonFilesVM from "../viewmodel/MCTCommonFilesVM";
import { cSubmitAction } from "../../../material_creation/assets/Strings";

const MCTCommonFilesView = () => {
  const {
    loading,
    success,
    error,
    handleFileChange,
    handleFileUpload,
    handleApiChange,
  } = MCTCommonFilesVM();

  return (
    <CMainGrid
      item
      xs={10}
      sm={8}
      md={10}
      marginLeft={6}
      marginRight={6}
      marginTop={12}
    >
      <CMainCard>
        {loading && (
          <div
            style={{ padding: "10px", textAlign: "center", fontWeight: "bold" }}
          >
            Uploading file...
          </div>
        )}
        {success && (
          <div
            style={{
              padding: "10px",
              textAlign: "center",
              color: "green",
              fontWeight: "bold",
            }}
          >
            File upload successful!
          </div>
        )}
        {error && (
          <div
            style={{
              padding: "10px",
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
            Error: {error}
          </div>
        )}
        {!loading && !success && !error && (
          <CMainGrid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CMainGrid item xs={12} sm={12} md={12} lg={12}>
              <CDropDown
                cId="dd_1"
                cHelperText="Choose File name"
                cData={[
                  "common/addSQ01Bulk",
                  "common/addMB51Bulk",
                  "common/addTerminalGlobalBulk",
                  "common/addSQ01GT00Bulk",
                  "common/addMPNGT00Bulk",
                  "common/addPoTextBulk",
                  "common/addTaxonomyMappingBulk",
                  "common/addMPNAllBulk",
                  "common/addSQ01AllBulk",
                  "gln/addContinentData",
                  "gln/addCountryData",
                  "gln/addRegionData",
                  "gln/addCityData",
                  "gln/addFacilityData",
                  "gln/addRoleType",
                  "gln/addGlnData",
                ]}
                cFullWidth={true}
                cOnChange={handleApiChange}
              />
              <CChooseFile
                cFileType="XCL"
                cTooltipText="Upload excel"
                cHelperText="*File Types Excel/CSV"
                cHandleFileChange={handleFileChange}
                cFullWidth={true}
              />
            </CMainGrid>
            {!loading && !success && !error && (
              <CMainGrid
                item
                xs={12}
                sm={6}
                md={1.5}
                paddingLeft={2}
                paddingBottom={3}
              >
                <CButton
                  cText="Submit"
                  cTooltipText={cSubmitAction}
                  cOnClick={() => {
                    handleFileUpload();
                  }}
                  disabled={loading} // Disable the button while loading
                />
              </CMainGrid>
            )}
          </CMainGrid>
        )}
      </CMainCard>
    </CMainGrid>
  );
};

export default MCTCommonFilesView;
