import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
const {
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosGetCallHeaderWithToken
 } = AxiosWrapper;

export const fetchJobDetails = async (id) => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getJobIdCatalogue?job_card_no=${id}`
  );
  return response.data;
};

export const getSq01All = async () => {
  const response = await axiosGetCallWithToken(`/common/getSq01All`);
  return response.data;
};

export const getSpBasketID = async () => {
  const response = await axiosGetCallWithToken(
    `/dcatalogue/getSpBasketID`
  );
  return response;
};

export const postSpBasket = async (result) => {
  const response = await axiosPostCallWithToken(
    `/dcatalogue/addSpBasket`,
    result
  );
  return response;
};

export const getGlobalAndSpBasket = async (id) => {
  const response = await axiosGetCallWithToken(
    `/common/getSpAndGlobal?jobid=${id}`
  );
  return response.data;
};

export const pdfDownload = async (jobId, type) => {
  const response = await axiosGetCallHeaderWithToken(
    `/dcatalogue/getPdf?jobId=${jobId}&type=${type}`,
    "application/pdf"
  );
  return response;
};
