const CONTINENT_KEY = 1;
const COUNTRY_KEY = 2;
const REGION_KEY = 3;
const CITY_KEY = 4;
const FACILITY_NAME_KEY = 5;
const OTHER_TYPE_KEY = 6;
const GLN_ID_KEY = 7;

export const CombinedConstKeys = {
  CONTINENT_KEY,
  COUNTRY_KEY,
  REGION_KEY,
  CITY_KEY,
  FACILITY_NAME_KEY,
  OTHER_TYPE_KEY,
  GLN_ID_KEY,
};

export const termsAndConditions = `
The information, services, products, and materials contained in this site, including, without limitation, text, graphics, and links, are provided on an "as is" basis with no warranty. To the maximum extent permitted by law, the operator of this website disclaims all representations and warranties, express or implied, with respect to such information, services, products, and materials, including but not limited to warranties of merchantability, fitness for a particular purpose, title, non-infringement, freedom from computer virus, and implied warranties arising from course of dealing or course of performance. In addition, the operator of this website does not represent or warrant that the information accessible via this website is accurate, complete or current. This site could include technical inaccuracies or typographical errors. Changes are periodically added to the information herein.  

The operator of this website is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this site is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will the operator of this website, or its partners, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information in this site, or for any consequential, special or similar damages, even if advised of the possibility of such damages. 

The operator of this website is not responsible for the contents of any linked site or any link contained in a linked site. The operator of this website reserves the right to make changes to the materials at this site, at any time without notice. 
`;

export const Acknowledgements = `
The information in this service is based on the data that was made publicly available by various organisations mentioned below.

Also on behalf of the users of this service, the operator of the service wants to express its appreciation to those organisations for making this data available publicly so it could be used and presented in this service.

The organisations from which the public data has been sourced include (in no particular order):

- International Maritime Organisation
  Through their “Global Integrated Shipping Information System (GISIS)”.
  In this service the data is presented related to “Port Facility codes”.

- UN/CEFACT – United Nations Centre for Trade Facilitation and Electronic Business
  Through the UNECE web-pages related to the so-called UN/LOCODES.

- Bureau International des Containers (BIC)
  From their BIC Facility Code service.

- SMDG - Ship Message Design Group
  From their web-pages for code lists.

- International Air Transport Association - IATA
  Airport codes.

The service will be expanded with location data from other sources.

Providers of location data wishing for their data to be findable through this service may contact CONAS via:

CONTACT DETAILS:
jaco.voorspuij@conasbv.com
`;
