import React, { useState } from "react";
import axios from "axios";
import {
  Grid,
  Tooltip,
  Button,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useToast } from "../../basecomp/components/provider/ToastProvider";

const Header = ({
  selectedRole,
  setSelectedRole,
  setSelectedFiles, // To handle file selection
  handleBulkUpload,
  isLoading,
}) => {
  const { handleToastOpen } = useToast();
  const [fileName, setFileName] = useState("Choose File");

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);

    // Display the name of the first file selected or reset the button text
    if (files.length > 0) {
      setFileName(files[0].name);
    } else {
      setFileName("Choose File");
    }
  };

  const handleSampleFileDownload = async () => {
    try {
      const type = selectedRole.toLowerCase(); // Convert role to lowercase for the request
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL2}/visitor-mgmt/getSampleCsv?type=${type}`,
        { responseType: "blob" } // Corrected for blob response
      );
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Sample${selectedRole}Upload.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{
        marginBottom: "2em",
        marginTop: {
          xs: "2em", // Top margin for screens below 500px
          sm: "0", // No top margin for larger screens
        },
        justifyContent: {
          xs: "flex-start", // Left-align on small screens
          sm: "flex-start", // Left-align on larger screens
        },
      }}
    >
      <Grid item>
        <FormControl>
          <Select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select Role" }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="" disabled>
              Select Role
            </MenuItem>
            <MenuItem value="Approver">Approver</MenuItem>
            <MenuItem value="Gatekeeper">Gatekeeper</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <label htmlFor="file-upload">
          <Button variant="outlined" component="span">
            {fileName}
          </Button>
        </label>
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple // If you want to allow multiple files
        />
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBulkUpload}
          sx={{ minWidth: 150 }}
          disabled={isLoading}
        >
          {isLoading ? "Uploading..." : "Upload"}
        </Button>
      </Grid>

      <Grid item>
        <Tooltip title="Download sample file" arrow>
          <Button
            variant="contained"
            sx={{ minWidth: 200 }}
            onClick={handleSampleFileDownload}
            disabled={!selectedRole} // Disable if no role is selected
          >
            {selectedRole ? `${selectedRole} File` : "No Role Selected"}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Header;
