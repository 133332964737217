import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WarningAmber from "@mui/icons-material/WarningAmber";

const ProcessingModal = ({ isOpen, onClose, processingModalMessage }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "warning.main",
            mb: 2,
          }}
        >
          <WarningAmber />
          <Typography id="modal-title" variant="h6" component="h2">
            Request In Progress
          </Typography>
        </Box>

        <Typography id="modal-description" sx={{ mb: 3 }}>
          {processingModalMessage ||
            "This request is currently being processed by another user."}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              bgcolor: "warning.main",
              "&:hover": {
                bgcolor: "warning.dark",
              },
            }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProcessingModal;
