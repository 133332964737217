import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import CButton from "../../../../basecomp/components/CButton";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SBHeaderVM from "../viewmodel/SBHeaderVM";
import CInput from "../../../../basecomp/components/CInput";
import CDropDown2 from "../../../../basecomp/components/CDropDown2";
import CText from "../../../../basecomp/components/CText";
import CBadge from "../../../../basecomp/components/CBadge";
import CBackDrop from "../../../../basecomp/components/CBackDrop";

const SBHeader = ({
  setIsMaterialSearchUI,
  setIsShoppingBasketUI,
  setIsobDetailsUI,
  setIsReplenishmentUI,
  section,
  jobType,
  resources,
  startDate,
  finishDate,
  workDesc,
  externalRef,
  setExternalRef,
  jobDirective,
  setJobDirective,
  equipmentType,
  setEquipmentType,
  equipmentID,
  setEquipmentID,
  spData,
  setSpData,
  setSpDetails,
  spDetails,
}) => {
  const {
    isLoading,
    jobCardNumber,
    setJobCardNumber,
    handleSaveJob,
    ShoppingBasket,
    MaterialSearch,
    JobDetails,
    Replenishment,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    setcatalogueId,
  } = SBHeaderVM({
    startDate,
    finishDate,
    jobDirective,
    equipmentID,
    equipmentType,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    setIsMaterialSearchUI,
    setIsShoppingBasketUI,
    setIsobDetailsUI,
    setIsReplenishmentUI,
    spData,
    setSpData,
    setSpDetails,
    spDetails,
  });

  // Filter spDetails to exclude records with an empty material_no
  const filteredSpDetails = spDetails.filter((detail) => detail.material_no);

  // Retrieve values from session storage when the component mounts
  useEffect(() => {
    const storedEquipmentId = sessionStorage.getItem("sb_equipment_id");
    if (storedEquipmentId) {
      const equipmentData = JSON.parse(storedEquipmentId);
      setEquipmentID(equipmentData);
    }
  }, []);

  return (
    <div>
      <CBackDrop cOpen={isLoading} />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={1.5}>
          <CText cVariant="normal_text" cText="Job ID" />
          <CInput
            cId="job_id"
            cIsSmall={true}
            cHintText={jobCardNumber}
            cOnChange={(value) => setJobCardNumber(value)}
            cDisabled={true}
            cIsSession={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={1.7}>
          <CText cVariant="normal_text" cText="External Ref." />
          <CInput
            cId="sb_external_ref"
            value={externalRef}
            cOnChange={(value) => setExternalRef(value)}
            cIsSession={true}
            cIsSmall={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={3.5}>
          <CText cVariant="normal_text" cText="Job Directive*" />
          <CInput
            cId="sb_job_directive"
            value={jobDirective}
            cOnChange={(value) => setJobDirective(value)}
            cIsSession={true}
            cIsSmall={true}
            cFullWidth={true}
            cIsRequired={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={2.1}>
          <CText cVariant="normal_text" cText="Equipment Type*" />
          <CDropDown2
            cId="sb_equipment_type"
            cHelperText="Choose..."
            cData={[...new Set(filteredEquipmentTypes)].sort()}
            cErrorMsg=""
            cOnChange={(newValue) => setEquipmentType(newValue)}
            cIsSession={true}
            cIsSmall={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={2.1}>
          <CText cVariant="normal_text" cText="Equipment ID*" />
          <CDropDown2
            cId="sb_equipment_id"
            cHelperText="Choose..."
            cData={filteredEquipmentIDs.sort() || []}
            cErrorMsg=""
            cOnChange={(newValue) => {
              setEquipmentID(newValue);
              const selectedCatalogueId = newValue
                ? newValue.catalogue_id
                : null;
              setcatalogueId(selectedCatalogueId);
              console.log("Selected Equipment ID:", newValue);
              console.log("Selected Catalogue ID:", selectedCatalogueId);
            }}
            getOptionLabel={(option) =>
              option && option.equipment_number
                ? option.equipment_number
                : equipmentID
                  ? equipmentID.equipment_number
                  : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.equipment_number === value.equipment_number &&
              option.catalogue_id === value.catalogue_id
            }
            cIsSession={true}
            cIsSmall={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={0.1}
          style={{ margin: "1.9em 1.4em 1em 1.4em" }}>
          <CButton
            cId="save_job"
            cIsMain={true}
            cSrc={<NoteAddIcon />}
            cTooltipText="Save Job"
            cOnClick={handleSaveJob}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 2.5 }}>
        <CText
          cVariant="nav_bar"
          cText={
            <>
              <CButton
                cId="material_search"
                cSrc={<SearchIcon />}
                cIsMain={false}
                cTooltipText="Material Search"
                cOnClick={MaterialSearch}
              />

              <CButton
                cId="shopping_basket"
                cSrc={
                  <CBadge badgeContent={spData.length} color="secondary">
                    <ShoppingCartIcon />
                  </CBadge>
                }
                cIsMain={false}
                cTooltipText="Shopping Basket"
                cOnClick={() => {
                  ShoppingBasket();
                }}
              />

              <CButton
                cId="material_list"
                cSrc={
                  <CBadge
                    badgeContent={filteredSpDetails.length}
                    color="secondary">
                    <PlaylistAddCheckCircleIcon />
                  </CBadge>
                }
                cIsMain={false}
                cTooltipText="Material List"
                cOnClick={() => {
                  JobDetails();
                }}
              />

              <CButton
                cId="replenishment"
                cSrc={
                  <CBadge
                    badgeContent={filteredSpDetails.length}
                    color="secondary">
                    <ErrorIcon />
                  </CBadge>
                }
                cIsMain={false}
                cTooltipText="Replenishment"
                cOnClick={() => {
                  Replenishment();
                }}
              />
            </>
          }
        />
      </Grid>
    </div>
  );
};

export default SBHeader;
