import AxiosWrapper from "../../base/AxiosWrapper";


const REACT_ENVI = process.env.REACT_APP_ENV;
const {
  axiosPostCall,
  axiosGetCallWithToken
} = AxiosWrapper;

const verificationUser = async (email) => {
  const formD = {
    "envi": REACT_ENVI,
    "email": email,
  };
  const response = await axiosPostCall(`/users/verification`, formD);
  return response;
};

const loginUser = async (email) => {
  const formD = {
    "envi": REACT_ENVI, "email": email,
  };
  const response = await axiosPostCall(`/users/login`, formD);
  return response;
};

const verifyLogin = async () => {
  const response = await axiosGetCallWithToken(`/users/verifyLogin`);
  return response;
};


export {
  verificationUser,
  loginUser,
  verifyLogin
};


