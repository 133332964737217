import React, { useState } from "react";
import CDialog from "../../../basecomp/components/CDialog";
import CInput from "../../../basecomp/components/CInput2";
import CButton from "../../../basecomp/components/CButton";
import CMainGrid from "../../../basecomp/components/wrapper/CMainGrid";
import CDropDown from "../../../basecomp/components/CDropDown";
import { TextField } from "@mui/material";
import MatRequestVM from "../viewmodel/MatRequestVM";
import CBackDrop from "../../../basecomp/components/CBackDrop";

const EmailRequestView = () => {
  const {
    emailFieldsForm,
    handleInputChange,
    handleEmailSubmit,
    handleBulkEmailUpload,
    handleBulkFile,
    isEmailSubmitting,
    isUploading,
    handleEmailBulkFileDownload,
  } = MatRequestVM();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const dialogContent = (
    <CMainGrid container spacing={1} sx={{ marginTop: "1em" }}>
      {Object.keys(emailFieldsForm).map((key) => (
        <CMainGrid item xs={12} sm={6} md={6} lg={4} xl={4} key={key}>
          {key === "date" ? (
            <TextField
              label={emailFieldsForm[key].enter}
              type="date"
              value={emailFieldsForm[key].value || ""}
              onChange={(event) => handleInputChange(key)(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required={emailFieldsForm[key].isRequired}
            />
          ) : emailFieldsForm[key].dropdownValues ? (
            <CDropDown
              cId={`dd_${key}`}
              cHelperText={emailFieldsForm[key].enter}
              cData={emailFieldsForm[key].dropdownValues}
              cOnChange={(selectedValue) =>
                handleInputChange(key)(selectedValue)
              }
              cFullWidth="true"
              cIsRequired={emailFieldsForm[key].isRequired}
              cValue={emailFieldsForm[key].value || ""}
            />
          ) : (
            <CInput
              cOnChange={(value) => handleInputChange(key)(value)}
              cHintText={emailFieldsForm[key].enter}
              cFullWidth="true"
              cIsRequired={emailFieldsForm[key].isRequired}
              cValue={emailFieldsForm[key].value || ""}
              cIsSession={false}
            />
          )}
        </CMainGrid>
      ))}
    </CMainGrid>
  );

  const loading = isEmailSubmitting || isUploading;

  if (loading) return <CBackDrop cOpen={loading} />;

  return (
    <>
      <CButton
        cId="mct_email_btn"
        cText="Email"
        cTooltipText="Submit the Request"
        cOnClick={handleDialogOpen}
      />
      <CDialog
        cOpen={dialogOpen}
        cHandleClose={handleDialogClose}
        cTitle="Material Email Request"
        cOnSubmitClick={handleEmailSubmit}
        cDialogContent={dialogContent}
        cBulkUpload={true}
        cOnBulkEmailUpload={handleBulkEmailUpload}
        cHandleBulkFileChoose={handleBulkFile}
        cHandleEmailBulkFileDownload={handleEmailBulkFileDownload}
      />
    </>
  );
};

export default EmailRequestView;
